import styled from "styled-components";

export const DeliveryCardOutsideRadiusStyled = styled.div`
  .headingFirstText {
    letter-spacing: 0px;
  }
  .headingSecondText {
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    margin-bottom: 20px;
    letter-spacing: 0px;
  }

  .deliverySubText {
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 50px 0 50px;
  }

  .boldDeliverySubText {
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-size: 16px;
    font-weight: 700;
  }

  .tryPickUpButton {
    font-family: ${(props) => props.theme.font.barlow};
    font-weight: 700;
    font-size: 20px;
    width: 180px;
    ${(props) => props.theme.responsive?.max.lg} {
      font-size: 15px;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.size.sm}) {
    .deliveryRadiusTextMobile {
      display: none;
    }
  }
`;
