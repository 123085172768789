import { ANALYTICS_CONST } from "constants/analytics";
import { setStoreLocation, setUserLocation } from "redux/reducer/Store/actions";
import { DEFAULT_LNG } from "redux/reducer/Store/types";
import Analytics from "analytics/Analytics";
import { DEFAULT_LAT } from "constants/constant";
import { setPresentUserGeolocationStatus } from "redux/reducer/UserStatus/UserStatusAction";
import store from "redux/store";

export const getUserLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Add use_current_location event here
        Analytics.getInstance().logEvent(ANALYTICS_CONST.STORE.USE_CURRENT_LOCATION);
        store.dispatch(
          setStoreLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        );
        store.dispatch(
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        );
        store.dispatch(setPresentUserGeolocationStatus(true));
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          store.dispatch(setPresentUserGeolocationStatus(false));
          store.dispatch(
            setStoreLocation({
              lat: DEFAULT_LAT,
              lng: DEFAULT_LNG,
            })
          );
          store.dispatch(
            setUserLocation({
              lat: DEFAULT_LAT,
              lng: DEFAULT_LNG,
            })
          );
        }
      }
    );
  }
};
