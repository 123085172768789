import { useState, MouseEvent } from "react";
import { CommonLayerResponseData } from "src/types/types";
import { Values } from "./types";

export const useSendCode = (onSubmit: (selected: Values) => Promise<CommonLayerResponseData>) => {
  const [values, setValues] = useState({
    email: false,
    phone: false,
  });

  const [error, setError] = useState("");
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  const handleChange = (name: string, value: boolean) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDisableSendBtn(true);

    return onSubmit(values).then((res: CommonLayerResponseData) => {
      setDisableSendBtn(false);
      if (res.success === false) {
        setError(res.error?.message || res.data?.message || "Something went wrong");
      }
    });
  };

  return {
    values,
    error,
    disableSendBtn,
    handleChange,
    handleSubmit,
  };
};
