import { useEffect } from "react";
import { getSignaturesForWeb } from "api/configuration";
import { setSessionStorageData, deleteSessionStorageData } from "utils/storage";
import { generateUUID } from "utils/generateUUID";

const THIRTY_SIX_CHARS_RANDOM_ID = 36;

const useFingerPrinting = () => {
  const fetchSignaturesData = () => {
    getSignaturesForWeb()
      .then((res: any) => {
        if (res.success) {
          setSessionStorageData("signaturesFormat", JSON.stringify(res.response));
        } else {
          // show fingerPrint error
        }
      })
      .catch((err: any) => {
        return err;
      });
  };

  useEffect(() => {
    // Generate the deviceId & store it in the localStorage
    const deviceId = generateUUID(THIRTY_SIX_CHARS_RANDOM_ID);
    setSessionStorageData("deviceId", JSON.stringify(deviceId));

    fetchSignaturesData();

    return () => deleteSessionStorageData("deviceId");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchSignaturesData();
    }, 1800000);

    return () => clearInterval(interval);
  }, []);
};

export default useFingerPrinting;
