import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_RETIRED_MENU } from "api/endpoints";
import { RetiredMenuCMSData } from "types/cms/retired-menu";

export const getRetiredMenuCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_RETIRED_MENU,
  }) as Promise<CommonLayerResponseDataWithType<RetiredMenuCMSData>>;
};
