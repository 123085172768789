import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_DOWN } from "api/endpoints";
import { ErrorDownCMSData } from "types/cms/error-down";

export const getErrorDownCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_DOWN,
  }) as Promise<CommonLayerResponseDataWithType<ErrorDownCMSData>>;
};
