import { apiCall } from "src/apis/api";
import { ME } from "../endpoints";

interface Request {
  idToken: string;
}

export interface Response {
  status: number;
  data: {
    id: number;
    externalId: string;
    firstName: string;
    lastName: string;
    email: string;
    cdpId: string;
    phone: string;
    birthDate: string;
    mfa: boolean;
    phoneVerified: boolean;
    emailVerified: boolean;
    subscription: {
      sms: boolean;
      email: boolean;
      textOptInStatus: string;
    };
    codeDeliveryDetailsResponse: {
      destination: string;
      deliveryMedium: "SMS" | "EMAIL";
      attribute: "phone" | "email";
    };
    created: string;
    updated: string;
  };
}

export const getMe = async (data: Request) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: ME,
    isAuth: true,
    token: data.idToken,
  });
};
