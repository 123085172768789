import { apiCall } from "src/apis/api";
import { Menu, MenuBasedOnCategoryRequest, Option, Product, Group, ProductDetailsResponse, SelectedLocationProduct } from "src/types/types";
import { MENU } from "api/endpoints";
import { ITEM_NOT_FOUND_ERROR_CODE } from "constants/errorCodeConstants";
import {
  SET_STORE_MENU,
  GET_CATEGORY_MENU_LIST,
  SET_SELECTED_TAB_NAME,
  GET_AN_ITEM_FROM_STORE,
  GET_DEFAULT_ITEM_SELECTED,
  GET_ITEM_SECTIONS,
  SET_SELECTED_OPTION,
  SET_OPTION_LIST,
  GET_GENERIC_MENU,
  SET_UPDATED_QUANTITY,
  SET_PRICE,
  GET_MENU_BASED_ON_CATEGORY,
  SET_SELECTED_CATEGORY_NAME,
  IS_REDIRECTED_FROM_HOME,
  SET_PRIMARY_MODIFIERS,
  SELECTED_PRODUCT_OPTIONS_LIST,
  RESET_CUSTOMIZATION_DATA,
  SET_SELECTED_PRODUCT_TO_MODAL,
  ITEM_COST,
  ITEM_BASE_CALORIE,
  ITEM_MAX_CALORIE,
  UNSELECTED_GROUPS,
  UNSELECTED_GROUPS_DETAILS,
  IS_SHOW_PULSE_EFFECT,
  MENU_IS_LOADING,
  SET_BRAND_PRODUCT_ID,
  IS_MENU_ERROR,
  SET_MENU_ERROR_MESSAGE_TO_MODAL_FLAG,
  SET_SELECTED_MENU_CATEGORY,
  GET_GENERIC_MENU_WITH_MODIFIERS,
  SET_STORE_MENU_WITH_MODIFIERS,
  SET_PRODUCT_NAME_TO_NAVIGATE,
  SET_SELECTED_LOCATION_PRODUCT,
  SET_PREVIOUS_ROUTE_URL,
  SET_SELECTED_CATEGORY_PRODUCTS,
  SET_FROM_HOME_REORDER_SECTION,
  INCREMENT_ITEM_QUANTITY,
  DECREMENT_ITEM_QUANTITY,
} from "./MenuConst";

export const getStoreMenuWithModifiers = (payload: string | string[]) => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));
    apiCall({
      requestType: "GET",
      apiEndPoint: `${MENU}/${payload}?includePrimaryModifiers=true`,
    }).then((response) => {
      dispatch(menuIsLoading(false));
      dispatch({ type: GET_CATEGORY_MENU_LIST, payload: response.success ? response.response : [] });
      dispatch({ type: SET_STORE_MENU_WITH_MODIFIERS, payload: response.success ? response.response : [] });
    });
  };
};

export const menuIsLoading = (isLoading: boolean) => ({
  type: MENU_IS_LOADING,
  payload: isLoading,
});

export const getStoreMenu = (payload: string | string[]) => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));
    apiCall({
      requestType: "GET",
      apiEndPoint: `${MENU}/${payload}`,
    }).then((response) => {
      dispatch(menuIsLoading(false));
      dispatch({ type: SET_STORE_MENU, payload: response.success ? response.response : [] });
    });
  };
};

export const resetStoreMenu = () => {
  return { type: SET_STORE_MENU, payload: [] };
};
export const getCategoryMenuList = (payload: any) => {
  return { type: GET_CATEGORY_MENU_LIST, payload: payload };
};
export const setSelectedTabName = (payload: string) => {
  return { type: SET_SELECTED_TAB_NAME, payload: payload };
};
export const setSelectedCategoryName = (payload: string) => {
  return { type: SET_SELECTED_CATEGORY_NAME, payload: payload };
};
export const isRedirectedFromHome = (payload: boolean) => {
  return { type: IS_REDIRECTED_FROM_HOME, payload: payload };
};

export const getAnItemFromStore = (payload: Menu[]) => {
  return { type: GET_AN_ITEM_FROM_STORE, payload: payload };
};

export const setItemCost = (payload: number) => {
  return { type: ITEM_COST, payload: payload };
};

export const setBrandProductId = (payload?: number) => {
  return { type: SET_BRAND_PRODUCT_ID, payload: payload };
};

export const isError = (error: boolean) => {
  return { type: IS_MENU_ERROR, payload: error };
};

export const getItemFromStore = (storeId: number, productId: number) => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));
    apiCall({ apiEndPoint: `${MENU}/${storeId}/products/${productId}`, requestType: "GET" }).then((response: ProductDetailsResponse) => {
      dispatch(menuIsLoading(false));
      if (response.success) {
        if (response.response) {
          dispatch({ type: GET_AN_ITEM_FROM_STORE, payload: response.response });
          dispatch({ type: ITEM_COST, payload: response.response.cost });
        }
      } else {
        dispatch({ type: GET_AN_ITEM_FROM_STORE, payload: {} });
        if (response.error) {
          if (response.error.apiError?.code === ITEM_NOT_FOUND_ERROR_CODE) {
            dispatch(isError(true));
          }
        }
      }
    });
  };
};

export const setDefaultItemCustomList = (payload: any) => {
  return { type: GET_DEFAULT_ITEM_SELECTED, payload: payload };
};

export const getProductItemSections = (payload: any) => {
  return { type: GET_ITEM_SECTIONS, payload: payload };
};

export const setSelectedOption = (payload: any) => {
  return { type: SET_SELECTED_OPTION, payload: payload };
};

export const setOptionList = (payload: any) => {
  return { type: SET_OPTION_LIST, payload: payload };
};

export const getGenericMenuWithModifiers = () => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));
    apiCall({
      requestType: "GET",
      apiEndPoint: `${MENU}/generic?includePrimaryModifiers=true`,
    }).then((response) => {
      dispatch(menuIsLoading(false));
      if (response.success) {
        dispatch({ type: GET_GENERIC_MENU_WITH_MODIFIERS, payload: response.response });
        dispatch({ type: GET_CATEGORY_MENU_LIST, payload: response.response });
      } else {
        dispatch({ type: SET_MENU_ERROR_MESSAGE_TO_MODAL_FLAG, payload: response.error?.message || "Something Went Wrong ..!!" });
      }
    });
  };
};

export const getGenericMenuList = () => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));
    apiCall({
      requestType: "GET",
      apiEndPoint: `${MENU}/generic`,
    }).then((response) => {
      dispatch(menuIsLoading(false));
      if (response.success) {
        dispatch({ type: GET_GENERIC_MENU, payload: response.response });
      } else {
        dispatch({ type: SET_MENU_ERROR_MESSAGE_TO_MODAL_FLAG, payload: response.error?.message || "Something Went Wrong ..!!" });
      }
    });
  };
};

export const setUpdatedQuantity = (payload: number) => {
  return { type: SET_UPDATED_QUANTITY, payload: payload };
};

export const setTotalPrice = (payload: any) => {
  return { type: SET_PRICE, payload: payload };
};

export const getMenuBasedOnCategory = (payload: string) => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));
    apiCall({
      requestType: "GET",
      apiEndPoint: `${MENU}/generic/categories/${payload}`,
    }).then((response) => {
      dispatch(menuIsLoading(false));
      dispatch({ type: GET_MENU_BASED_ON_CATEGORY, payload: response.success ? response.response : [] });
    });
  };
};

export const getMenuBasedOnCategoryAndProduct = (categoryName: string, productName: string) => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));
    apiCall({ apiEndPoint: `${MENU}/generic/categories/${categoryName}/products/${productName}`, requestType: "GET" }).then((response) => {
      dispatch(menuIsLoading(false));
      if (response.success) {
        dispatch(isError(false));
        dispatch(getAnItemFromStore(response.response));
        dispatch(setItemCost(response.response.cost));
        dispatch(setBrandProductId(response.response.brandProductId));
        dispatch(setItemBaseCalorieModified(parseInt(response.response.baseCalories)));
        dispatch(setItemMaxCalorieModified(parseInt(response.response.maxCalories)));
      } else {
        dispatch({ type: GET_AN_ITEM_FROM_STORE, payload: [] });
        if (response.error?.message === "Item not found") {
          dispatch(isError(true));
        }
      }
    });
  };
};

export const getMenuBasedOnStoreAndCategory = (payload: MenuBasedOnCategoryRequest) => {
  return (dispatch: any) => {
    dispatch(menuIsLoading(true));

    apiCall({
      requestType: "GET",
      apiEndPoint: `${MENU}/${payload.storeId}/categories/${payload.category}`,
    }).then((response) => {
      dispatch(menuIsLoading(false));
      dispatch({ type: GET_MENU_BASED_ON_CATEGORY, payload: response.success ? response.response : [] });
    });
  };
};

export const setPrimaryModifiers = (payload: any) => {
  return { type: SET_PRIMARY_MODIFIERS, payload: payload };
};

export const sendSelectedProductToModal = (payload: Product) => {
  return { type: SET_SELECTED_PRODUCT_TO_MODAL, payload: payload };
};
export const setSelectedProductOptions = (index: number, data: Array<Option>) => {
  return { type: SELECTED_PRODUCT_OPTIONS_LIST, payload: [index, data] };
};

export const incrementItemQuantity = (index: number, count: number) => {
  return { type: INCREMENT_ITEM_QUANTITY, payload: { index, count } };
};
export const decrementItemQuantity = (index: number, count: number) => {
  return { type: DECREMENT_ITEM_QUANTITY, payload: { index, count } };
};

export const resetCustomizationData = () => {
  return { type: RESET_CUSTOMIZATION_DATA };
};

export const setItemCostModified = (payload: number) => {
  return { type: ITEM_COST, payload: payload };
};

export const setItemBaseCalorieModified = (payload: number) => {
  return { type: ITEM_BASE_CALORIE, payload: payload };
};

export const setItemMaxCalorieModified = (payload: number) => {
  return { type: ITEM_MAX_CALORIE, payload: payload };
};

export const setUnselectedGroups = (payload: Array<number>) => {
  return { type: UNSELECTED_GROUPS, payload: payload };
};

export const setUnselectedGroupsDetails = (payload: Array<Group>) => {
  return { type: UNSELECTED_GROUPS_DETAILS, payload: payload };
};

export const isShowPulse = (payload: boolean) => {
  return { type: IS_SHOW_PULSE_EFFECT, payload: payload };
};

export const setSelectedMenuCategory = (payload: string) => {
  return { type: SET_SELECTED_MENU_CATEGORY, payload: payload };
};

export const setProductNameToNavigate = (payload: string) => {
  return { type: SET_PRODUCT_NAME_TO_NAVIGATE, payload: payload };
};

export const setSelectedLocationProduct = (payload: SelectedLocationProduct) => {
  return { type: SET_SELECTED_LOCATION_PRODUCT, payload: payload };
};

export const setPreviousRouteUrl = (payload: string) => {
  return { type: SET_PREVIOUS_ROUTE_URL, payload: payload };
};

export const setSelectedCategoryProducts = (payload: Product[] | any) => {
  return { type: SET_SELECTED_CATEGORY_PRODUCTS, payload: payload };
};

export const setFromHomeReorderSection = (payload?: { isProduct?: boolean; id: string; notFound?: boolean }) => {
  return { type: SET_FROM_HOME_REORDER_SECTION, payload: payload };
};
