import { ReactNode } from "react";
import Button from "../../button/Button";
import SubHeadings from "../../typography/SubHeadings/SubHeadings";
import Headings from "../../typography/Headings/Headings";
import Modal from "../Modal/Modal";
import { AuthCardStyled, AuthCardFooter } from "./AuthCardStyled";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  title?: ReactNode | string;
  description?: ReactNode | string;
  onSelect?: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  confirmText?: string;
  cancelText?: string;
  okButton?: string;
  body?: ReactNode;
  footer?: ReactNode;
  className?: string;
  modalClassName?: string;
  mobileVariant?: "fullScreen" | "croppedVariant" | string;
}
const AuthCard = ({
  isOpen,
  onClose,
  title,
  description,
  onSelect,
  showCloseButton,
  showBackButton,
  confirmText,
  cancelText,
  okButton,
  body,
  footer,
  className,
  modalClassName,
  mobileVariant = "croppedVariant",
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={showCloseButton}
      showBackButton={showBackButton}
      className={modalClassName}
      mobileVariant={mobileVariant}
    >
      <AuthCardStyled className={className}>
        <div className="authCardHeader">
          {title && (
            <Headings fontSize={24} lineHeight={24} variant="secondaryHeading2" className="titleWrapper">
              {title}
            </Headings>
          )}
          {description && (
            <SubHeadings variant="secondarySubHeading1" className="descriptionWrapper" fontWeight={400}>
              {description}
            </SubHeadings>
          )}
          {body}
        </div>
        {footer && (
          <AuthCardFooter className="authCardFooter">
            {cancelText && (
              <Button variant="secondaryWhite" onClick={onClose}>
                {cancelText}
              </Button>
            )}
            {confirmText && (
              <Button variant="primaryRed" onClick={onSelect}>
                {confirmText}
              </Button>
            )}
            {okButton && (
              <Button variant="primaryRed" onClick={onClose} className="okButton">
                {okButton}
              </Button>
            )}
            {footer}
          </AuthCardFooter>
        )}
      </AuthCardStyled>
    </Modal>
  );
};

export default AuthCard;
