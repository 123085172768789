import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import AddressModal from "../AddressModal/AddressModal";

const ConfirmAddress = () => {
  const userAddress = useSelector((state: any) => state.address?.userAddress);
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));

  return (
    <AddressModal
      device={device}
      title={device === "DESKTOP" ? "CONFIRM THIS DELIVERY" : "CONFIRM \n DELIVERY"}
      description={device === "DESKTOP" ? "Home is where the Zax goes" : "Home is where the Zax goes."}
      modalName="CONFIRM_ADDRESS"
      defaultValues={userAddress}
    />
  );
};

export default ConfirmAddress;
