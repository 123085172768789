import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import useModal from "hooks/useModal";
import { getUserLocation } from "utils/getLocation";
import { RootState } from "src/types/types";
import { setLocationsScreenFlag } from "redux/reducer/UserStatus/UserStatusAction";
import { setLocationOrderType } from "redux/reducer/Location/LocationAction";
import { OrderType } from "containers/Order/types";
import Button from "components/atomic-components/atoms/button/Button";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import PromptModal from "components/atomic-components/molecules/Modals/PromptModal/PromptModal";
import StackedButtonsContainer from "components/atomic-components/molecules/StackedButtonsContainer/StackedButtonsContainer";
import { SubHeading, Footer, Body, Additional, AccountCreatedStyled, HeaderStyle } from "./AccountCreatedStyled";

const AccountCreated = () => {
  const router = useRouter();
  const { modal, setModal } = useModal();
  const dispatch = useDispatch();
  const { device } = useSelector(({ currentDevice }: RootState) => ({
    device: currentDevice.device,
  }));
  const handleClose = () => {
    setModal("");
    if (router?.pathname === "/account/logged-out") router.push("/");
  };

  const handleGoToHome = () => {
    router.push("/");
  };

  const onNavigationChange = (orderType: OrderType) => {
    getUserLocation();
    dispatch(setLocationOrderType(orderType));
    dispatch(setLocationsScreenFlag(true));
    router.push("/locations");
  };

  const isMobile = device === "MOBILE";
  const BODY = (
    <Body>
      <HeaderStyle>
        <Headings variant="primaryHeading2" fontWeight={800} fontSize={40} className="accountCreatedModal">
          YOUR ACCOUNT IS
        </Headings>
        <Headings
          variant="primaryHeading1"
          fontWeight={800}
          fontSize={34}
          mobileFontSize={30}
          mobileFontWeight={700}
          className="welcomeBackTitle"
        >
          READY!
        </Headings>
      </HeaderStyle>
    </Body>
  );

  const FOOTER = (
    <Footer>
      <SubHeadings variant="primarySubHeading2" fontSize={24} fontWeight={800} className="accountSubTitle">
        LET&apos;S GET COOKIN&apos;
      </SubHeadings>
      <SubHeading className="scaledText">
        {isMobile ? "Select your order type to start your order" : "How do you like to get your food?"}
      </SubHeading>
      <StackedButtonsContainer direction="row" className="buttons" mobileDirection="col">
        <Button className="buttonPrimary" onClick={() => onNavigationChange("PICKUP")} variant="primaryRed">
          PICKUP
        </Button>
        <Button className="buttonPrimary" onClick={() => onNavigationChange("DELIVERY")} variant="primaryRed">
          DELIVERY
        </Button>
      </StackedButtonsContainer>
      <Additional>
        <Button className="layout" variant="ghostWhite" onClick={handleGoToHome}>
          <Paragraph
            className="regularText"
            variant="secondaryParagraph1"
            fontSize={16}
            fontWeight={500}
            mobileFontSize={14}
            mobileFontWeight={400}
          >
            No Thanks,
          </Paragraph>
          <Paragraph
            className="underline"
            variant="secondaryParagraph1"
            fontSize={16}
            fontWeight={700}
            mobileFontSize={14}
            mobileFontWeight={700}
          >
            {" "}
            GO TO HOME{" "}
          </Paragraph>
        </Button>
      </Additional>
    </Footer>
  );

  return (
    <AccountCreatedStyled>
      <PromptModal isOpen={modal === "ACCOUNT_CREATED"} className="accountModal" body={BODY} footer={FOOTER} onClose={handleClose} />
    </AccountCreatedStyled>
  );
};

export default AccountCreated;
