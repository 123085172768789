import { apiCall } from "src/apis/api";
import { CHECKIN_CURBSIDE_ORDER } from "api/endpoints";
import { CommonLayerResponseDataWithType } from "src/types/types";

export const checkInCurbsideOrder = async (
  token?: string,
  order?: {
    orderId: string;
    ParkingSpot: string;
    LicensePlate: string;
    PreferredLocation: string;
    Comments: string;
  }[]
) => {
  return apiCall({
    requestType: "POST",
    apiEndPoint: `${CHECKIN_CURBSIDE_ORDER}`,
    apiPostData: {
      orders: {
        order: order,
      },
    },
    token,
  }) as Promise<CommonLayerResponseDataWithType<Response>>;
};
