import { apiCall } from "src/apis/api";
import { USERS } from "api/endpoints";
import { FavouriteAddressListRequest, AddressListRequest, UserAddress, DeleteAddress, SmartyStreetModal, Location } from "src/types/types";
import {
  DELETE_USER_ADDRESS,
  SET_ADDRESS_LIST,
  SET_USER_ADDRESS,
  SET_FAVOURITE_ADDRESS,
  SET_USER_ADDRESS_FROM_MODAL,
  USER_ADDRESS_AFTER_UPDATE,
  SET_USER_ADDRESS_FROM_AUTO_COMPLETE,
  SET_USER_ENTERED_DELIVERY_ADDRESS,
  SET_USER_ADDRESS_CONFIRMATION,
  ADDRESS_IS_LOADING,
  SET_ADD_ADDRESS_MODAL,
  EDIT_ADD_ADDRESS_MODAL,
} from "./AddressConst";

export const addressIsLoading = (isLoading: boolean) => {
  return { type: ADDRESS_IS_LOADING, payload: isLoading };
};

export const getAddressList = (payload: AddressListRequest) => {
  return (disptach: any) => {
    disptach(addressIsLoading(true));
    apiCall({
      apiEndPoint: `${USERS}/addresses`,
      requestType: "GET",
      isAuth: true,
      token: payload.idToken,
    }).then((response) => {
      if (response.success) {
        disptach({ type: SET_ADDRESS_LIST, payload: response.response });
      } else {
        disptach({ type: SET_ADDRESS_LIST, payload: [] });
      }
    });
  };
};

export const setFavoriteAddress = (payload: FavouriteAddressListRequest) => {
  return (dispatch: any) => {
    dispatch(addressIsLoading(true));
    apiCall({
      apiEndPoint: `${USERS}/addresses/favorites`,
      requestType: "PUT",
      isAuth: true,
      token: payload.idToken,
      apiPostData: {
        addressId: payload.addressId,
        favorite: payload.favorite,
      },
    }).then((response) => {
      if (response.success) {
        dispatch({ type: SET_FAVOURITE_ADDRESS, payload: response.response });
        dispatch(getAddressList({ idToken: payload?.idToken, externalId: payload?.externalId }));
      }
    });
  };
};

export const setUserAddress = (payload: UserAddress) => {
  return { type: SET_USER_ADDRESS, payload: payload || {} };
};

export const deleteUserAddress = (payload: DeleteAddress) => {
  return (dispatch: any) => {
    dispatch(addressIsLoading(true));
    return apiCall({
      apiEndPoint: `${USERS}/addresses/${payload.addressId}`,
      requestType: "DELETE",
      isAuth: true,
      token: payload.idToken,
    }).then((response) => {
      if (response.success) {
        dispatch({ type: DELETE_USER_ADDRESS, payload: response.response });
      } else {
        dispatch({ type: DELETE_USER_ADDRESS, payload: [] });
      }
    });
  };
};

export const setUserAddressFromModal = (payload: UserAddress | SmartyStreetModal) => {
  return { type: SET_USER_ADDRESS_FROM_MODAL, payload: payload || {} };
};
export const setUserAddressConfirmation = (payload: boolean) => {
  return { type: SET_USER_ADDRESS_CONFIRMATION, payload: payload || false };
};

export const setUserAddressAfterUpdate = () => {
  return { type: USER_ADDRESS_AFTER_UPDATE, payload: {} };
};

export const setUserAddressFromAutoComplete = (payload: UserAddress) => {
  return { type: SET_USER_ADDRESS_FROM_AUTO_COMPLETE, payload: payload || {} };
};

export const setUserEnteredDeliveryAddress = (payload: Location) => {
  return { type: SET_USER_ENTERED_DELIVERY_ADDRESS, payload: payload || {} };
};

export const setIsAddAdrressOpen = (payload: boolean) => {
  return { type: SET_ADD_ADDRESS_MODAL, payload: payload || false };
};
export const setIsEditAdrress = (payload: boolean) => {
  return { type: EDIT_ADD_ADDRESS_MODAL, payload: payload || false };
};
