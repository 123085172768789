import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_PROCESS } from "api/endpoints";
import { FranchiseProcessCMSData } from "types/cms/franchise-process";

export const getFranchiseProcessCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_PROCESS,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseProcessCMSData>>;
};
