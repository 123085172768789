import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_ABOUT_US } from "api/endpoints";
import { FranchiseAboutUsCMSData } from "types/cms/franchise-aboutus";

export const getFranchiseAboutUsCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_ABOUT_US,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseAboutUsCMSData>>;
};
