import { request } from "api/utils";
import { USERS } from "api/endpoints";

import { CodeDeliveryDetailsResponse, ResendConfirmationCodeSignUpRequest } from "../types";

type Request = ResendConfirmationCodeSignUpRequest;
type Response = CodeDeliveryDetailsResponse;

export const resendConfirmationCode = (body: Request) => {
  return request<Response>({
    endpoint: `${USERS}/resend-confirmation-code`,
    method: "POST",
    body,
  });
};
