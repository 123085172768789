import { MouseEvent } from "react";
import { useSelector } from "react-redux";
import Image from "next/image";
import { RootState } from "src/types/types";
import onKey from "utils/accessability/onKey";
import { Nullable } from "types/cms/cms-types";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { CLOSE_WHITE_X, CLOSE_X } from "assets/images";
import { Template } from "../types";
import { ViolatorBarTemplatesStyled } from "./ViolatorBarTemplatesStyled";

interface Props {
  templateName: Template;
  title?: Nullable<string>;
  subTitle?: Nullable<string>;
  ctaText?: Nullable<string>;
  handleClose: (e: MouseEvent) => void;
}

const ViolatorBarTemplates = ({ templateName, title, handleClose, subTitle, ctaText }: Props) => {
  const { device } = useSelector((state: RootState) => ({
    device: state?.currentDevice.device,
  }));

  const closeButton = templateName === "OneDark" || templateName === "TwoDark" ? CLOSE_X : CLOSE_WHITE_X;

  return (
    <ViolatorBarTemplatesStyled template={templateName}>
      <div className="violatorContent">
        <div className="violatorText">
          {device === "MOBILE" && (
            <Paragraph variant="paragraphHeading1" className="title">
              {title}
            </Paragraph>
          )}
          {device === "DESKTOP" && title && <span className="title desktop">{title + " — "}</span>}
          <span className="subTitle">{subTitle + " "}</span>
          <span className="ctaText">{ctaText}</span>
        </div>
      </div>
      <div className="image-wrapper" onClick={handleClose} onKeyDown={onKey(handleClose)} tabIndex={0} role="button">
        <Image src={closeButton} layout="fill" alt="close" />
      </div>
    </ViolatorBarTemplatesStyled>
  );
};

export default ViolatorBarTemplates;
