import { RewardState, RewardAction } from "./types";

const initialState: RewardState = {
  setRewardToBasket: null,
  deleteRewardFromBasket: null,
  getRewardForBasket: null,
  getUserRewardHistory: null,
  selectedReward: null,
  getRewardUserCodeToScan: null,
  appliedReward: {},
  selectedRewardId: "",
  selectedRewardList: [],
  basketRewardsList: [],
  redeemInstoreRewardResponse: null,
  deleteRedemptionTrackingCode: null,
  applyPromoCodeToBasket: null,
  deletePromoCodeFromBasket: null,
  getRewardsForGuest: null,
  getPointsWithManualCheckins: null,
  getActivateYourOfferCoupons: null,
  userClickedTier: [],
  userClickedCard: {
    type: "",
    rewardId: "",
    name: "",
    description: "",
    pointsRequired: 0,
    image: "",
    tag: "",
    expiringAt: "",
    termsLink: "",
    isSelected: false,
    terms: [],
  },
  showPromoCodeInput: false,
  isPromoCodeApplied: false,
  promoInput: null,
  rewardsListUntilExpiry: [],
  activeFeaturedRewardsExpiration: [],
  rewardsScanError: {
    firstLine: "",
    secondLine: "",
    description: "",
  },
  rewardsData: null,
  tieredRewards: null,
};

export const rewardsReducer = (state = initialState, action: RewardAction) => {
  switch (action.type) {
    case "SET_REWARDS_SCAN_ERROR":
      return {
        ...state,
        rewardsScanError: action.payload ?? null,
      };
    case "SET_REWARD_TO_BASKET":
      return { ...state, setRewardToBasket: action.payload ?? null };

    case "DELETE_REWARD_FROM_BASKET":
      return { ...state, deleteRewardFromBasket: action.payload ?? null };

    case "GET_REWARDS_FOR_BASKET":
      return { ...state, getRewardForBasket: action.payload ?? null };

    case "GET_REWARDS_FOR_HOME":
      return { ...state, getRewardForHome: action.payload ?? null };

    case "GET_USER_REWARDS_HISTORY":
      return { ...state, getUserRewardHistory: action.payload ?? null };

    case "SET_SELECTED_REWARD":
      return { ...state, selectedReward: action.payload ?? null };

    case "GET_REWARD_USER_CODE_TO_SCAN":
      return { ...state, getRewardUserCodeToScan: action.payload ?? null };

    case "SET_APPLIED_REWARD":
      return { ...state, appliedReward: action.payload };

    case "SET_SELECTED_REWARD_ID":
      return { ...state, selectedRewardId: action.payload };

    case "GET_ACTIVATE_YOUR_OFFER_COUPONS":
      return { ...state, getActivateYourOfferCoupons: action.payload };

    case "REDEEM_INSTORE_REWORD":
      return { ...state, redeemInstoreRewardResponse: action.payload ?? null };

    case "DELETE_REDEMPTION_TRACKING_CODE":
      return { ...state, deleteRedemptionTrackingCode: action.payload ?? null };

    case "APPLY_PROMO_CODE_TO_BASKET":
      return { ...state, applyPromoCodeToBasket: action.payload ?? null };

    case "DELETE_PROMO_CODE_FROM_BASKET":
      return { ...state, deletePromoCodeFromBasket: action.payload ?? null };

    case "GET_REWARDS_FOR_GUEST":
      return { ...state, getRewardsForGuest: action.payload ?? null };

    case "SET_SELECTED_REWARD_LIST":
      return { ...state, selectedRewardList: action.payload ?? null };

    case "SET_USER_SELECTED_TIER":
      return { ...state, userClickedTier: action.payload ?? null };

    case "SET_USER_SELECTED_CARD":
      return { ...state, userClickedCard: action.payload ?? null };

    case "SET_BASKET_REWARD_LIST":
      return { ...state, basketRewardsList: action.payload ?? null };

    case "SET_SHOW_PROMO_CODE_INPUT":
      return { ...state, showPromoCodeInput: action.payload ?? null };

    case "SET_IS_PROMO_CODE_APPLIED":
      return { ...state, isPromoCodeApplied: action.payload ?? null };

    case "SET_PROMO_INPUT":
      return { ...state, promoInput: action.payload ?? undefined };

    case "SET_REWARDS_LIST_UNTIL_EXPIRY":
      return { ...state, rewardsListUntilExpiry: action.payload ?? [] };

    case "SET_ACTIVE_FEATURED_REWARDS_EXPIRATION":
      return { ...state, activeFeaturedRewardsExpiration: action.payload ?? [] };

    case "SET_REWARDS_DATA":
      return { ...state, rewardsData: action.payload ?? null };

    case "SET_TIERED_REWARDS":
      return { ...state, tieredRewards: action.payload ?? null };
    default:
      return state;
  }
};
