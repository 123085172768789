import { SmartySuggestionsLatLngResponse, SmartySuggestionsResponse } from "src/types/types";
import { apiCall } from "src/apis/api";
import { SMARTY_STREET_MAX_RESULTS } from "constants/constant";

const smartyStreetKey = process.env.NEXT_PUBLIC_SMARTY_STREET_KEY;
const smartyStreetEndpoint = process.env.NEXT_PUBLIC_SMARTY_STREET_END_POINT;
const smartyStreetEndpointLicenseKey = process.env.NEXT_PUBLIC_SMARTY_STREET_END_POINT_LICENSE_KEY;
const smartyStreetValidationEndpoint = process.env.NEXT_PUBLIC_SMARTY_VALIDATION_END_POINT;
const smartyStreetValidationEndpointLicenseKey = process.env.NEXT_PUBLIC_SMARTY_VALIDATION_END_POINT_LICENSE_KEY;
const reverseGeoCodeEndPoint = process.env.NEXT_PUBLIC_SMARTY_STREET_REVERSE_GEOCODE;
const reverseGeoCodeEndPointLicenseKey = process.env.NEXT_PUBLIC_SMARTY_STREET_REVERSE_GEOCODE_LICENSE_KEY;

export const getSmartyStreet = (text: string, maxResults: number = SMARTY_STREET_MAX_RESULTS) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${smartyStreetEndpoint}/lookup?search=${text}&key=${smartyStreetKey}&max_results=${maxResults}&source=all${
      smartyStreetEndpointLicenseKey ? `&license=${smartyStreetEndpointLicenseKey}` : ""
    }`,
  }) as Promise<SmartySuggestionsResponse>;
};

export const validateSmartyStreet = (street: string, city: string, state: string, zipcode: string) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${smartyStreetValidationEndpoint}/street-address?match=enhanced&&auth-id=${smartyStreetKey}&street=${street}&city=${city}&state=${state}&zipcode=${zipcode}${
      smartyStreetValidationEndpointLicenseKey ? `&license=${smartyStreetValidationEndpointLicenseKey}` : ""
    }`,
  });
};

export const getSmartyStreetNameViaLocation = (latitude: number, longitude: number) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${reverseGeoCodeEndPoint}/lookup?auth-id=${smartyStreetKey}&latitude=${latitude}&longitude=${longitude}&source=all${
      reverseGeoCodeEndPointLicenseKey ? `&license=${reverseGeoCodeEndPointLicenseKey}` : ""
    }
   `,
  }) as Promise<SmartySuggestionsLatLngResponse>;
};
