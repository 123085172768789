import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_INVESTMENT } from "api/endpoints";
import { FranchiseInvestmentCMSData } from "types/cms/franchise-investment";

export const getInvestmentCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_INVESTMENT,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseInvestmentCMSData>>;
};
