import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CMSRootState } from "redux/reducer/CMS/types";
import { setErrorDownData } from "redux/reducer/CMS/CMSAction";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import MarkdownComponent from "components/atomic-components/atoms/MarkdownComponent/MarkdownComponent";
import { Error500Styled } from "./Error500Styled";

interface RootState {
  cms: CMSRootState;
}

const Error500 = () => {
  const dispatch = useDispatch();
  const { errorDown, isErrorDownLoaded } = useSelector(({ cms }: RootState) => ({
    errorDown: cms.errorDown,
    isErrorDownLoaded: cms.isErrorDownLoaded,
  }));

  useEffect(() => {
    dispatch(setErrorDownData());
  }, []);

  if (!errorDown || !isErrorDownLoaded) return null;

  return (
    <Error500Styled>
      {errorDown.Title && (
        <Headings variant="headingMedium" fontSize={36} lineHeight={40} mobileFontSize={24} mobileLineHeight={26} className="errorTitle">
          {errorDown.Title}
        </Headings>
      )}
      {errorDown.Subtitle && <MarkdownComponent markdown={errorDown.Subtitle} className="markdown" />}
    </Error500Styled>
  );
};

export default Error500;
