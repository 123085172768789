import { useDispatch, useSelector } from "react-redux";
import { resendConfirmationCode } from "api/users/auth/resendConfirmationCode";
import { setUserTokens, setUserType } from "redux/reducer/user/actions";
import { USERS } from "api/endpoints";
import { apiCall } from "src/apis/api";
import { useAccount } from "hooks/account/useAccount";
import { RootState } from "src/types/types";

export const useVerify = () => {
  const dispatch = useDispatch();
  const { getUserDetails } = useAccount();

  const { username, password, codeDeliveryDetails } = useSelector((state: RootState) => state.signup);

  const signinApiDetails = {
    requestType: "POST",
    apiEndPoint: `${USERS}/signin`,
  };

  const confirmSignUpApiDetails = {
    requestType: "POST",
    apiEndPoint: `${USERS}/confirm-signup`,
  };

  const handleResend = async () => {
    if (!username) {
      throw Error("Username is missing");
    }

    return resendConfirmationCode({ username });
  };

  const handleSubmit = async (code: string, callback?: Function) => {
    if (username && password) {
      const confirmSignUpResult = await apiCall({ ...confirmSignUpApiDetails, apiPostData: { username, code } });
      if (confirmSignUpResult.success) {
        const result = await apiCall({ ...signinApiDetails, apiPostData: { username, password } });
        if (result.success) {
          const { authenticationResult } = result.response;
          dispatch(setUserType("RETURNING"));
          dispatch(setUserTokens(authenticationResult));
          // Calling mparticle login identity using the callback method here
          if (callback) {
            await callback();
          }
          getUserDetails(authenticationResult.idToken, authenticationResult.accessToken);
        }
        return result;
      }
      return confirmSignUpResult;
    } else {
      throw Error("Username or password is missing");
    }
  };

  return {
    destination: codeDeliveryDetails?.destination || "",
    handleResend,
    handleSubmit,
  };
};
