import dayjs from "dayjs";
import Analytics from "analytics/Analytics";
import { ANALYTICS_CONST } from "constants/analytics";
import { USERS } from "../api/endpoints";
import { getTokensForAPICall } from "../api/utils";
import { postApiCall } from "./api";
import refreshToken, { refreshTokenExpired } from "./refreshToken";

let DEFAULT_TOKEN_EXTEND_EXPIRY_TIME: number = 20;
let isWebTokenRefreshInProgress: boolean = false;
let time: number = 0;
let extendExpTime: number = Number(process.env.NEXT_TOKEN_EXTEND_EXPIRY_TIME) || DEFAULT_TOKEN_EXTEND_EXPIRY_TIME;

export const updateWebTokenExpiryTime = (expirationSecs: number) => {
  const expiredTime = dayjs(dayjs()).unix() + expirationSecs;
  time = expiredTime;
  localStorage.setItem("tokenExpirationTime", expiredTime.toString());
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isWebTokenSoonExpire: any = async () => {
  if (isWebTokenRefreshInProgress) {
    await delay(500);
    return isWebTokenSoonExpire();
  }
  if (time < dayjs(dayjs()).unix() + extendExpTime) {
    isWebTokenRefreshInProgress = true;
    let tokenExpirationTime = localStorage.getItem("tokenExpirationTime") || "";

    if (tokenExpirationTime && parseInt(tokenExpirationTime) < dayjs(dayjs()).unix() + extendExpTime) {
      const tokens = (await getTokensForAPICall()) as Record<string, unknown>;
      //refresh token API Call
      const result = await postApiCall({
        apiEndPoint: `${USERS}/regenerate-tokens`,
        requestType: "POST",
        apiPostData: {
          refreshToken: tokens?.refreshToken,
        },
      });

      if (result.success && result?.response?.authenticationResult?.idToken) {
        updateWebTokenExpiryTime(result?.response?.authenticationResult?.expiresIn);
        refreshToken(result?.response?.authenticationResult);
        isWebTokenRefreshInProgress = false;
        return true;
      } else {
        isWebTokenRefreshInProgress = false;
        refreshTokenExpired();
        const error = result?.error?.apiError;
        Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.SESSION_TIMEOUT, {
          tokenType: "refresh",
          errorType: error?.status ? error?.status : "null",
          messageType: "none",
          timeToExpire: tokenExpirationTime ? new Date(Number(tokenExpirationTime) * 1000).toUTCString() : "",
        });
        return false;
      }
    } else {
      isWebTokenRefreshInProgress = false;
      return false;
    }
  } else {
    return false;
  }
};

export const setWebInitialValue: any = () => {
  time = 0;
};
