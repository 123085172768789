export const NAME_REGEX = /^[A-Za-z][A-Za-z\s]*$/;
export const PHONE_REGEX = /^[1-9][0-9]{9}$/;
export const EMAIL_REGEX = /REGEX ( UPPER ( EmailField__c ) ,"^[A-Z0-9._%+-/!#$%&'*=?^_`{|}~]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$")/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const MASK_PHONE_REGEX = /([\d]{0,3})?([\d]{0,3})?([\d]{0,4})?/;
export const MASK_PAYMENT_CARD = /([\d]{0,4})?([\d]{0,4})?([\d]{0,4})?([\d]{0,4})?/;
export const MASK_AMEX_CARD = /([\d]{0,4})?([\d]{0,6})?([\d]{0,5})?/;
export const MASK_EXPIRATION_NUMBER = /([\d]{0,2})?([\d]{0,2})?/;
export const STATE_REGEX = /[^A-Za-z]/g;
export const ZIP_REGEX = /\D/g;
export const NO_SPECIAL_CHARACTERS_AND_SPACE = /[^A-Za-z]/g;
export const MASK_PHONE_WITH_STAR = /^(\D*\d\D*){6}/gm;
export const PHONE_REPLACE_NUMBER = /\d/g;
export const NAME_YOUR_FAVE_REGEX = /[._%+-/!<>@#$%&'*=?^_`{|}~():;'"\s\\[\]]/;
export const ONLY_NUMBERS = /^[0-9\b]+$/;
export const TIP_AMOUNT_REGEX = /^[0-9][.\d]*(,\d+)?$/;
export const MIN_MAX_REGEX = /\d+/;
export const NAME_MAX_LIMIT_REGEX = /^[A-Za-z]{1,25}$/;
export const IS_URL_REGEX = /^http[s]?:\/\//;
export const MASK_GIFT_CARD_REGEX = /([\d]{0,4})?([\d]{0,4})?([\d]{0,4})?([\d]{0,4})?/;
export const CREDIT_CARD_NUMBER_REGEX = /^[0-9]+$/;
export const NAME_REGEX_ALPHANUMERIC = /^[\w\d\s]*$/;
export const VEHICLE_INFO_REGEX = /^[A-Za-z0-9]*$/;
export const ZIP_CODE_REGEX = /\d{5}/;
export const MENU_URL_REGEX = /^\/menu(\/[a-zA-Z0-9-]*){2}$/;
export const US_STATE_REGEX =
  /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/;
export const MASK_FORMAT_PHONE_REGEX = /^\(?(\d{3})\)?[ ](\d{3})[- ]?(\d{4})$/;
export const ONLY_ALPHABET = /[a-zA-Z]/i;
export const NAME_NUMBER_REGEX = /^[A-Za-z0-9]/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const LOWERCASE_REGEX = /[a-z]/;
export const SPECIAL_CHARECTER_REGEX = /[!@#$%^&*(),.?":{}|<>]/;
export const NUMBERS_REGEX = /\d/;
export const DECIMAL_REGEX = /^\d*\.?\d*$/;
export const TWO_DECIMAL_REGEX = /^(\d*\.?\d{0,2}$)/;
export const PRODUCTS_REGEX = /^\/products/;
export const PHONE_FORMAT = /^(\d{3})(\d{3})(\d{4})$/;
export const PHONE_FORMAT_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im; /* eslint-disable-line no-useless-escape */
export const REFERRAL_CODE_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
