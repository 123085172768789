import { KeyboardEventHandler } from "react";
// Handle callback that should be triggered when user uses keyboard action on focused element
export default function onKey(callback: Function): KeyboardEventHandler<HTMLElement> {
  return (event) => {
    // Do nothing if the key is not accepted as action
    if (event.key !== "Enter") {
      return;
    }
    callback(event);
  };
}
