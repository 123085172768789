import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_404 } from "api/endpoints";
import { Error404CMSData } from "types/cms/error-404";

export const getError404CMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_404,
  }) as Promise<CommonLayerResponseDataWithType<Error404CMSData>>;
};
