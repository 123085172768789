import styled from "styled-components";
import Modal from "components/atomic-components/atoms/layout-elements/Modal/Modal";
import { IC_CHECKBOX_WHITE_SM } from "assets/images";

export const LocationFilteredSearchStyled = styled(Modal)`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  .modal-style {
    height: 100%;
    margin-top: 120px;
  }
  .locationFilterSearch {
    height: 652px;
    width: 632px;
    padding: 100px 0 60px 0;
    background: ${({ theme }) => theme.color?.primary.coleslawWhite};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .applyBtn {
      width: 220px;
      margin-top: 20px;
    }
  }
  ${(props) => props.theme.responsive?.max.lg} {
    .modal-style {
      height: 100%;
      margin-top: 0;
    }
    .locationFilterSearch {
      width: 100%;
      height: auto;
      min-height: 100vh;
      border-radius: 0;
      padding: 80px 0 60px 0;
      .applyBtn {
        width: 200px;
        height: 36px !important;
        margin-top: 10px;
      }
    }
  }
`;

export const HorizontalLine = styled.div`
  width: 392px;
  height: 1px;
  background: ${({ theme }) => theme.color?.secondary.coolGray4};
  display: flex;
  justify-content: center;
  margin: 3px auto 10px auto;
  ${(props) => props.theme.responsive?.max.lg} {
    width: calc(100% - 96px);
  }
`;

export const DistanceRadioButton = styled.div`
  height: 96px;
  width: 392px;
  .subHeading {
    letter-spacing: 0;
  }
  .distanceGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 20px;
    margin-top: 15px;
    .checkbox-wrapper {
      min-width: 90px;
      label:before {
        width: 18px !important;
        height: 18px !important;
        top: 2px;
      }
      /* stylelint-disable no-descending-specificity */
      input:not(:checked) + label:before {
        background: ${({ theme }) => theme.color?.core.white};
      }
      input:not(:disabled):checked + label:after {
        background-repeat: no-repeat !important;
        position: absolute !important;
        background-image: url(${IC_CHECKBOX_WHITE_SM.src}) !important;
        left: 3px !important;
        top: 7px !important;
      }
    }
    label {
      font-size: 18px;
      font-weight: 500;
      font-family: ${(props) => props.theme.font?.barlow};
    }
  }
  ${(props) => props.theme.responsive?.max.lg} {
    width: 100%;
    padding: 0 48px;
    height: auto;
  }
`;

export const AmenitiesCheckbox = styled.div`
  height: 172px;
  width: 392px;
  margin-top: 10px;
  .amenitiesGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
    margin-top: 15px;
    .checkbox-wrapper {
      min-width: 50%;
      label:before {
        width: 18px !important;
        height: 18px !important;
        top: 2px;
      }
      input:not(:checked) + label:before {
        background: ${({ theme }) => theme.color?.core.white};
      }
      input:not(:disabled):checked + label:after {
        background-repeat: no-repeat !important;
        position: absolute !important;
        background-image: url(${IC_CHECKBOX_WHITE_SM.src}) !important;
        left: 3px !important;
        top: 7px !important;
      }
    }
    label {
      font-size: 18px;
      font-weight: 500;
      font-family: ${(props) => props.theme.font?.barlow};
    }
  }
  ${(props) => props.theme.responsive?.max.lg} {
    width: 100%;
    padding: 0 48px;
    height: auto;
    .amenitiesGroup {
      flex-wrap: none;
      flex-direction: column;
    }
  }
`;
