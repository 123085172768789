import React from "react";
import { useSelector } from "react-redux";
import useModal from "hooks/useModal";
import { RootState } from "src/types/types";
import { MobileComponent } from "components/global/MobileComponent/MobileComponents";
import { defaultOffsetTime } from "constants/constant";
import Modal from "components/atomic-components/atoms/layout-elements/Modal/Modal";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import { HorizontalLine } from "components/atomic-components/atoms/HorizontalLineStyled/HorizontalLineStyled";
import { getValidCurbsideOrders, groupByStoreId, sortedCurbsideOrders } from "../../helper";
import OrderDetailsAccordion from "./OrderItemAccordion";
import { ViewOrdersModalStyled } from "./ViewOrdersModalStyled";

const ViewOrdersModal = () => {
  const { validCurbsideOrdersList, storeDetail } = useSelector((state: RootState) => ({
    validCurbsideOrdersList: state.placeOrder?.validCurbsideOrdersList,
    storeDetail: state.store.storeDetail,
  }));

  const { modal, setModal } = useModal();
  const offset = storeDetail?.utcOffset || defaultOffsetTime;
  const curbsideOrdersReadyForDevlivery = getValidCurbsideOrders(validCurbsideOrdersList, offset, true);

  const sortedCurbsideOrdersList = sortedCurbsideOrders(curbsideOrdersReadyForDevlivery);
  const groupedData = groupByStoreId(sortedCurbsideOrdersList, "storeId");

  return (
    <MobileComponent>
      <ViewOrdersModalStyled>
        <Modal showCloseButton isOpen={modal === "CURBSIDE_VIEW_ORDERS_MODAL"} onClose={() => setModal("")} className="modal qt">
          <div className="modalContent">
            <div className="header">
              <div className="headerContainer">
                <Headings variant="primaryHeading2" mobileFontSize={28}>
                  Curbside&nbsp;
                </Headings>
                <Headings variant="primaryHeading1Red" mobileFontSize={24}>
                  Pickup
                </Headings>
              </div>
              <HorizontalLine className="hrModal" />
            </div>

            {Object.keys(groupedData).map((curbsideOrderDetails: any) => (
              <OrderDetailsAccordion
                sortedCurbsideOrdersList={groupedData}
                curbsideOrderDetails={curbsideOrderDetails}
                key={curbsideOrderDetails.orderId}
              />
            ))}
          </div>
        </Modal>
      </ViewOrdersModalStyled>
    </MobileComponent>
  );
};

export default ViewOrdersModal;
