import dayjs from "dayjs";
import "dayjs/plugin/utc";
import { RecentOrderResponse } from "src/types/types";
import { CURBSIDE, ORDERPLACED } from "constants/constant";
import { convertTimeStampToMilliSeconds } from "utils/helper/dates";

export const getCurrentTimeBasedOnOffset = (storeOffset: number) => {
  const isNegative = storeOffset < 0;
  const currentTimeWithOffset = isNegative ? dayjs.utc().subtract(Math.abs(storeOffset), "h") : dayjs.utc().add(storeOffset, "h");
  return currentTimeWithOffset.format();
};

export const convertTimeStampToUTCFormat = (timeStamp: string) => {
  return dayjs(String(timeStamp)).utc(true).format();
};

// Show Curbside order I'm Here Button or Persistent Banner
export const showCurbsideOrderReadyButton = (orderReadyTime: string, storeOffset: number) => {
  const currentUTCTimeInMilliSeconds = convertTimeStampToMilliSeconds(getCurrentTimeBasedOnOffset(storeOffset));
  const orderReadyTimeInMilliSeconds = convertTimeStampToMilliSeconds(orderReadyTime);
  const priorCurbsideReadyTimeInMilliSeconds = 300 * 1000;
  const additionalCurbsideReadyTimeInMilliSeconds = 1800 * 1000;

  // Check If the current time is less than 5 mins prior to order Ready Time
  if (currentUTCTimeInMilliSeconds < orderReadyTimeInMilliSeconds - priorCurbsideReadyTimeInMilliSeconds) {
    return { isOrderPlaced: true, showReadyButton: false };
  }

  // Check If the current time is 5 mins prior to order ready time & current time is within order ready time plus 30 mins time
  if (
    currentUTCTimeInMilliSeconds + priorCurbsideReadyTimeInMilliSeconds >= orderReadyTimeInMilliSeconds &&
    orderReadyTimeInMilliSeconds + additionalCurbsideReadyTimeInMilliSeconds >= currentUTCTimeInMilliSeconds
  ) {
    return { isOrderPlaced: true, showReadyButton: true };
  }

  return {
    isOrderPlaced: false,
    showReadyButton: false,
  };
};

export const showMultipleCurbsideOrderReadyButton = (validCurbsideOrdersList: Array<RecentOrderResponse>, storeOffset: number) => {
  // Get all the orders of the ready time
  const allCurbsideOrdersReadyTimeList = Object.values(validCurbsideOrdersList.map((validCurbsideOrder) => validCurbsideOrder.readyTime));
  const allCurbsideOrdersStatusList = allCurbsideOrdersReadyTimeList.map((curbsideOrdersReadyTime) =>
    showCurbsideOrderReadyButton(convertTimeStampToUTCFormat(String(curbsideOrdersReadyTime)), storeOffset)
  );

  const isOrderPlaced = allCurbsideOrdersStatusList.some((curbsideOrdersStatus) => curbsideOrdersStatus.isOrderPlaced);
  const showReadyButton = allCurbsideOrdersStatusList.some((curbsideOrdersStatus) => curbsideOrdersStatus.showReadyButton);

  return {
    isOrderPlaced,
    showReadyButton,
  };
};

export const showCurbsideOrderReadyForDelivery = (validCurbsideOrdersList: Array<RecentOrderResponse>, storeOffset: number) => {
  if (validCurbsideOrdersList?.length < 1) {
    return {
      isOrderPlaced: false,
      showReadyButton: false,
    };
  }

  return validCurbsideOrdersList?.length > 1
    ? showMultipleCurbsideOrderReadyButton(validCurbsideOrdersList, storeOffset)
    : showCurbsideOrderReadyButton(convertTimeStampToUTCFormat(String(validCurbsideOrdersList[0].readyTime)), storeOffset);
};

export const convertArrivalStatusToLowerCase = (orderArrivalStatus: string) => {
  return orderArrivalStatus?.toString().replace(/\s/g, "")?.toLowerCase();
};

export const checkIsCurbSideOrderReadyForDelivery = (
  validCurbsideOrder: RecentOrderResponse,
  storeOffset: number,
  isViewOrdersModalOpen?: boolean
) => {
  if (validCurbsideOrder?.handoffMode?.toLowerCase() === CURBSIDE) {
    // check the arrival status is for the order is placed
    if (convertArrivalStatusToLowerCase(validCurbsideOrder.arrivalStatus) === ORDERPLACED || validCurbsideOrder.arrivalStatus === null) {
      const { isOrderPlaced, showReadyButton } = showCurbsideOrderReadyButton(
        convertTimeStampToUTCFormat(validCurbsideOrder.readyTime),
        storeOffset
      );
      return isViewOrdersModalOpen ? isOrderPlaced && showReadyButton : isOrderPlaced;
    }
    return false;
  }
  return false;
};

export const getValidCurbsideOrders = (ordersList: Array<RecentOrderResponse>, storeOffset: number, isViewOrdersModalOpen?: boolean) => {
  const filterCurbsideOrdersFromList = ordersList?.filter((recentOrderList) =>
    checkIsCurbSideOrderReadyForDelivery(recentOrderList, storeOffset, isViewOrdersModalOpen)
  );
  return filterCurbsideOrdersFromList?.length > 0 ? filterCurbsideOrdersFromList : [];
};

export const sortedCurbsideOrders = (ordersList: any) => {
  const orderListCopy = [...ordersList];
  orderListCopy.sort((a, b) => {
    return convertTimeStampToMilliSeconds(a.readyTime) - convertTimeStampToMilliSeconds(b.readyTime);
  });
  return orderListCopy;
};

export const groupBy = (data: [], key: string) => {
  return data?.reduce((acc: any, val: any, index: number) => {
    const indexAcc = Object.keys(acc).find((accNewVal) => accNewVal.includes(val[key]));

    if (indexAcc) {
      acc[indexAcc] = [...acc[indexAcc], val];
    } else {
      const indexVal = `${index}-${[val[key]]}`;

      acc = { ...acc, [indexVal]: [val] };
    }

    return acc;
  }, {});
};

export const groupByStoreId = (orderList: any, key: string) => {
  return groupBy(orderList, key);
};
