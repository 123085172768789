import Link from "next/link";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import useModal from "hooks/useModal";
import { RootState } from "src/types/types";
import { setCurbsideCheckInModalData } from "redux/reducer/PlaceOrder/PlaceOrderActions";
import { SET_ORDER_DETAILS } from "redux/reducer/PlaceOrder/PlaceOrderConst";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Button from "components/atomic-components/atoms/button/Button";
import { defaultOffsetTime } from "constants/constant";
import { getValidCurbsideOrders } from "./helper";
import { CurbsideBannerStyled } from "./CurbsideBannerStyled";

const CurbsideBanner = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { device, showCurbsideReadyButton, validCurbsideOrdersList, storeDetail } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
    showCurbsideReadyButton: state?.placeOrder?.showCurbsideReadyButton,
    validCurbsideOrdersList: state.placeOrder.validCurbsideOrdersList,
    storeDetail: state.store.storeDetail,
  }));

  const { setModal } = useModal();

  if (device !== "MOBILE" || validCurbsideOrdersList?.length <= 0) {
    return null;
  }

  const isPageBlackListed = ["/menu/[categoryName]/[productTitle]", "/cart", "/checkout", "/placing-order", "/confirmation"];

  const handleOrderLink = () => {
    dispatch({ type: SET_ORDER_DETAILS, payload: validCurbsideOrdersList[0] });
  };

  const offset = storeDetail?.utcOffset || defaultOffsetTime;
  const curbsideOrdersReadyForDevlivery = getValidCurbsideOrders(validCurbsideOrdersList, offset, true);

  const hasMultipleCurbsideOrders = curbsideOrdersReadyForDevlivery?.length > 1;

  const handleSingleOrderButton = () => {
    if (validCurbsideOrdersList[0]?.orderId) {
      setModal("CURBSIDE_CHECKIN_MODAL");
      dispatch(setCurbsideCheckInModalData([validCurbsideOrdersList[0].orderId]));
    }
  };

  const handleMultipleOrdersButton = () => {
    setModal("CURBSIDE_VIEW_ORDERS_MODAL");
    dispatch(setCurbsideCheckInModalData([]));
  };

  const renderActionButton = () => {
    if (hasMultipleCurbsideOrders) {
      return (
        <Button variant="outlinedWhite" className="multiOrderButton" onClick={handleMultipleOrdersButton}>
          VIEW ORDERS
        </Button>
      );
    } else {
      return (
        <Button className="imHereButton" variant="primaryWhite" onClick={handleSingleOrderButton}>
          I&apos;M HERE
        </Button>
      );
    }
  };

  return (
    <>
      {device === "MOBILE" && showCurbsideReadyButton && !isPageBlackListed.includes(router.pathname) ? (
        <CurbsideBannerStyled className="curbside-banner">
          <div className="storeDetails">
            <div className="headingContainer">
              <Headings variant="primaryHeading2" mobileFontSize={28} className="whitePart">
                Curbside&nbsp;
              </Headings>
              <Headings variant="primaryHeading1Red" mobileFontSize={24} mobileFontWeight={700}>
                Pickup
              </Headings>
            </div>
            {!hasMultipleCurbsideOrders && (
              <Link href="/confirmation" passHref>
                <Button variant="primary" onClick={handleOrderLink} className="orderConfirmationLink">
                  {validCurbsideOrdersList[0]?.storeDetails?.address}
                </Button>
              </Link>
            )}
          </div>
          <div className="actionButtons">{renderActionButton()}</div>
        </CurbsideBannerStyled>
      ) : null}
    </>
  );
};
export default CurbsideBanner;
