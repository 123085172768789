import secureLocalStorage from "react-secure-storage";

export const getLocalStorageData = (key: string) => {
  if (typeof window !== "undefined") {
    if (key === "tokens") {
      return secureLocalStorage.getItem(key)?.toString();
    }
    return localStorage.getItem(key);
  }
};

export const setLocalStorageData = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    if (key === "tokens") {
      secureLocalStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  }
};

export const deleteLocalStorageData = (key: string) => {
  if (typeof window !== "undefined") {
    if (key === "tokens") {
      secureLocalStorage.removeItem(key);
    } else {
      localStorage.removeItem(key);
    }
  }
};

export const clearLocalStorageData = () => {
  if (typeof window !== "undefined") {
    localStorage.clear();
  }
};

export const getSessionStorageData = (key: string): string | undefined | null => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem(key);
  }
};

export const setSessionStorageData = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem(key, value);
  }
};

export const deleteSessionStorageData = (key: string) => {
  if (typeof window !== "undefined") {
    sessionStorage.removeItem(key);
  }
};

export const clearSessionStorageData = () => {
  if (typeof window !== "undefined") {
    sessionStorage.clear();
  }
};

export const removeAllCookie = () => {
  const allCookies = document.cookie.split(";");
  // The "expire" attribute of every cookie is
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  for (const cookie of allCookies) {
    document.cookie = cookie + "=;expires=" + new Date(0).toUTCString() + ";path=/;";
  }
};
export const removeCookieByName = (name: string) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/;";
};

function getCookieValue(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (const cookie of ca) {
    let c = cookie;
    while (c.startsWith(" ")) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const setDataToCookie = (key: any, value: any) => {
  const encryptValue = encryptData(value);
  document.cookie = key + "=" + encryptValue + ";path=/";
};

export const getDataFromCookie = (key: any) => {
  let cookieValue = "";
  try {
    cookieValue = JSON.parse(decryptData(getCookieValue(key)));
  } catch (_e) {}
  return cookieValue;
};

export const encryptData = (value: any) => {
  return window.btoa(encodeURIComponent(JSON.stringify(value)));
};

export const decryptData = (value: any) => {
  return decodeURIComponent(window.atob(value));
};

export const setCookieData = (key: string, value: string) => {
  const encryptedValue = encryptData(value);
  document.cookie = `${key}=${encryptedValue}`;
};
