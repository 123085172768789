import {
  IS_APPLE_PAY_SUPPORTED,
  PAYMENT_WITH_APPLE_PAY_SELECTED,
  PAYMENT_WITH_APPLE_PAY_SELECTED_CAN_MAKE_PAYMENTS,
  SET_APPLEPAY_IS_LOADING,
  SET_APPLE_IFRAME_URL,
} from "./const";
import {
  SetAppleIFrameAction,
  SetApplePayLoadingAction,
  SetIsApplePaySupportedAction,
  SetPayWithApplePayCanMakePaymentsAction,
  SetPayWithApplePaySelectedAction,
} from "./types";

export const setAppleIFrameURL = (appleIFrame: string): SetAppleIFrameAction => ({
  type: SET_APPLE_IFRAME_URL,
  payload: appleIFrame,
});

export const setApplePayLoader = (isLoading: boolean): SetApplePayLoadingAction => ({
  type: SET_APPLEPAY_IS_LOADING,
  payload: isLoading,
});

export const setPayWithApplePaySelected = (data: boolean): SetPayWithApplePaySelectedAction => ({
  type: PAYMENT_WITH_APPLE_PAY_SELECTED,
  payload: data,
});

export const isApplePaySupported = (data: boolean): SetIsApplePaySupportedAction => ({
  type: IS_APPLE_PAY_SUPPORTED,
  payload: data,
});

export const setPayWithApplePayCanMakePayments = (data: boolean): SetPayWithApplePayCanMakePaymentsAction => ({
  type: PAYMENT_WITH_APPLE_PAY_SELECTED_CAN_MAKE_PAYMENTS,
  payload: data,
});
