import isClient from "utils/helper/default";
import { CreditCardType } from "src/types/types";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    FreedomPay?: any;
  }
}

export const isApplePaySupported = () => {
  if (isClient() && window && window.FreedomPay?.Apm?.ApplePay.isSupported() && window.FreedomPay?.Apm?.ApplePay.canMakePayments()) {
    return true;
  } else {
    return false;
  }
};

export const applePayBrowserSupport = () => {
  if (isClient() && window && window.FreedomPay?.Apm?.ApplePay.isSupported()) {
    return true;
  } else {
    return false;
  }
};

export const canMakeApplePayPayments = () => {
  if (isClient() && window && window.FreedomPay?.Apm?.ApplePay.canMakePayments()) {
    return true;
  } else {
    return false;
  }
};

export const canMakeGooglePayPayment = () => {
  if (isClient()) {
    const userAgent = window.navigator.userAgent;
    return userAgent.match(/chrome/i) && !/iPhone|iPad|iPod/.test(userAgent);
  }
  return false;
};

export const formatCreditCardData = (data: Array<CreditCardType>) => {
  const filteredList = data.map((filterCard: CreditCardType) => {
    filterCard.errorMessage = "";
    if (filterCard.expired && !filterCard.expiringSoon) {
      filterCard.errorMessage = "Card Expired. Please Delete";
    } else if (!filterCard.expired && filterCard.expiringSoon) {
      filterCard.errorMessage = "! CARD WILL EXPIRE SOON. PLEASE UPDATE.";
    }
    return filterCard;
  });

  return [...filteredList]
    .sort((x, y) => {
      return (x.default as any) - (y.default as any);
    })
    .reverse();
};

export const getIframeStylesBasedOnPaymentType = (isApplePay: boolean, isMobile: boolean) => {
  let style = `
  label {
    font-size: 14px;
    display: none;
    color: #0C2340;
  }
  form {
    padding: 0;
    width: 100%;
    height: 100%;
  }
  label + .validation-message {
    width: 386px;
    height: 60px;
  }
  i {
    top: 36px;
    position: relative;
  }
  input {
    height: 60px;
    border: 2px solid #BBBCBC;
    font-weight: 800;
    color: #0c2340;
    font-size: 18px;
    font-weight: 800;
  }
  .invalid input:not(:disabled):focus {
    color: #0c2340;
    box-shadow: none;
  }
  input:focus {
    box-shadow: none;
    border: 2px solid #bbbcbc
  }
  input::placeholder {
    font-size: 14px;
    font-weight: 800;
  }
  .validation-message.feedback {
    font-family: Calibri;
    color: #E4002A;
    font-weight: 800;
  }
  label:last-chid {
    padding: 50px;
  }
  button {
    background-color: #0C2340;
    text-transform: uppercase;
    font-family: Calibri;
    font-size: 18px;
    line-height: 17px;
    font-weight: 800;
    padding: 12px 0;
    border-radius: 38px;
    height: 44px;
  }
  .invalid input:not(:disabled) {
    border: 2px solid #e4002a;
  }
  s`;
  if (isApplePay) {
    style += `
    iframe{
      height:40px;
      width: 260px;
      border-radius: 22px;
      overflow: hidden; 
      display: flex;
      justify-content: center;
    }
    form {
      text-align:center;
      background-color: #000000;
    }
    form a{
      height: 48px;
      border-radius:50px;
      width: 333px;
    }
    .fp-apple-pay-container{
      overflow:hidden;
    }
    .fp-apple-pay-button{
      height: 50px;
      border-radius:20px;
      width: 260px;
    }
    `;
  }
  return style;
};

export const getCrediCardIframeStyles = (isMobile: boolean) => `
  label {
    font-size: 0px;
    margin: 0;
  }
 
  form {
    width: 100%;
    height: 100%;
    padding: 0 30px;
  }
 
  label + .validation-message {
    width: 386px;
    height: 60px;
  }

  i {
    top: 36px;
    position: relative;
  }

  input {
    height: 60px;
    border: 2px solid #D9D9D6;
    color: #041E42;
    font-size: 16px;
    font-weight: ${isMobile ? 700 : 800};
    line-height: 19.2px;
    padding: ${isMobile ? "8px 0 8px 16px" : "0 16px"};
  }

  .invalid input:not(:disabled):focus {
    color: #041E42;
    box-shadow: none;
  }

  input:focus {
    box-shadow: none;
    border: 2px solid #041E42;
  }

  input::placeholder {
    color: #041E42;
    text-transform: uppercase;
  }
  
  .invalid input:not(:disabled) {
    border: 2px solid #E4002B;
  }

  .valid input:not(:disabled) {
    border-color: #D9D9D6;
  }

  .valid input:not(:disabled):focus {
    border-color: #D9D9D6;
  }
  
  .validation-message.feedback {
    color: #E4002B;
    margin-top: 4px;
    font-size: 11px;
    font-weight: 700;
    line-height: 11px;
    text-transform: uppercase;
  }

  button {
    background-color: #0C2340;
    text-transform: uppercase;
    font-family: Calibri;
    font-size: 18px;
    line-height: 17px;
    font-weight: 800;
    padding: 12px 0;
    border-radius: 38px;
    height: 44px;
  }

  form + .control-group {
    font-size: 0px;
    background-color: red;
  }
`;
