import { useSelector } from "react-redux";
import { RootState, Store } from "src/types/types";
import AddressPickupCard from "../AddressPickupCard/AddressPickupCard";

const AddressSelectedPickupCard = () => {
  const { filteredStoreList, selectedStoreId } = useSelector((state: RootState) => ({
    filteredStoreList: state.store?.filteredStoreList,
    selectedStoreId: state.store.selectedStoreId,
    device: state.currentDevice.device,
  }));

  if (filteredStoreList) {
    filteredStoreList.sort((a: Store) => (a.storeId === selectedStoreId ? -1 : 0));
  }

  return <AddressPickupCard />;
};

export default AddressSelectedPickupCard;
