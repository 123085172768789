import styled from "styled-components";
import OrderNowModal from "../OrderNowModal/OrderNowModal";

export const NotNearAddressStyled = styled(OrderNowModal)`
  .heading {
    max-width: 380px;
    margin-bottom: 25px;
    font-family: ${(props) => props.theme.font.scriptRough};
    color: ${(props) => props.theme.color.core.blue};
    text-align: center;
  }
  .modalContent {
    background: ${(props) => props.theme.color.secondary.lightBlue};
    border-radius: 40px;
  }
  .addressCard {
    margin: 25px 0;
    width: 100%;
    max-width: 285px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: auto 1fr;
    border-radius: 20px;
    background: ${(props) => props.theme.color.secondary.lightGrey};

    .locationPin {
      grid-row: 1 / span 3;
      padding: 15px;
      display: grid;
      place-items: center;
    }

    .addressLineOne {
      font-size: 18px;
    }

    .addressLine {
      font-size: 14px;
    }
  }
`;
