import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import useModal from "hooks/useModal";
import PromptModal from "components/atomic-components/molecules/Modals/PromptModal/PromptModal";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import { StoreRequestConfirmationStyled, Body, Footer } from "./StoreRequestConfirmationStyled";

const StoreRequestConfirmation = () => {
  const router = useRouter();
  const { modal, setModal } = useModal();

  const { storeDetail } = useSelector((state: RootState) => ({
    storeDetail: state.store?.storeDetail,
  }));

  const onClickMenuButton = () => {
    setModal("");
    router.replace(
      {
        pathname: "/menu/",
        query: { storeId: storeDetail.storeId },
      },
      "/menu"
    );
  };

  const onClickHomeButton = () => {
    setModal("");
    router.push("/");
  };

  const BODY = (
    <Body>
      <Headings
        variant="secondaryHeading2"
        fontSize={40}
        lineHeight={42}
        fontWeight={800}
        mobileFontSize={24}
        mobileLineHeight={24}
        className="popupHeading"
      >
        THANK YOU!
      </Headings>

      <Paragraph variant="primaryParagraph1" fontSize={18} fontWeight={400} className="popupContent">
        A store coordinator will be contacting you shortly with some additional information.
      </Paragraph>
    </Body>
  );

  const FOOTER = (
    <Footer>
      <div className="buttonContainer">
        <Button variant="primaryRed" onClick={onClickMenuButton}>
          EXPLORE MENU
        </Button>

        <Button variant="secondaryWhite" onClick={onClickHomeButton}>
          GO TO HOME
        </Button>
      </div>
    </Footer>
  );
  return (
    <StoreRequestConfirmationStyled>
      <PromptModal isOpen={modal === "SUBMIT_REQUEST_CONFIRMATION"} onClose={() => setModal("")} body={BODY} footer={FOOTER} />
    </StoreRequestConfirmationStyled>
  );
};

export default StoreRequestConfirmation;
