import React, { useEffect } from "react";
import { PAGE_NAME } from "constants/analytics";
import { getLocalStorageData } from "utils/storage";
import { sendPasswordVerificationCode } from "api/users/send-password-verification-code";
import SendCode from "components/global/SendCode/SendCode";
import Analytics from "analytics/Analytics";
import ForgotPasswordContext from "../ForgotPasswordContext";
import { ForgotPasswordSendCodeStyled } from "./ForgotPasswordSendCodeStyled";

interface Props {
  handleClose: () => void;
  goToPreviousPage?: () => void;
}

const ForgotPasswordSendCode = ({ handleClose, goToPreviousPage }: Props) => {
  const { options, username, setStep, setMedium } = React.useContext(ForgotPasswordContext);

  const logPageViewEvent = async () => {
    const isUserMigrated = getLocalStorageData("isUserMigrated");

    if (isUserMigrated === "Yes") {
      Analytics.getInstance().logEvent("page_view", {
        page_name: PAGE_NAME.MIGRATION_SEND_A_CODE,
      });
    }
  };

  useEffect(() => {
    logPageViewEvent();
  }, []);

  const getCodeDeliveryMedium = (selected: { email: boolean; phone: boolean }) => {
    if (selected.phone && selected.email) return "BOTH";
    if (selected.phone) return "PHONE";
    if (selected.email) return "EMAIL";
    return "EMAIL";
  };

  const handleSubmit = async (selected: { email: boolean; phone: boolean }) => {
    const codeDeliveryMedium = getCodeDeliveryMedium(selected);

    return sendPasswordVerificationCode({
      username,
      codeDeliveryMedium,
    }).then((response) => {
      if (response.success) {
        setMedium(codeDeliveryMedium);
        setStep("ENTER_CODE");
      }
      return response;
    });
  };

  return (
    <ForgotPasswordSendCodeStyled>
      <SendCode options={options} onCancel={handleClose} onSubmit={handleSubmit} onBackBtnClick={goToPreviousPage} isBackButtonPresent />
    </ForgotPasswordSendCodeStyled>
  );
};

export default ForgotPasswordSendCode;
