import {
  ACCOUNT_IS_LOADING,
  ACCOUNT_SYSTEM_ERROR,
  ACCOUNT_USER_SUBSCRIPTION,
  GET_ADDRESS,
  SAVE_ADDRESS,
  SET_DELETE_CARD_MODAL,
  SET_FAV_ADDRESS,
} from "./const";
import {
  AccountSystemErrorAction,
  BoolValueDeleteCardAction,
  CommSubscriptionAction,
  CommSubscriptionPayload,
  SaveAddressAction,
  SetAccountLoaderAction,
  SetAddressListAction,
  SetAddressListPayload,
  SetFavAddressAction,
  SetFavAddressPayload,
} from "./types";

export const setAddressList = (addressList: SetAddressListPayload): SetAddressListAction => ({
  type: GET_ADDRESS,
  payload: addressList,
});

export const setFavAddress = (address: SetFavAddressPayload): SetFavAddressAction => ({
  type: SET_FAV_ADDRESS,
  payload: address,
});

export const setAccountLoader = (isLoading: boolean): SetAccountLoaderAction => ({
  type: ACCOUNT_IS_LOADING,
  payload: isLoading,
});

export const setBoolForDeleteCardModal = (value: boolean): BoolValueDeleteCardAction => ({
  type: SET_DELETE_CARD_MODAL,
  payload: value,
});

export const setSaveAddress = (address: SetFavAddressPayload): SaveAddressAction => ({
  type: SAVE_ADDRESS,
  payload: address,
});

export const setAccountSystemError = (value: string): AccountSystemErrorAction => ({
  type: ACCOUNT_SYSTEM_ERROR,
  payload: value,
});

export const setCommSubscription = (value: CommSubscriptionPayload): CommSubscriptionAction => ({
  type: ACCOUNT_USER_SUBSCRIPTION,
  payload: value,
});
