import { useSelector, useDispatch } from "react-redux";
import { ANALYTICS_CONST } from "constants/analytics";
import { setDeliveryLocationName, setStoreLocation, setUserLocation } from "redux/reducer/Store/actions";
import OrderPageContainer from "containers/Order/OrderPageContainer/OrderPageContainer";
import { AddressSelectedStyled } from "containers/Order/AddressSelected/AddressSelectedStyled";
import { DEFAULT_LAT, DEFAULT_LNG } from "constants/constant";
import { setLocationOrderType, setLocationStep } from "redux/reducer/Location/LocationAction";
import Analytics from "analytics/Analytics";
import { setUserEnteredDeliveryAddress } from "redux/reducer/Address/AddressAction";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import AddressDeliveryCard from "../AddressDeliveryCard/AddressDeliveryCard";
import { DeliveryCardOutsideRadiusStyled } from "./DeliveryCardOutsideRadiusStyled";

interface Props {
  className?: string;
}

const DeliveryCardOutsideRadius = ({ className }: Props) => {
  const dispatch = useDispatch();

  const { userAddress, userGeolocationStatus, userLocation, deliveryLocationCoordinates, corporateStoreId, device } = useSelector(
    (state: any) => ({
      userAddress: state?.address?.userAddress,
      userGeolocationStatus: state?.userStatus?.presentUserGeolocationStatus,
      userLocation: state?.store?.userLocation,
      deliveryLocationCoordinates: state.store.deliveryLocationCoordinates,
      corporateStoreId: state.store?.corporateStoreId,
      device: state?.currentDevice.device,
    })
  );

  const onBack = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            setStoreLocation({
              lat: deliveryLocationCoordinates.lat,
              lng: deliveryLocationCoordinates.lng,
            })
          );
          dispatch(
            setUserEnteredDeliveryAddress({
              lat: deliveryLocationCoordinates.lat,
              lng: deliveryLocationCoordinates.lng,
            })
          );
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            dispatch(setUserEnteredDeliveryAddress({}));
            dispatch(
              setStoreLocation({
                lat: DEFAULT_LAT,
                lng: DEFAULT_LNG,
              })
            );
            dispatch(
              setUserLocation({
                lat: DEFAULT_LAT,
                lng: DEFAULT_LNG,
              })
            );
          }
        },
        {
          maximumAge: Infinity,
          timeout: 0,
        }
      );
    }
    dispatch(setLocationStep("LOGGED_IN_SEARCH"));
    dispatch(setDeliveryLocationName(""));
  };

  let redirectLat = DEFAULT_LAT;
  let redirectLng = DEFAULT_LNG;
  if (userGeolocationStatus === true) {
    redirectLat = userLocation?.lat;
    redirectLng = userLocation?.lng;
  } else {
    redirectLat = DEFAULT_LAT;
    redirectLng = DEFAULT_LNG;
  }

  const onTryPickup = () => {
    // Added outofdelivery try pickup event in amplitude
    Analytics.getInstance().logEvent(ANALYTICS_CONST.ORDER.OUTOFDELIVERY_TRY_PICKUP, {
      ecommerce: { store_id: corporateStoreId },
    });

    dispatch(setLocationOrderType("PICKUP"));
    dispatch(setLocationStep("LOGGED_IN_SEARCH"));
    dispatch(
      setStoreLocation({
        lat: redirectLat,
        lng: redirectLng,
      })
    );
  };

  const Footer = (
    <>
      <Headings variant="headingMedium" fontSize={20} fontWeight={700} className="headingFirstText">
        DELIVERY
      </Headings>
      <Headings variant="secondaryHeading2" fontSize={20} fontWeight={700} className="headingSecondText">
        OUTSIDE OF DELIVERY RADIUS
      </Headings>
      <Paragraph variant="secondaryParagraph4" className="deliverySubText">
        <span className="boldDeliverySubText">It’s not you, it’s us.</span> Check back soon for new Zaxbys stores in your area.
      </Paragraph>
      <Button variant="modalOutlineButton" className="tryPickUpButton" onClick={onTryPickup}>
        {device === "MOBILE" ? "Try PickUp?" : "Try PickUp"}
      </Button>
    </>
  );

  return (
    <DeliveryCardOutsideRadiusStyled className="deliveryCardOutSideRadious">
      <OrderPageContainer mobileLayout="REBRAND_SCREEN">
        <AddressSelectedStyled>
          <AddressDeliveryCard onBack={onBack} className={className} Footer={Footer} storeData={userAddress} isUserAddress />
        </AddressSelectedStyled>
      </OrderPageContainer>
    </DeliveryCardOutsideRadiusStyled>
  );
};

export default DeliveryCardOutsideRadius;
