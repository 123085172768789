import { DirectionsRenderer, GoogleMap, Marker, MarkerF } from "@react-google-maps/api";
import { ReactNode, RefObject, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { IC_LOCATION_PIN_BLUE, IC_LOCATION_PIN_CHICKEN_RED, IC_ZAX_LOCATION_DOT } from "assets/images";
import { setUserCurrentLocation } from "redux/reducer/Global/GlobalAction";
import { DEFAULT_LAT, DEFAULT_LNG } from "constants/constant";
import { RootState } from "src/types/types";
import { centerMapAdjuster, getStoreLocation } from "../../atomic-components/molecules/CheckoutMap/helper/helper";

type Location = {
  lat: number;
  lng: number;
};

interface Props {
  center: Location;
  zoom: number;
  onZoomChange?: () => void;
  className?: string;
  refObj?: RefObject<GoogleMap>;
  children?: ReactNode;
  onLoad?: (map: any) => void;
  onDragEnd?: () => void;
  showDirections?: boolean;
}

const CustomGoogleMap = ({ center, className, zoom, onZoomChange, refObj, children, onLoad, onDragEnd, showDirections }: Props) => {
  const {
    storeList,
    userLocation,
    userCurrentLocation,
    selectedStoreLocations,
    direction,
    storeDetail,
    orderType,
    presentUserGeolocationStatus,
    disablePinsOnLocation,
    userEnteredDeliveryAddress,
  } = useSelector((state: RootState) => ({
    storeList: state?.store?.storeList,
    userLocation: state.store?.userLocation,
    userCurrentLocation: state.globalReducer?.userCurrentLocation,
    selectedStoreLocations: state.store?.selectedStoreLocations,
    direction: state.store?.direction,
    storeDetail: state.store?.storeDetail,
    orderType: state.location.orderType,
    presentUserGeolocationStatus: state.userStatus?.presentUserGeolocationStatus,
    disablePinsOnLocation: state.location?.disablePinsOnLocation,
    userEnteredDeliveryAddress: state.address?.userEnteredDeliveryAddress,
  }));

  const router = useRouter();
  const dispatch = useDispatch();

  const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [locationPermission, setLocationPermission] = useState<"granted" | "denied" | "prompt" | "unknown">("unknown");
  const [disablePin, setDisablePin] = useState(false);
  const storeLocation = getStoreLocation(storeDetail?.latitude, storeDetail?.longitude);
  const isPickup = orderType === "PICKUP";

  const mapOptions = {
    fullscreenControl: false,
    gestureHandling: "cooperative",
  };

  const disableHomeLocationPin = () => {
    if (router.pathname === "/confirmation") {
      setDisablePin(true);
    } else {
      setDisablePin(false);
    }
  };

  useEffect(() => {
    disableHomeLocationPin();
  }, []);

  const chromeBrowserNavigationPermissionCheck = () => {
    if (navigator.userAgent.includes("Chrome")) {
      navigator?.permissions?.query({ name: "geolocation" }).then(function (PermissionStatus) {
        setLocationPermission(PermissionStatus.state);
      });
    }
  };

  useEffect(() => {
    // Chrome browser navigation permission check

    if (navigator.userAgent.includes("Chrome")) {
      chromeBrowserNavigationPermissionCheck();
    }

    // Other browser navigation permission check

    if (locationPermission === "granted") {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Update both userLocation and currentLocation with the user's coordinates

          const userLoc = {
            lat: latitude,

            lng: longitude,
          };

          dispatch(setUserCurrentLocation(userLoc));

          // set the map's center to the user's location

          setCurrentLocation(userLoc);
        },

        (error) => {
          console.error("Error obtaining geolocation:", error);

          // Optionally set a default location here if geolocation fails

          setCurrentLocation({ lat: DEFAULT_LAT, lng: DEFAULT_LNG });
        }
      );
    } else {
      if (storeLocation) {
        const adjustedCoordinates = centerMapAdjuster(storeLocation);

        setCurrentLocation(adjustedCoordinates);
      } else {
        // Default Store location

        setCurrentLocation({ lat: DEFAULT_LAT, lng: DEFAULT_LNG });
      }
    }
  }, [locationPermission]);

  useEffect(() => {
    function success() {
      setLocationPermission("granted");
    }

    function error(err: any) {
      setLocationPermission("denied");

      console.warn(`Error: ${err.message}`);
    }

    if ("permissions" in navigator) {
      navigator.permissions

        .query({
          name: "geolocation",
        })

        .then((result) => {
          navigator.geolocation.getCurrentPosition(success, error, {
            timeout: 4000,

            maximumAge: 0,
          });
        });
    }
  }, []);

  if (!currentLocation) {
    setCurrentLocation({ lat: DEFAULT_LAT, lng: DEFAULT_LNG });
  }

  // Loading the Google Maps only on the client side

  if (typeof window === "undefined" || typeof window.google === "undefined") {
    return <></>;
  }

  return (
    <GoogleMap
      mapContainerClassName={className}
      ref={refObj}
      center={center}
      zoom={zoom}
      onZoomChanged={onZoomChange}
      onLoad={onLoad}
      onDragEnd={onDragEnd}
      options={mapOptions}
    >
      {!presentUserGeolocationStatus && storeList?.length === 0 && userEnteredDeliveryAddress.lat === 0 && (
        <Marker
          position={{
            lat: DEFAULT_LAT,

            lng: DEFAULT_LNG,
          }}
          icon={IC_LOCATION_PIN_BLUE.src}
        />
      )}

      {isPickup &&
        storeList?.length > 0 &&
        userCurrentLocation &&
        storeList.map((item: any) => {
          return (
            <MarkerF key={item.storeId} position={{ lat: item.latitude, lng: item.longitude }} icon={IC_LOCATION_PIN_CHICKEN_RED.src} />
          );
        })}

      {!disablePin && orderType === "DELIVERY" && (
        <>
          {userEnteredDeliveryAddress.lat && userEnteredDeliveryAddress.lng && (
            <MarkerF
              position={{
                lat: parseFloat(userEnteredDeliveryAddress.lat.toString()),

                lng: parseFloat(userEnteredDeliveryAddress.lng.toString()),
              }}
              icon={IC_LOCATION_PIN_CHICKEN_RED.src}
            />
          )}
        </>
      )}
      {isPickup && storeList?.length === 0 && (
        <MarkerF
          position={{
            lat: parseFloat(storeLocation.lat.toString()),
            lng: parseFloat(storeLocation.lng.toString()),
          }}
          icon={IC_LOCATION_PIN_CHICKEN_RED.src}
        />
      )}
      {userLocation && selectedStoreLocations && disablePinsOnLocation && (
        <Marker
          position={{
            lat: parseFloat(selectedStoreLocations.lat),
            lng: parseFloat(selectedStoreLocations.lng),
          }}
          icon={IC_LOCATION_PIN_CHICKEN_RED.src}
        />
      )}

      {userCurrentLocation && (
        <MarkerF
          position={{
            lat: userCurrentLocation.lat,

            lng: userCurrentLocation.lng,
          }}
          icon={{ url: `${IC_ZAX_LOCATION_DOT.src}` }}
        />
      )}
      {userCurrentLocation &&
        orderType === "DELIVERY" &&
        userEnteredDeliveryAddress?.lat &&
        userEnteredDeliveryAddress?.lng?.toString() && (
          <MarkerF
            position={{
              lat: parseFloat(userEnteredDeliveryAddress?.lat?.toString()),
              lng: parseFloat(userEnteredDeliveryAddress?.lng?.toString()),
            }}
            icon={{ url: `${IC_LOCATION_PIN_CHICKEN_RED.src}` }}
          />
        )}
      {isPickup && storeDetail?.latitude && storeDetail?.longitude && disablePinsOnLocation && (
        <MarkerF
          position={{
            lat: storeDetail.latitude,

            lng: storeDetail.longitude,
          }}
          icon={IC_LOCATION_PIN_CHICKEN_RED.src}
        />
      )}

      {showDirections && direction && <DirectionsRenderer directions={direction} />}

      {children || <></>}
    </GoogleMap>
  );
};

export default CustomGoogleMap;
