import { ReactNode } from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import StackedButtonsContainer from "../StackedButtonsContainer/StackedButtonsContainer";
import { ValueUpdatedStyled } from "./ValueUpdatedStyled";

interface Props {
  valueName: string;
  buttons: ReactNode;
  successMessage?: string;
  actionType?: string;
}
const ValueUpdated = ({ valueName, buttons, successMessage, actionType = "updated" }: Props) => {
  const { width } = useWindowDimensions();
  return (
    <ValueUpdatedStyled>
      <Headings variant="primaryHeading2" fontSize={40} lineHeight={40} className="heading">
        Your {valueName} has been {actionType}
      </Headings>
      {width < 786 && <hr className="horizontalDivider" />}
      {successMessage && (
        <Paragraph variant="secondaryParagraph1" mobileFontSize={16} mobileFontWeight={500} className="text">
          {successMessage}
        </Paragraph>
      )}
      <StackedButtonsContainer direction={width < 786 ? "col" : "row"} className="btnWrapper" mobileDirection="col-reverse">
        {buttons}
      </StackedButtonsContainer>
    </ValueUpdatedStyled>
  );
};

export default ValueUpdated;
