import styled from "styled-components";

export const Error500Styled = styled.div`
  background-color: ${(props) => props.theme.color.primary.coleslawWhite};
  min-height: 448px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  .errorTitle {
    text-align: center;
  }
  .markdown {
    width: min(90%, 590px);
    text-align: center;
    margin: 0 auto;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: ${(props) => props.theme.font.barlow};

    a {
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 16px;
      font-weight: 700;
      text-decoration: underline;
      line-height: 19px;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    min-height: 478px;
    padding: 0 44px;
    gap: 20px;

    .markdown {
      width: auto;
      font-size: 12px;
      line-height: 14px;

      a {
        line-height: 14px;
        font-size: 12px;
      }
    }
  }
`;
