import { useDispatch, useSelector } from "react-redux";
import useModal from "hooks/useModal";
import { useVerify } from "hooks/auth/signup/useVerify";
import Modal from "components/global/Modal/Modal";
import EnterCode from "components/global/EnterCode/EnterCode";
import { setPresentUserStatus } from "redux/reducer/UserStatus/UserStatusAction";
import { useMParticle } from "hooks/useMParticle";
import { setSessionStorageData } from "utils/storage";
import { RootState } from "src/types/types";
import SignInSignUpCard from "components/Auth/SignInSignUpCard/SignInSignUpCard";
import { VerifyStyled } from "./VerifyStyled";

const Verify = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useModal();
  const { destination, handleResend, handleSubmit } = useVerify();
  const { mParticleLoginIdentity } = useMParticle();

  const { signupDetails } = useSelector((state: RootState) => ({
    signupDetails: state.signup,
  }));

  const handleGoToAccountCreated = () => setModal("ACCOUNT_CREATED");
  const handleCloseModal = () => setModal("");

  const mparticleLoginCallback = () => {
    const userIdentities = {
      email: signupDetails?.username,
    };

    mParticleLoginIdentity(userIdentities);
  };

  const handleSubmitExtended = async (code: string) => {
    return await handleSubmit(code, mparticleLoginCallback).then((res) => {
      if (res.success && res.response.authenticationResult) {
        setSessionStorageData("tokens", JSON.stringify(res.response.authenticationResult));
        dispatch(setPresentUserStatus("FIRST_TIME")); // delete after complete integration
        handleGoToAccountCreated();
      }
      return res;
    });
  };
  const BODY = <EnterCode codeLength={6} target={destination} onResend={handleResend} onSubmit={handleSubmitExtended} />;

  return (
    <VerifyStyled>
      <Modal isOpen={modal === "VERIFY"} overlayClassName="signupFormOverlay" mobileVariant="fullScren">
        <SignInSignUpCard
          className="verify-modal"
          body={BODY}
          onClose={handleCloseModal}
          isCloseRequired={true}
          isCloseRequiredInMobile={true}
          isBackVisible={true}
          onBack={() => setModal("")}
        />
      </Modal>
    </VerifyStyled>
  );
};

export default Verify;
