import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_FOOTER } from "api/endpoints";
import { FranchiseFooterCMSData } from "types/cms/footer";

export const getFranchiseFooterCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_FOOTER,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseFooterCMSData>>;
};
