import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_PROFILE } from "api/endpoints";
import { FranchiseProfileCMSData } from "types/cms/franchise-profile";

export const getFranchiseProfileCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_PROFILE,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseProfileCMSData>>;
};
