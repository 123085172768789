import { useDispatch } from "react-redux";
import { apiCall } from "src/apis/api";
import { BASKET, STORES, USERS } from "api/endpoints";
import { setFavouriteStoreList, setStoresCalendar } from "redux/reducer/Store/actions";
import { useIdToken } from "hooks/selectors/useIdToken";

export const useStores = () => {
  const dispatch = useDispatch();
  const token = useIdToken();

  const getStoresCalendars = async (storeId: string, from: string, to: string) => {
    const apiDetails = {
      requestType: "GET",
      apiEndPoint: `${STORES}/${storeId}/calendars?from=${from}&to=${to}`,
    };
    const storesCalendarResponse = await apiCall(apiDetails);

    if (storesCalendarResponse.success) {
      dispatch(setStoresCalendar(storesCalendarResponse.response));
    }
    return storesCalendarResponse;
  };

  const getFavouriteStore = async () => {
    const request = {
      apiEndPoint: `${USERS}/stores`,
      requestType: "GET",
      isAuth: true,
      token,
    };
    const response = await apiCall(request);
    if (response) {
      dispatch(setFavouriteStoreList(response.success ? response.response : []));
    }
    return response;
  };

  const getStoreETADetails = async (storeId: number) => {
    const apiDetails = {
      requestType: "GET",
      apiEndPoint: `${STORES}/${storeId}/eta`,
    };
    return apiCall(apiDetails);
  };

  const checkoutStoreETADetails = (basketId: string) => {
    const apiDetails = {
      apiEndPoint: `${BASKET}/${basketId}/checkout`,
      requestType: "POST",
      apiPostData: {},
      token,
    };
    return apiCall(apiDetails).then((data: any) => {
      return data;
    });
  };

  return {
    getStoresCalendars,
    getFavouriteStore,
    getStoreETADetails,
    checkoutStoreETADetails,
  };
};
