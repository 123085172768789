import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_CHECKOUT_PAGE_DATA } from "api/endpoints";

export const getCMSDataForCheckout = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_CHECKOUT_PAGE_DATA,
  }) as Promise<CommonLayerResponseDataWithType<any>>;
};
