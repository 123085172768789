import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { apiCall } from "src/apis/api";
import { ORDER_DETAILS } from "api/endpoints";
import { RootState, RecentOrderResponse } from "src/types/types";
import {
  showCurbsideReadyButtonFlag,
  curbsideOrderReadyForDeliveryFlag,
  setValidCurbsideOrders,
} from "redux/reducer/PlaceOrder/PlaceOrderActions";
import { defaultOffsetTime } from "constants/constant";
import { getValidCurbsideOrders, showCurbsideOrderReadyForDelivery } from "../helper";

dayjs.extend(utc);

export const useCurbsideOrder = () => {
  const dispatch = useDispatch();
  const {
    tokens,
    isCurbSideOrderReadyForDelivery,
    showCurbsideReadyButton,
    storeDetail,
    recentOrdersList,
    validCurbsideOrdersList,
    isAuthenticated,
  } = useSelector((state: RootState) => ({
    tokens: state.user.tokens,
    isCurbSideOrderReadyForDelivery: state?.placeOrder?.isCurbSideOrderReadyForDelivery,
    showCurbsideReadyButton: state?.placeOrder?.showCurbsideReadyButton,
    storeDetail: state.store?.storeDetail,
    recentOrdersList: state.placeOrder?.recentOrdersList,
    validCurbsideOrdersList: state.placeOrder?.validCurbsideOrdersList,
    isAuthenticated: state.user.isAuthenticated,
  }));
  const orderPlacedIntervalId = useRef(0);
  const readyOrderIntervalId = useRef(0);

  const offset = storeDetail?.utcOffset || defaultOffsetTime;

  const updateValidCurbsideOrders = (ordersList: Array<RecentOrderResponse>) => {
    const validCurbSideOrders = getValidCurbsideOrders(ordersList, offset);

    if (validCurbSideOrders?.length > 0) {
      dispatch(setValidCurbsideOrders(validCurbSideOrders));
      dispatch(curbsideOrderReadyForDeliveryFlag(true));
      // show banner if the order is yet to be delivered
      const { isOrderPlaced, showReadyButton } = showCurbsideOrderReadyForDelivery(validCurbSideOrders, Number(offset));
      if (isOrderPlaced && showReadyButton) {
        dispatch(showCurbsideReadyButtonFlag(true));
      }
    } else {
      dispatch(setValidCurbsideOrders([]));
      dispatch(curbsideOrderReadyForDeliveryFlag(false));
      dispatch(showCurbsideReadyButtonFlag(false));
    }
  };

  const fetchRecentsOrders = async () => {
    await apiCall({
      requestType: "POST",
      apiEndPoint: `${ORDER_DETAILS}recents`,
      apiPostData: {
        accessToken: tokens?.accessToken,
      },
    }).then((response) => {
      if (response.success) {
        updateValidCurbsideOrders(response.response);
      }
    });
  };

  const fetchRecentsOrdersForGuestUser = async () => {
    const validOrderIds = recentOrdersList.map((list: RecentOrderResponse) => list.orderId);
    await apiCall({
      requestType: "POST",
      apiEndPoint: `${ORDER_DETAILS}order-status`,
      apiPostData: {
        orderIds: [...validOrderIds],
      },
    }).then((response) => {
      if (response.success) {
        updateValidCurbsideOrders(response.response);
      }
    });
  };

  const resetClearInterval = () => {
    dispatch(curbsideOrderReadyForDeliveryFlag(false));
    dispatch(showCurbsideReadyButtonFlag(false));
    dispatch(setValidCurbsideOrders([]));
  };

  useEffect(() => {
    const timeInterval = 60000;

    if (isCurbSideOrderReadyForDelivery && !showCurbsideReadyButton && validCurbsideOrdersList.length > 0) {
      if (orderPlacedIntervalId.current) {
        clearInterval(orderPlacedIntervalId.current);
      }

      orderPlacedIntervalId.current = window.setInterval(async () => {
        const { isOrderPlaced, showReadyButton } = showCurbsideOrderReadyForDelivery(validCurbsideOrdersList, offset);

        if (isOrderPlaced && showReadyButton) {
          if (isAuthenticated) {
            await fetchRecentsOrders();
          } else {
            await fetchRecentsOrdersForGuestUser();
          }
          dispatch(showCurbsideReadyButtonFlag(true));
          clearInterval(orderPlacedIntervalId.current);
        } else if (!isOrderPlaced && !showReadyButton) {
          resetClearInterval();
          clearInterval(orderPlacedIntervalId.current);
        }
      }, timeInterval);
    }
  }, [isCurbSideOrderReadyForDelivery, showCurbsideReadyButton, tokens?.accessToken, validCurbsideOrdersList]);

  useEffect(() => {
    const timeInterval = 60000;

    if (isCurbSideOrderReadyForDelivery && showCurbsideReadyButton && validCurbsideOrdersList.length > 0) {
      if (orderPlacedIntervalId.current) {
        clearInterval(orderPlacedIntervalId.current);
      }

      if (readyOrderIntervalId.current) {
        clearInterval(readyOrderIntervalId.current);
      }

      readyOrderIntervalId.current = window.setInterval(async () => {
        const { isOrderPlaced, showReadyButton } = showCurbsideOrderReadyForDelivery(validCurbsideOrdersList, offset);

        if (isOrderPlaced && showReadyButton) {
          if (isAuthenticated) {
            await fetchRecentsOrders();
          } else {
            await fetchRecentsOrdersForGuestUser();
          }
        } else if (!isOrderPlaced && !showReadyButton) {
          resetClearInterval();
          clearInterval(readyOrderIntervalId.current);
        }
      }, timeInterval);
    }
  }, [isCurbSideOrderReadyForDelivery, showCurbsideReadyButton, tokens?.accessToken, validCurbsideOrdersList]);

  useEffect(() => {
    if (validCurbsideOrdersList?.length === 0) {
      resetClearInterval();
      clearInterval(orderPlacedIntervalId.current);
      clearInterval(readyOrderIntervalId.current);
    }
  }, [validCurbsideOrdersList?.length]);

  useEffect(() => {
    if (recentOrdersList?.length > 0) {
      updateValidCurbsideOrders(recentOrdersList);
    }
  }, [recentOrdersList]);
};
