import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_ARTICLES } from "api/endpoints";
import { ArticlesCMSData } from "types/cms/articles";

export const getArticlesCMSData = (pageNumber?: number, itemsPerPage?: number) => {
  const requestParmeters = {
    ...(itemsPerPage && { _limit: itemsPerPage.toString() }),
    ...(pageNumber && {
      _start: (pageNumber * (itemsPerPage ? itemsPerPage - 1 : 0)).toString(),
    }),
  };

  const urlSearchParameters = new URLSearchParams(requestParmeters);

  return apiCall({
    requestType: "GET",
    apiEndPoint: `${CMS_ARTICLES}?${urlSearchParameters}`,
  }) as Promise<CommonLayerResponseDataWithType<ArticlesCMSData[]>>;
};

export const getArticleByUrl = (url: string) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${CMS_ARTICLES}/${url}`,
  }) as Promise<CommonLayerResponseDataWithType<{ data: [{ id: number; attributes: ArticlesCMSData }] }>>; // Changed the response type based on the Strapi v4 Response format
};
