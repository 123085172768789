import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import mParticle from "@mparticle/web-sdk";
import { computeStraightLineDistanceInMiles } from "utils/compute";
import { apiCall } from "src/apis/api";
import { useBasket } from "hooks/basket/useBasket";
import { useStores } from "hooks/stores/useStores";
import { ANALYTICS_CONST } from "constants/analytics";
import {
  setCorporateStoreId,
  setDeliveryLocationName,
  setIsStoreAvailable,
  setPickupOrderType,
  setSelectedStore,
  setSelectedStoreName,
  getStoreDetails,
} from "redux/reducer/Store/actions";
import OrderPageContainer from "containers/Order/OrderPageContainer/OrderPageContainer";
import { DEFAULT_PRODUCT_URL, DELIVERY } from "constants/constant";
import { AddressSelectedStyled } from "containers/Order/AddressSelected/AddressSelectedStyled";
import { getAddressList, setFavoriteAddress, setUserEnteredDeliveryAddress } from "redux/reducer/Address/AddressAction";
import { setLocationOrderModal, setLocationOrderType, setLocationStep } from "redux/reducer/Location/LocationAction";
import {
  getStoreMenu,
  setSelectedTabName,
  setUnselectedGroups,
  setUnselectedGroupsDetails,
  resetCustomizationData,
  setFromHomeReorderSection,
} from "redux/reducer/Menu/MenuAction";
import { setErrorMessageToModal, setErrorMessageToModalFlag, setPresentUserOrderType } from "redux/reducer/UserStatus/UserStatusAction";
import { escapeCategoryName, navigateToMenuPage } from "utils/helper";
import { MENU, ORDER_DETAILS } from "api/endpoints";
import { urlTrim } from "utils/cleaning";
import Analytics from "analytics/Analytics";
import { createProductForMparticle, getPrice } from "utils/mparticle/mparticleHelper";
import useReorder from "hooks/useReorder";
import { getLocalStorageData, setLocalStorageData, setSessionStorageData, setDataToCookie } from "utils/storage";
import { CommonLayerResponseData, DeliveryETADetails, Menu, Product } from "src/types/types";
import { MENU_URL_REGEX } from "constants/regexPatterns";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Button from "components/atomic-components/atoms/button/Button";
import StackedButtonsContainer from "../../../global/StackedButtonsContainer/StackedButtonsContainer";
import AddressDeliveryCard from "../AddressDeliveryCard/AddressDeliveryCard";
import { DeliveryCardSelectedAddressStyled } from "./DeliveryCardSelectedAddressStyled";

interface Props {
  className?: string;
}

const DeliveryCardSelectedAddress = ({ className }: Props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const reorder = useReorder();
  const routerUrl = getLocalStorageData("previousURL") ?? "";
  const { createBasketId, transferBasketToAnotherStore } = useBasket();
  const { getStoreETADetails } = useStores();
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [transferBasketActionCalled, setTransferBasketActionCalled] = useState(false);
  const [deliveryETA, setDeliveryETA] = useState<string>("");
  const [callMparticleEvent, setCallMparticleEvent] = useState(false);

  const {
    userAddress,
    favouriteAddress,
    itemsNotTransferred,
    basket,
    details,
    tokens,
    deliveryAvailableStores,
    fromCheckout,
    selectedTabName,
    famousMealFlag,
    previousRouteURL,
    selectedLocationProducts,
    fromHomeReorderSection,
    isAuthenticated,
    recentOrdersList,
  } = useSelector((state: any) => ({
    userAddress: state.address?.userAddress,
    favouriteAddress: state.address?.favouriteAddress,
    itemsNotTransferred: state.basket?.itemsNotTransferred,
    basket: state.basket?.basket,
    details: state.user.details,
    tokens: state.user.tokens,
    deliveryAvailableStores: state.store?.deliveryAvailableStores,
    fromCheckout: state.userStatus?.fromCheckout,
    selectedTabName: state.menu?.selectedTabName,
    famousMealFlag: state.store?.famousMealFlag,
    previousRouteURL: state.menu?.previousRouteURL,
    selectedLocationProducts: state?.menu?.selectedLocationProducts,
    fromHomeReorderSection: state.menu?.fromHomeReorderSection,
    isAuthenticated: state.user.isAuthenticated,
    recentOrdersList: state?.placeOrder?.recentOrdersList,
  }));

  useEffect(() => {
    // itemsNotTransferred is a redux value and it will be having value even when this component unmounts
    // when user open this pop up from diffrent pages the remove action will be triggered as itemsTrasferred is having value
    // we don't need to trigger this event each time this component is mounted.
    // so maintaing a local state which will be set to true on successful basket transfer
    if (itemsNotTransferred?.length > 0 && callMparticleEvent) {
      const mparticleProductList: mParticle.Product[] = itemsNotTransferred?.map((product: any) =>
        createProductForMparticle(product?.title, product?.productId, getPrice(product), product?.quantity, {
          currency: "USD",
          store_id: selectedStoreId,
          modifiers: JSON.stringify(product?.choices),
        })
      );
      // remove from cart event in mParticle and Amplitude
      mParticle.eCommerce.logProductAction(mParticle.ProductActionType.RemoveFromCart, mparticleProductList);
      Analytics.getInstance().logEvent(
        ANALYTICS_CONST.ORDER.REMOVE_ITEM_FROM_CART,
        {
          ecommerce: {
            ...itemsNotTransferred,
            store_id: selectedStoreId,
          },
        },
        false
      );
    }
  }, [itemsNotTransferred?.length]);

  useEffect(() => {
    if (tokens?.idToken && details?.externalId) {
      dispatch(
        getAddressList({
          idToken: tokens?.idToken,
          externalId: details?.externalId,
        })
      );
    }

    if (!deliveryAvailableStores || !userAddress?.latitude || !userAddress?.longitude) {
      return;
    }

    const store = deliveryAvailableStores[0];
    const etaRange = store?.eta?.split("-");

    if (!store || !etaRange || !store?.latitude || !store?.longitude) {
      return;
    }

    const x = {
      lat: parseFloat(userAddress?.latitude),
      lng: parseFloat(userAddress?.longitude),
    };

    const y = {
      lat: parseFloat(store?.latitude),
      lng: parseFloat(store?.longitude),
    };

    const miles = computeStraightLineDistanceInMiles(x, y);
    const driveTime = 2 * Math.ceil(miles); // we assume 2 minutes of travel time per mile
    if (etaRange.length === 2) {
      const minPrepTime = parseInt(etaRange[0].match(/\d+/)[0]) || 0;
      const maxPrepTime = parseInt(etaRange[1].match(/\d+/)[0]) || 0;

      const minEta = minPrepTime + driveTime;
      const maxEta = maxPrepTime + driveTime;

      setDeliveryETA(`${minEta} - ${maxEta} MIN`);
    } else {
      const time = (parseInt(etaRange[0].match(/\d+/)[0]) || 0) + driveTime;
      setDeliveryETA(`${time} MIN`);
    }
  }, [favouriteAddress]);

  const updateSelectedStoreDetails = (selectedStore: any) => {
    dispatch(
      setSelectedStoreName({
        deliveryType: "Delivery",
        storeAddress: userAddress.addressLineOne ? userAddress.addressLineOne : selectedStore.address,
      })
    );
    dispatch(setSelectedStore(selectedStore.storeId));
  };

  useEffect(() => {
    if (!deliveryAvailableStores[0].currentlyOpen) {
      const selectedStore = navigateToMenuPage(userAddress, deliveryAvailableStores, "", "Delivery", 0);

      // Update The store details manually as removed from the navigateToMenuPage function
      // Commented the below code as updating in the API response is successful
      // updateSelectedStoreDetails(selectedStore);

      getStoreETADetails(selectedStore.storeId).then((res: CommonLayerResponseData) => {
        if (res.success) {
          const deliveryStoreETADetails = res.response.find((ele: DeliveryETADetails) => ele.type === "dispatch");

          if (deliveryStoreETADetails) {
            if (!deliveryAvailableStores || !userAddress?.latitude || !userAddress?.longitude) {
              return;
            }

            const store = deliveryAvailableStores[0];

            if (!store || !deliveryStoreETADetails.eta || !store?.latitude || !store?.longitude) {
              return;
            }

            const x = {
              lat: parseInt(userAddress?.latitude),
              lng: parseInt(userAddress?.longitude),
            };

            const y = {
              lat: parseInt(store?.latitude),
              lng: parseInt(store?.longitude),
            };

            const miles = computeStraightLineDistanceInMiles(x, y);

            const driveTime = 2 * Math.ceil(miles); // we assume 2 minutes of travel time per mile
            const etaTime = dayjs(deliveryStoreETADetails.eta).add(driveTime, "minute").format("MMM DD @ hh:mm A");

            setDeliveryETA(etaTime);
          }
        }
      });
    }
  }, [deliveryAvailableStores]);

  useEffect(() => {
    if (fromCheckout && transferBasketActionCalled) {
      if (basket && basket.products.length > 0 && itemsNotTransferred.length === 0) {
        router.push("/checkout");
      } else {
        if (basket && basket.products.length > 0 && itemsNotTransferred.length !== 0) {
          router.push("/cart");
        }
        if (itemsNotTransferred.length > basket.products.length) {
          router.push(
            {
              pathname: "/menu",
              query: { storeId: selectedStoreId },
            },
            "/menu"
          );
        }
        if (itemsNotTransferred.length === basket.products.length) {
          router.push(
            {
              pathname: "/menu",
              query: { storeId: selectedStoreId },
            },
            "/menu"
          );
        }
      }
    }
  }, [itemsNotTransferred, fromCheckout, transferBasketActionCalled]);

  const callTransferBasketAPIForDelivery = async (callback: any) => {
    dispatch(setSelectedTabName(selectedTabName || "Menu"));
    const selectedStore = navigateToMenuPage(userAddress, deliveryAvailableStores, "", "Delivery", 0);
    let basketId = "";
    if (!basket?.basketId) {
      await createBasketId({
        storeId: selectedStore.storeId,
        accessToken: tokens?.accessToken || "",
        handoffMode: "delivery",
        address: userAddress,
      }).then((data: CommonLayerResponseData) => {
        basketId = data?.response?.basketId;
      });
    }

    if (basket?.basketId || basketId) {
      await transferBasketToAnotherStore({
        storeId: selectedStore.storeId,
        basketId: basket?.basketId || basketId,
        handoffMode: "delivery",
        address: userAddress,
      }).then((res: CommonLayerResponseData) => {
        if (res.success === false) {
          dispatch(setErrorMessageToModal(res.error?.apiError));
          dispatch(setErrorMessageToModalFlag(true));
        } else if (res.success) {
          // Update the basketId in the cookie storage
          setDataToCookie("basketId", res.response.basket?.basketId);
          setCallMparticleEvent(true);
          // Show Reward Not Applicable Modal
          if (res.response.basket?.basketValidationMessages?.length > 0) {
            setSessionStorageData("REWARD_NOT_APPLICABLE", "true");
            dispatch(setErrorMessageToModal(res?.response?.basket?.basketValidationMessages[0]));
          }

          // Remove the unSelectedGroups from the previous store
          dispatch(resetCustomizationData());
          dispatch(setUnselectedGroups([]));
          dispatch(setUnselectedGroupsDetails([]));

          callback(selectedStore);
        }
      });
    }
  };

  const onDeliverSuccessStateUpdate = async (selectedStore: any) => {
    let productAvailable: any;
    let recentOrdersRefreshed;
    dispatch(setIsStoreAvailable(true));
    // Commenting the below as it's not required to clear instead need to update the menu details
    // dispatch(resetStoreMenu());
    // Updating the Store Menu Details
    dispatch(getStoreDetails(selectedStore.storeId));
    dispatch(getStoreMenu(selectedStore.storeId));
    dispatch(setPickupOrderType(null));
    dispatch(setPresentUserOrderType("DELIVERY"));
    dispatch(setCorporateStoreId(selectedStore.corporateStoreId));
    setSelectedStoreId(selectedStore.storeId);
    // Update The store details manually as removed from the navigateToMenuPage function only if the API response is successful
    updateSelectedStoreDetails(selectedStore);

    if (previousRouteURL && selectedLocationProducts.productId) {
      await apiCall({
        requestType: "GET",
        apiEndPoint: `${MENU}/${selectedStore?.storeId}?includePrimaryModifiers=true`,
      }).then(({ response }: any) => {
        productAvailable = response
          ?.find((category: Menu) => escapeCategoryName(category?.name) === selectedLocationProducts?.categoryName)
          ?.products?.find(
            (product: Product) => product?.title?.toLocaleLowerCase() === selectedLocationProducts?.productName?.toLocaleLowerCase()
          );
      });
    }

    // Refresh the recent orders if the flow is from home and no store was previously selected
    if (fromHomeReorderSection?.isProduct) {
      await apiCall({
        requestType: "POST",
        apiEndPoint: `${ORDER_DETAILS}recents?basedOnStore=true`,
        apiPostData: {
          accessToken: tokens?.accessToken,
          storeId: selectedStore?.storeId,
        },
      }).then((response) => {
        recentOrdersRefreshed = response.response;
      });
    }

    // As the Same code is duplicated in the condition so removed & re-used below
    const orderData = {
      presentUserOrderType: DELIVERY,
      pickupOrderType: null,
      selectedStoreId: selectedStore.storeId,
      selectedStoreDetail: {
        deliveryType: DELIVERY,
        storeAddress: selectedStore.address,
      },
      userAddress: userAddress,
    };
    setLocalStorageData("store", JSON.stringify(orderData));

    if (fromCheckout) {
      setTransferBasketActionCalled(true);
    } else if (fromHomeReorderSection) {
      const product = (recentOrdersRefreshed || recentOrdersList)
        .find(({ products }: { products: { productId: string }[] }) =>
          products.find(({ productId }) => productId === fromHomeReorderSection.id)
        )
        ?.products.find(({ productId }: { productId: string }) => productId === fromHomeReorderSection.id);
      if (fromHomeReorderSection.isProduct && product) {
        dispatch(setFromHomeReorderSection());
        return router.push(
          {
            pathname: "/menu/[categoryName]/[productTitle]",
            query: { storeId: selectedStoreId, productId: fromHomeReorderSection.id },
          },
          `/menu/${urlTrim(product?.category)}/${urlTrim(product?.name as string)}`
        );
      } else if (fromHomeReorderSection.isProduct && !product) {
        dispatch(setFromHomeReorderSection({ id: fromHomeReorderSection.id, notFound: true }));
        return router.push("/");
      } else {
        reorder(fromHomeReorderSection.id, "delivery", selectedStore?.storeId, true);
        dispatch(setFromHomeReorderSection());
      }
      router.push("/cart");
      dispatch(setFromHomeReorderSection());
    } else {
      if (productAvailable?.title) {
        router.push(
          {
            pathname: "/menu/[categoryName]/[productTitle]",
            query: { storeId: selectedStore, productId: productAvailable?.productId },
          },
          `/menu/${selectedLocationProducts?.categoryName}/${urlTrim(productAvailable?.title as string)}`
        );
      } else if (MENU_URL_REGEX.test(routerUrl?.split("?")?.[0])) {
        router.push(
          {
            pathname: "/menu/[categoryName]/[productTitle]",
            query: { productId: undefined },
          },
          routerUrl
        );
      } else if (famousMealFlag) {
        router.push("/");
      } else if (routerUrl?.split("?")?.[0] === DEFAULT_PRODUCT_URL) {
        router.push(
          {
            pathname: "/menu-category/products",
            query: { storeId: selectedStore, previousRoute: router?.asPath },
          },
          `/menu-category/products`
        );
      } else {
        router.push(
          {
            pathname: "/menu",
            query: { storeId: selectedStore.storeId, previousRoute: router?.asPath },
          },
          "/menu"
        );
      }
    }
  };

  const onSaveAndDeliverHere = () => {
    // Open the Confirm Address Modal if the transfer API is success
    callTransferBasketAPIForDelivery(() => dispatch(setLocationOrderModal("CONFIRM_ADDRESS")));
  };

  const onDeliverBtnClick = () => {
    callTransferBasketAPIForDelivery(onDeliverSuccessStateUpdate);
  };

  const handleUpdateFavouriteAddress = async (favourite: boolean, addressId?: number) => {
    if (tokens && details && addressId) {
      const payload = {
        addressId,
        favorite: favourite,
        idToken: tokens?.idToken,
        externalId: details?.externalId,
      };

      dispatch(setFavoriteAddress(payload));
    }
  };
  const Footer = (
    <>
      <Headings variant="headingMedium" fontSize={20} fontWeight={700} className="deliveryText">
        DELIVERY
      </Headings>
      <Headings variant="headingMedium" fontSize={20} fontWeight={700} className="etaText">
        ETA {deliveryETA}
      </Headings>
      <StackedButtonsContainer direction="col" className="btnWrapper">
        <Button className="ctaButtons" variant="primaryRed" onClick={onDeliverBtnClick} disabled={userAddress?.zip?.length < 5}>
          DELIVER HERE
        </Button>
        {isAuthenticated && (
          <Button className="ctaButtons" variant="modalOutlineButton" onClick={onSaveAndDeliverHere}>
            SAVE AND DELIVER HERE
          </Button>
        )}
      </StackedButtonsContainer>
    </>
  );
  const onBack = () => {
    dispatch(setDeliveryLocationName(""));
    dispatch(setUserEnteredDeliveryAddress({}));
    dispatch(setLocationOrderType("DELIVERY"));
    dispatch(setLocationStep("LOGGED_IN_SEARCH"));
  };

  return (
    <DeliveryCardSelectedAddressStyled className="deliveryCardSelectedAddress">
      <OrderPageContainer mobileLayout="REBRAND_SCREEN">
        <AddressSelectedStyled>
          <AddressDeliveryCard
            onBack={onBack}
            className={className}
            Footer={Footer}
            storeData={userAddress}
            isUserAddress
            onUpdateFavouriteAddress={handleUpdateFavouriteAddress}
          />
        </AddressSelectedStyled>
      </OrderPageContainer>
    </DeliveryCardSelectedAddressStyled>
  );
};

export default DeliveryCardSelectedAddress;
