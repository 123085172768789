export const IS_SHOW_TOGGLE_MODAL = "IS_SHOW_TOGGLE_MODAL";
export const SET_TOGGLE_MENUS = "SET_TOGGLE_MENUS";
export const SET_IS_FORM_VALID = "SET_IS_FORM_VALID";
export const SET_IS_APPLEPAY_PAYMENT_SUPPORTED = "SET_IS_APPLEPAY_PAYMENT_SUPPORTED";
export const SAVE_SELECTED_SPOTLIGHT_ITEM_INDEX = "SAVE_SELECTED_SPOTLIGHT_ITEM_INDEX";
export const SET_ADDED_NOTIFICATION_OPEN = "SET_ADDED_NOTIFICATION_OPEN";
export const SET_ADDED_NOTIFICATION_CLOSE = "SET_ADDED_NOTIFICATION_CLOSE";
export const SET_CLOSE_RIBBON_BANNER = "SET_CLOSE_RIBBON_BANNER";
export const SET_IS_SHOW_RIBBON_BANNER = "SET_IS_SHOW_RIBBON_BANNER";
export const SET_USER_CURRENT_LOCATION = "SET_USER_CURRENT_LOCATION";
export const SET_IS_LOADING = "SET_IS_LOADING";
