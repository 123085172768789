import secureLocalStorage from "react-secure-storage";
import { isMobile } from "src/api/utils";
import { setCookieData } from "src/utils/storage";
import {
  BasketBeginLoadingAction,
  BasketCloseLoadingAction,
  BasketErrorAction,
  BasketErrorPayload,
  CreateBasketAction,
  CreateFavBasketAction,
  CreateFavBasketErrorAction,
  CreateFavBasketErrorPayload,
  CreateFavBasketPayload,
  DeleteUserFavoriteBasketAction,
  DeleteUserFavoriteBasketPayload,
  ItemsNotAvailableForHandoffAction,
  ItemsNotAvailableForHandoffPayload,
  ItemsNotTransferedAction,
  ItemsNotTransferedPayload,
  removeUpsellItemsPayload,
  removeUsellItemsAction,
  ResetBasketAction,
  SaveFavBasketAction,
  SaveFavBasketPayload,
  SelectedFavIdAction,
  SelectedFavIdPayload,
  SetBasketDetailsAction,
  SetBasketDetailsPayload,
  SetBasketStoreIdAction,
  SetBasketStoreIdPayload,
  SetFaveConfirmationFlagAction,
  SetFaveConfirmationFlagPayload,
  SetFaveDisableFlagAction,
  SetFaveDisableFlagPayload,
  SetFaveModalAction,
  SetFaveModalPayload,
  SetFavoriteBasketAction,
  SetFavoriteBasketPayload,
  SetFlagOnProductLengthAction,
  SetFlagOnProductLengthPayload,
  SetHandOffModeErrorAction,
  SetHandOffModeErrorPayload,
  SetIsCheckOutScreenAction,
  SetIsCheckOutScreenPayload,
  SetLocalDriverNoteAction,
  SetLocalDriverNotePayload,
  SetOrderPlaceErrorPayload,
  SetProductTimerAction,
  SetProductTimerPayload,
  SetSubmitOrderStatusAction,
  SetSubmitOrderStatusPayload,
  SetTabBarHeightAction,
  SetTabBarHeightPayload,
  SetTimeAction,
  SetUpsellAction,
  SetUpsellPayload,
  SetUserFavBasketNameAction,
  SetUserFavBasketNamePayload,
  SetValidateBasketFlagAction,
  SetValidateBasketFlagPayload,
  ValidateBasketAction,
  ValidateBasketPayload,
} from "./types";

export const basketBeginLoading = (): BasketBeginLoadingAction => ({
  type: "BASKET_BEGIN_LOADING",
});

export const basketCloseLoading = (): BasketCloseLoadingAction => ({
  type: "BASKET_CLOSE_LOADING",
});

export const basketError = (payload: BasketErrorPayload): BasketErrorAction => ({
  type: "BASKET_SYSTEM_ERROR",
  payload,
});

export const setBasketDetails = (payload: SetBasketDetailsPayload): SetBasketDetailsAction => ({
  type: "SET_BASKET_DETAILS",
  payload,
});

export const setTabBarHeight = (payload: SetTabBarHeightPayload): SetTabBarHeightAction => ({
  type: "SET_TAB_BAR_HEIGHT",
  payload: payload,
});

export const setUpSellItem = (payload: SetUpsellPayload): SetUpsellAction => ({
  type: "SET_UP_SELL_LIST",
  payload,
});

export const removeUpSellItem = (payload: removeUpsellItemsPayload): removeUsellItemsAction => ({
  type: "REMOVE_UP_SELL_LIST",
  payload,
});

export const setHandOffModeError = (payload: SetHandOffModeErrorPayload): SetHandOffModeErrorAction => ({
  type: "HAND_OFF_MODE_ERROR",
  payload,
});

export const setOrderPlaceError = (payload: SetOrderPlaceErrorPayload) => ({
  type: "ORDER_PLACE_ERROR",
  payload,
});

export const setIsCheckOutScreen = (payload: SetIsCheckOutScreenPayload): SetIsCheckOutScreenAction => ({
  type: "SET_IS_CHECKOUT_SCREEN",
  payload,
});

export const setLocalDriverNote = (payload: SetLocalDriverNotePayload): SetLocalDriverNoteAction => ({
  type: "ADD_NOTES_FOR_DRIVER",
  payload,
});

export const setFavBasketList = (payload: SetFavoriteBasketPayload): SetFavoriteBasketAction => ({
  type: "REORDER_FAV_BASKET_LIST",
  payload,
});

export const saveFavBasket = (payload: SaveFavBasketPayload): SaveFavBasketAction => ({
  type: "REORDER_FAV_SET_BASKET",
  payload,
});

export const createBasket = (payload: SetBasketDetailsPayload): CreateBasketAction => {
  if (!isMobile()) {
    if ((secureLocalStorage.getItem("tokens") || sessionStorage.getItem("tokens")) && payload?.basketId) {
      setCookieData("basketId", payload?.basketId);
    }
  }
  return {
    type: "CREATE_BASKET",
    payload,
  };
};

export const submitOrderStatus = (payload: SetSubmitOrderStatusPayload): SetSubmitOrderStatusAction => ({
  type: "SUBMIT_ORDER_STATUS",
  payload,
});

export const resetBasket = (): ResetBasketAction => {
  return { type: "RESET_BASKET" };
};

export const setTime = (payload: number): SetTimeAction => {
  return { type: "SET_TIMER", payload: payload };
};

export const setProductTime = (payload: SetProductTimerPayload): SetProductTimerAction => {
  return { type: "SET_PRODUCT_TIMER", payload: payload };
};

export const setSelectedFavId = (payload: SelectedFavIdPayload): SelectedFavIdAction => {
  return { type: "SET_SELECTED_FAV_ID", payload: payload };
};

export const setBasketStoreId = (payload: SetBasketStoreIdPayload): SetBasketStoreIdAction => {
  return { type: "SET_BASKET_STORE_ID", payload: payload };
};

export const setUserFavBasketName = (payload: SetUserFavBasketNamePayload): SetUserFavBasketNameAction => {
  return { type: "SET_USER_FAV_BASKET_NAME", payload: payload };
};

export const setFaveConfirmationFlag = (payload: SetFaveConfirmationFlagPayload): SetFaveConfirmationFlagAction => {
  return { type: "FAVE_RECENT_CONFIRMATION_FLAG", payload: payload };
};

export const setFlagOnProductLength = (payload: SetFlagOnProductLengthPayload): SetFlagOnProductLengthAction => {
  return { type: "SET_FLAG_ON_PRODUCT_LENGTH", payload: payload };
};

export const setFaveDisableFlag = (payload: SetFaveDisableFlagPayload): SetFaveDisableFlagAction => {
  return { type: "SET_FAVE_DISABLE_FLAG", payload: payload };
};

export const setValidateBasketFlag = (payload: SetValidateBasketFlagPayload): SetValidateBasketFlagAction => {
  return { type: "SET_VALIDATE_BASKET_API_CALL_FLAG", payload: payload };
};

export const itemsNotTransferred = (payload: ItemsNotTransferedPayload): ItemsNotTransferedAction => {
  return { type: "ITEMS_NOT_TRANSFERRED", payload: payload };
};

export const itemsNotAvailableForHandoffMode = (payload: ItemsNotAvailableForHandoffPayload): ItemsNotAvailableForHandoffAction => {
  return { type: "ITEMS_NOT_AVAILABLE_FOR_HANDOFF", payload: payload };
};

export const createFavBasket = (payload: CreateFavBasketPayload): CreateFavBasketAction => {
  return { type: "CREATE_FAV_BASKET", payload: payload };
};

export const createFavBasketError = (payload: CreateFavBasketErrorPayload): CreateFavBasketErrorAction => {
  return { type: "CREATE_FAV_BASKET_ERROR", payload: payload };
};

export const deleteUserFavBasket = (payload: DeleteUserFavoriteBasketPayload): DeleteUserFavoriteBasketAction => {
  return { type: "DELETE_USER_FAV_BASKET", payload: payload };
};

export const setFaveModal = (payload: SetFaveModalPayload): SetFaveModalAction => {
  return { type: "SET_FAV_MODALS", payload: payload };
};

export const validateBasket = (payload: ValidateBasketPayload): ValidateBasketAction => {
  return { type: "VALIDATE_BASKET", payload: payload };
};
