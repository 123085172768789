import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_CORPORATE_OPPORTUNITIES } from "api/endpoints";
import { CorporateCMSData } from "types/cms/corporate";

export const getCorporateCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_CORPORATE_OPPORTUNITIES,
  }) as Promise<CommonLayerResponseDataWithType<CorporateCMSData>>;
};
