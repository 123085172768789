export const ANALYTICS_CONST = {
  AUTH: {
    CLICK_TOP_NAV_LOG_IN_BUTTON: "click_top_nav_log_in_button",
    CLICK_TOP_NAV_SIGN_UP_BUTTON: "click_top_nav_sign_up_button",
    CLICK_LOGOUT: "click_logout",
    SIGN_UP_STARTED: "click_sign_up_button",
    SIGN_UP_SUCCESS: "sign_up_success",
    SIGN_UP_FAILURE: "signup_failure",
    SIGN_IN_STARTED: "click_log_in_button",
    LOGIN_SUCCESS: "login_success",
    LOGIN_FAILURE: "login_failure",
    EMAIL_OPTIN_SELECTED: "select_email_optin",
    EMAIL_OPTIN_DESELECTED: "deselect_email_optin",
    TEXT_OPTIN_SELECTED: "select_text_optin",
    TEXT_OPTIN_DESELECTED: "deselect_text_optin",
    PROFILE_UPDATE_SUCCESS: "update_profile_success",
    PHONE_VERIFICATION_SUCCESS: "verify_phone_number_success",
    SIGN_UP_PASSWORD_ERROR: "sign_up_password_error",
    LOGIN_FORGOT_MY_PASSWORD: "login_forgot_my_password",
    CLICK_DELETE_ACCOUNT_PROFILE: "click_delete_account_profile",
    CLICK_CLOSE_CANCEL_DELETE_ACCOUNT: "click_close_cancel_delete_account",
    CLICK_DELETE_ACCOUNT_CONFIRM: "click_delete_account_confirm",
    MIGRATION_PHONE_VERIFICATION_ERROR: "migration_phone_verification_error",
    MIGRATION_PHONE_VERIFICATION_SUCCESS: "migration_phone_verification_success",
    MIGRATION_PASSWORD_ERROR: "migration_password_error",
    MIGRATION_PASSWORD_SUCCESS: "migration_password_success",
    MIGRATION_TERMS_CONDITIONS_SUCCESS: "migration_terms_conditions_success",
    SESSION_TIMEOUT: "session_time_out",
  },
  HOMEPAGE: {
    CLICK_BEHIND_THE_SCENES: "click_behind_the_scenes",
    CLICK_PLAY: "click_play",
    CLICK_PAUSE: "click_pause",
  },
  MENU: {
    ITEM_BROWSE: "browse_item",
    CLICK_SPOTLIGHT_ITEM: "click_spotlight_item",
  },
  CART: {
    CLICK_APPLY_REWARDS_CART: "click_apply_rewards_cart",
  },
  BASKET: {
    BASKET_FAILURE: "basket_failure",
  },

  ORDER: {
    CLICK_ORDER_NOW: "click_order_now",
    CLICK_PICK_UP: "click_pick_up",
    CLICK_DELIVERY: "click_delivery",
    ADD_TO_CART: "add_to_cart",
    REMOVE_ITEM_FROM_CART: "remove_item_from_cart",
    ADD_TO_CART_ERROR: "add_to_cart_error",
    CHECK_OUT_STARTED: "start_check_out",
    CHECK_VEHICLE_INFO_ERROR: "checkout_vehicle_info_error",
    CLICK_EDIT_LOCATION_CHECKOUT: "click_edit_location_checkout",
    ADD_UPSELL_ITEM: "add_upsell_item",
    GO_BIG: "click_go_big",
    PLACE_ORDER_SUCCESS: "place_order_success",
    CLICK_REORDER: "click_reorder",
    ITEM_ORDER_SUCCESS: "item_order_success",
    FIRST_ITEM_ORDER_SUCCESS: "first_item_order_success",
    RECENT_ORDERS: "click_recent_orders",
    CLICK_FAVES: "click_faves",
    START_ADD_PAYMENT_METHOD: "start_add_payment_method",
    ADD_PAYMENT_METHOD_SUCCESS: "add_payment_method_success",
    OUTOFDELIVERY_TRY_PICKUP: "click_out_of_delivery_try_pickup",
    CLICK_FAVE_IT: "click_fave_it",
    CLICK_IM_HERE: "click_Im_here",
    CLICK_SUBMIT_CHECK_IN: "click_submit_check_in",
    GOOGLE_PAY_FAILURE: "google_pay_failure",
    QUICK_CHECK_TRIGGERED: "quick_check_trigged",
    CLICK_QUICK_CHECK_YES_CONTINUE: "click_quick_check_yes_continue",
    CLICK_QUICK_CHECK_NO_CHANGE_LOCATION: "click_quick_check_no_change_location",
    APPLE_PAY_FAILURE: "apple_pay_failure",
    CREDIT_CARD_FAILURE: "credit_card_failure",
    GIFT_CARD_FAILURE: "gift_card_failure",
    UNCATEGORIZED_ORDER_FAILURE: "uncategorized_order_failure",
  },
  STORE: {
    STORE_DETAIL_CALL: "call_store_detail",
    CLICK_CURBSIDE: "click_curbside",
    CLICK_INSTORE: "click_instore",
    CLICK_DRIVETHROUGH: "click_drive_thru",
    SELECT_STORE: "select_store",
    USE_CURRENT_LOCATION: "use_current_location",
    SEARCH_STORE: "search_store",
    SELECT_A_STORE_POPUP: "select_a_store_popup",
  },
  DOWNLOAD: {
    VIEW_NUTRITION_TRIFOLD: "view_nutrition_trifold",
    VIEW_FULL_MENU: "view_full_menu",
    VIEW_CATERING_MENU: "view_full_menu",
    APPLE_APP_STORE: "apple_app_store",
    GOOGLE_APP_STORE: "google_app_store",
  },
  CAREERS: {
    CLICK_APPLY_NOW: "click_apply_now",
    CLICK_CARRERS_EMAIL: "click_CARRERS_Explore",
    SUBMIT_JOB_FORM: "submit_job_form",
  },
  REWARD: {
    RECEIPT_EARN_POINTS_SUCCESS: "receipt_earn_points_success",
    CLICK_EARN_POINTS_RECEIPT: "click_earn_points_receipt",
    CLICK_SCAN_TO_EARN: "click_scan_to_earn",
    CLICK_CHOOSE_REWARD: "click_choose_reward",
    CLICK_SELECT_REWARD: "click_select_reward",
    REWARD_APPLIED_SUCCESS: "reward_applied_success",
    CLICK_SHARE_REFERRAL_CODE: "click_share_referral_code",
    SELECT_REFERRAL_BY_FRIEND: "select_referral_by_friend",
    CLICK_REDEEM_INSTORE: "click_redeem_instore",
    CLICK_APPLY_REWARD: "click_apply_reward",
    CLICK_VIEW_HISTORY: "click_view_history",
  },
  LOCAL_STORE: {
    CALL_STORE_DETAIL: "call_store_detail",
    CLICK_DIRECTIONS: "click_directions",
    CLICK_EMAIL: "click_email",
    SUBMIT_CATERING_REQUEST: "submit_catering_request",
    SELECT_STORE: "select_store",
  },
};

export const ANALYTICS_SCREENS = {
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
  UNLOCK_LOGIN: "Unlock Login", //
  VERIFY_USER: "Verify User", //
  CREATE_A_FAV: "Create A Favourite", //
  FAV_CONFIRMATION: "Favourite Confirmation", //
  REWARDS: "Rewards",
  TERMS_AND_COND: "Terms & Conditions",
  CART: "Cart",
  READY_TO_CHECKOUT: "Ready to checkout",
  GIFT_CARDS: "Gift Cards",
  STORE_PICKUP_METHOD: "Store Pickup Method",
  FAQ_AND_SUPPORT: "FAQ & Support",
  GUEST_REWARDS: "Guest Rewards", //
  GUEST_HOME: "Guest Home", //
  FILTER_SEARCH: "FilterSearch",
  ADDRESS_BOOK: "AddressBook",
  STORE_DETAILS: "Store Details",
  STORE_SELECTION: "Store Selection",
  NEAR_BY_STORES: "Near By Stores", //
  ACCOUNT: "Account", //
  LTO_SPLASH: "LTO Splash",
  ITEM_CUSTOMISATION: "Item Customization", //
  REORDER: "Reorder",
  FEATURED_REWARDS: "Featured Rewards", //
  POINTS_HISTORY: "Points History", //
  REWARDS_CATEGORY: "Rewards Category", //
  SCAN_TO_EARN: "Scan To Earn", //
  ACTIVATE_YOUR_OFFER: "Activate Your Offer",
  LOCATION_DETAIL: "Location Detail",
  ORDER_CONFIRMATION: "Order Confirmation",
  SEND_CODE: "Send Code",
  FORGOT_PASSWORD: "Forgot Password",
  COMPLETE_SIGNUP: "Complete Signup",
  PROFILE_SCREEN: "Profile Screen",
  UPDATE_PHONE_NUMBER: "Update Your Phone Number",
  SELECT_PAYMENT_TYPE: "Select Payment Type",
  SELECT_CREDIT_CARD: "Select Credit Card",
  PAYMENTS: "Payments",
  MENU: "Menu",
  CREDIT_CARD: "Credit Card",
  FAVORITE_STORES: "Favorite Stores",
  CREATE_EDIT_LOCK: "Create Edit Lock", //
  CONTACT_US: "Contact Us", //
  COMMUNICATION_PREF: "Communication Prefrences",
  ADD_EXISTING_CARD: "Add Existing Card",
  ADD_NEW_CREDIT_CARD: "Add New Credit Card",
  ADD_EDIT_ADDRESS: "Add And Edit Address",
  DELETE_CARD: "Delete Card", //
  HOME_SCREEN: "Home Screen",
  VEHICLE_INFO: "Vehicle Info",
};

export const PAGE_NAME = {
  CART: "cart",
  CHECKOUT: "checkout",
  ORDER_CONFIRMATION: "order confirmation",
  LOCATION: "Locations",
  CAREERS: "Careers",
  HOME: "Home",
  MENU: "Menu",
  REORDER: "Reorder",
  ACCOUNT: "Account",
  PROFILE: "Profile",
  PAYMENT: "Payment",
  FAVE_STORES: "Fave Stores",
  ADDRESS_BOOK: "Address Book",
  GIFT_CARDS: "Gift Cards",
  COMMUNICATIONS: "Communications",
  REWARDS: "Rewards",
  REDEEM_INSTORE: "Redeem instore",
  SCAN_TO_EARN: "Scan to earn",
  ACTIVATE_YOUR_OFFER: "Activate Your Offer",
  CHOOSE_A_REWARDS: "Choose a rewards",
  REWARD_SELECTED: "Reward selected",
  REWARD_APPLIED: "Reward applied",
  PLACE_ORDER_SUCCESS: "Place order success",
  MIGRATION_SEND_A_CODE: "send_a_code_migration",
  MIGRATION_TERMS_AND_CONDITIONS: "terms_and_conditions_migration",
  MIGRATION_ENTER_YOUR_CODE: "enter_your_code_migration",
  MIGRATION_CHANGE_PASSWORD: "change_password_migration",
  PERSISTENT_BANNER: "Persistent Banner",
  MULTI_ORDER_INTERSTITIAL: "Multi-order Interstitial",
};

export const PAGE_TYPE = {
  TYPE_CART: "Cart",
  TYPE_LOCATION: "Locations",
  TYPE_CAREERS: "Careers",
  TYPE_CHECKOUT: "Checkout",
  TYPE_ORDER_CONFIRMATION: "Order Confirmation",
  TYPE_HOME: "Home",
  TYPE_MENU: "Menu",
  TYPE_REORDER: "Reorder",
  TYPE_ACCOUNT: "Account",
  TYPE_REWARDS: "Rewards",
  TYPE_CATEGORY_ORDERING_PAGE: "Category Ordering Page",
  TYPE_PRODUCT_ORDERING_PAGE: "Product Ordering Page",
  TYPE_ORDER_TRACKING: "Order Tracking",
};

export const AppsFlyerAnalyticsConst: Record<string, string> = {
  [ANALYTICS_CONST.AUTH.SIGN_UP_SUCCESS]: "af_complete_registration",
  [ANALYTICS_CONST.ORDER.ADD_TO_CART]: "add_item_to_cart",
  [ANALYTICS_CONST.AUTH.LOGIN_SUCCESS]: "login_success",
  FIRST_ITEM_ORDER_SUCCESS: "first_purchase",
};

export const AppsFlyerAnalyticsPropertyConst: Record<string, string> = {
  af_registration_method: "af_registration_method",
  price: "af_price",
  item_id: "af_content_id",
  quantity: "af_quantity",
};

export const ANALYTICS_KEY = {
  PAGE_VIEW: "page_view",
};

export const PERIMETER_TRACKING = {
  PERIMETER_TRACKING_HEADER_KEY: "PERIMETER_TRACKING_HEADERS",
};
