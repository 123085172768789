type Coordinate = {
  lat: number;
  lng: number;
};

// Haversine Formula (taken from http://www.movable-type.co.uk/scripts/latlong.html)
export const computeStraightLineDistanceInMiles = (x: Coordinate, y: Coordinate) => {
  const { lat: lat1, lng: lng1 } = x;
  const { lat: lat2, lng: lng2 } = y;

  const R = 6371e3; // meters
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lng2 - lng1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const meters = R * c;
  const miles = meters / 1609.34;
  const round = +miles.toFixed(2);

  return round;
};
