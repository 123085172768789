import { useEffect, MouseEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeViolatorBar, setViolatorCMSData } from "redux/reducer/CMS/CMSAction";
import { CMSRootState } from "redux/reducer/CMS/types";

interface RootState {
  cms: CMSRootState;
}

export const useViolatorBar = () => {
  const dispatch = useDispatch();
  const { isViolatorLoaded, violatorCMSData } = useSelector((state: RootState) => ({
    violatorCMSData: state.cms.violator,
    isViolatorLoaded: state.cms.isViolatorLoaded,
  }));

  const handleCloseViolatorBar = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(closeViolatorBar());
  };
  useEffect(() => {
    if (!isViolatorLoaded) dispatch(setViolatorCMSData());
  }, [dispatch]);

  return { violatorCMSData, handleCloseViolatorBar };
};
