import { useState, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { ANALYTICS_CONST } from "constants/analytics";
import { ONLY_ALPHABET } from "constants/regexPatterns";
import useTimer from "hooks/useTimer";
import Analytics from "analytics/Analytics";
import { phonePageApiError } from "redux/reducer/UserStatus/types";
import { CommonLayerResponseData } from "src/types/types";
import { setErrorMessageToModalFlag, setIsOldPhoneNumValidated } from "redux/reducer/UserStatus/UserStatusAction";
import { USER_PROFILE_PHONE_VERIFICATION_LIMIT_EXCEEDED } from "constants/errorCodeConstants";
import { setLocalStorageData } from "utils/storage";

export const useEnterCode = (
  codeLength: number,
  onResend: () => Promise<void>,
  onSubmit: (code: string) => Promise<CommonLayerResponseData>,
  isProfilePhonePage: boolean
) => {
  const INITIAL_SECONDS = 45;
  const router = useRouter();
  const profilePath = "/account/my-info";
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<phonePageApiError>();
  const [isResendCodeSelected, setIsResendCodeSelected] = useState(false);
  const { seconds, complete, resetTimer } = useTimer(INITIAL_SECONDS);
  const isAtVerifyPhonePage = router?.pathname === profilePath || false;
  const dispatch = useDispatch();
  const handleChange = (_: string, value: string) => {
    setError(false);
    setErrorMessage("");
    setCode(value);
    setIsResendCodeSelected(false);
    setApiErrorMessage({} as phonePageApiError);
    if (codeLength === value.length) {
      setError(false);
    }
  };

  const handleResend = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setError(false);
    setApiErrorMessage({} as phonePageApiError);
    setErrorMessage("");
    setIsResendCodeSelected(true);
    if (complete) {
      onResend().then(resetTimer);
      setCode("");
    }
  };

  const handleSubmit = async (otpTargetLocation: string) => {
    setError(false);
    setIsLoading(true);

    if (code.length !== codeLength) {
      setError(true);
      setIsLoading(false);
      return;
    }

    return onSubmit(code)
      .then((res: CommonLayerResponseData) => {
        setIsLoading(false);

        if (res.success) {
          // Added analytics event of phone_verification_success
          if (ONLY_ALPHABET.test(otpTargetLocation) === false) {
            Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.PHONE_VERIFICATION_SUCCESS);
          }
        } else {
          if (isProfilePhonePage && res.error.apiError?.errorCode === USER_PROFILE_PHONE_VERIFICATION_LIMIT_EXCEEDED) {
            dispatch(setErrorMessageToModalFlag(true));
            dispatch(setIsOldPhoneNumValidated(false));
            setLocalStorageData("isUserPhoneVerificationLimitExceeded", JSON.stringify(true));
          }

          setError(true);
          const message = res.error?.message || res.error?.apiError?.message || "";
          setErrorMessage(message);
          (isProfilePhonePage || isAtVerifyPhonePage) &&
            res.error?.apiError?.errorResponse &&
            setApiErrorMessage(res.error?.apiError?.errorResponse);
        }
      })
      .catch((err) => {
        setError(true);
        setErrorMessage(err.response?.data?.message);
        setIsLoading(false);
      });
  };

  return {
    code,
    error,
    seconds,
    complete,
    errorMessage,
    apiErrorMessage,
    isLoading,
    handleChange,
    handleResend,
    handleSubmit,
    isResendCodeSelected,
  };
};
