import { PAYMENT_WITH_GOOGLE_PAY_SELECTED, SET_GOOGLE_PAY_AMOUNT, SET_MERCHANT_ID, SET_SESSION_KEY } from "./const";
import { GooglePayAction, GooglePayState } from "./types";

const initialState: GooglePayState = {
  isPaymentWithGooglePaySelected: false,
  googlepayAmount: "",
  merchantId: "",
  sessionKey: "",
};

export const googlePayReducer = (state = initialState, action: GooglePayAction): GooglePayState => {
  switch (action.type) {
    case PAYMENT_WITH_GOOGLE_PAY_SELECTED:
      return { ...state, isPaymentWithGooglePaySelected: action.payload ?? false };

    case SET_GOOGLE_PAY_AMOUNT:
      return { ...state, googlepayAmount: action.payload ?? "" };

    case SET_MERCHANT_ID:
      return { ...state, merchantId: action.payload ?? "" };

    case SET_SESSION_KEY:
      return { ...state, sessionKey: action.payload ?? "" };

    default:
      return state;
  }
};
