import dayjs from "dayjs";
import "dayjs/plugin/utc";
import { useDispatch } from "react-redux";
import { USERS } from "api/endpoints";
import { apiCall } from "src/apis/api";
import { ResponseData } from "src/apis/types";
import { setAccountLoader, setAccountSystemError, setCommSubscription } from "redux/reducer/account/action";
import { setUserDetails } from "redux/reducer/user/actions";
import { useIdToken } from "hooks/selectors/useIdToken";

export const useAccount = () => {
  const dispatch = useDispatch();
  const token = useIdToken();

  const onSuccessSubscription = (response: ResponseData) => {
    dispatch(setAccountLoader(false));
    if (response.success) {
      dispatch(setCommSubscription(response?.response));
    } else {
      dispatch(setAccountSystemError(response.error?.message || "Something Went Wrong"));
    }
    return response;
  };

  const getSubscription = () => {
    dispatch(setAccountLoader(true));
    return apiCall({
      apiEndPoint: `${USERS}/subscriptions`,
      requestType: "GET",
      isAuth: true,
      token,
    }).then((response) => onSuccessSubscription(response));
  };

  const getUserDetails = (userToken?: string, accessToken?: string) => {
    dispatch(setAccountLoader(true));
    return apiCall({
      apiEndPoint: `${USERS}/me`,
      requestType: "GET",
      isAuth: true,
      token: userToken,
    }).then((response: any) => {
      dispatch(setAccountLoader(false));
      if (response.success) {
        dispatch(setUserDetails(response?.response));
        const isGPCinstalled = (navigator as any).globalPrivacyControl;

        if (!Object.prototype.hasOwnProperty.call(response?.response, "gpcsOn") && isGPCinstalled === 1) {
          apiCall({
            apiEndPoint: `${USERS}`,
            requestType: "PUT",
            isAuth: true,
            token: userToken,
            apiPostData: {
              firstName: response.response.firstName,
              lastName: response.response.lastName,
              phone: response.response.phone,
              accessToken: accessToken,
              gpcsOn: dayjs().utc().format(),
            },
          });
        }
      }
      return response;
    });
  };

  const setSubscription = (requestData: any) => {
    dispatch(setAccountLoader(true));
    return apiCall({
      apiEndPoint: `${USERS}/subscriptions`,
      requestType: "PUT",
      apiPostData: requestData,
      isAuth: true,
      token,
    }).then((response) => onSuccessSubscription(response));
  };

  return {
    getSubscription,
    setSubscription,
    getUserDetails,
  };
};
