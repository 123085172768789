import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_SIGNIN_SIGNUP_MESSAGE } from "api/endpoints";
import { SignupLoginMessageResponse } from "src/types/cms/home";

export const getSigninSignupMessage = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_SIGNIN_SIGNUP_MESSAGE,
  }) as Promise<CommonLayerResponseDataWithType<SignupLoginMessageResponse>>;
};
