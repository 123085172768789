import DataDogAnalytics from "analytics/Datadog";
import { RevenueProperties } from "src/types/types";
import { AnalyticsListener } from "./AnalyticsListener";

export default class BrazeAnalyticsListener implements AnalyticsListener {
  public brazeInstance: any;

  init() {
    try {
      if (typeof window !== "undefined") {
        import("@braze/web-sdk").then((braze) => {
          if (typeof braze !== "undefined") {
            braze.initialize(`${process.env.NEXT_PUBLIC_BRAZE_KEY}`, {
              baseUrl: `${process.env.NEXT_PUBLIC_BRAZE_API_ENDPOINT}`,
              enableLogging: true,
              manageServiceWorkerExternally: false,
            });
            braze.requestPushPermission();
            braze.automaticallyShowInAppMessages();
            braze.requestContentCardsRefresh();
            braze.subscribeToContentCardsUpdates(function (event: any) {
              if (event?.cards?.length > 0) {
                const contentCardsData: any = event.cards.map((eventCard: any) => ({
                  url: eventCard.url,
                  imageUrl: eventCard.imageUrl,
                  extras: eventCard.extras,
                  expiresAt: eventCard.expiresAt,
                  id: eventCard.id,
                  viewed: eventCard.viewed,
                  created: eventCard.created,
                  updated: eventCard.updated,
                }));
                localStorage.setItem("brazeContentCards", JSON.stringify(contentCardsData));
              } else {
                localStorage.removeItem("brazeContentCards");
              }
            });
            braze.toggleLogging();
            braze.openSession();
            this.brazeInstance = braze;
          }
        });
      }
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Braze" });
    }
  }

  getExcludedEvents() {
    return ["click", "page_view"];
  }

  logEvent(eventName: string, properties?: Record<string, unknown>) {
    this.brazeInstance?.logCustomEvent(eventName, properties);
  }

  // Set user details
  public setUserId = (userId: string) => {
    this.brazeInstance?.changeUser(userId);
  };

  // Track Revenue
  logRevenue(userProperties: RevenueProperties) {
    try {
      this.brazeInstance?.logPurchase(
        userProperties.productId,
        userProperties.price,
        userProperties.revenueType,
        userProperties.quantity,
        userProperties.eventProperties || undefined
      );
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Braze" });
    }
  }
}
