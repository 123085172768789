import styled from "styled-components";
import { IC_LOCATION_PIN_RED } from "assets/images";

export const SearchCardStyled = styled.div<{
  isSearching?: boolean;
  isDelivery?: boolean;
  isAuth?: boolean;
}>`
  max-width: 390px;
  height: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  align-self: flex-start;
  background: ${(props) => props.theme.color.core.white};
  padding: 40px 24px 24px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  .mobileCloseButton {
    display: none;
  }
  .noResults {
    padding-bottom: 0;
    .text {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .locationErrorModal {
    .heading {
      font-size: 40px;
      font-weight: 800;
      font-family: ${(props) => props.theme.font.barlowSemiCondensed};
      font-style: normal;
    }
    .paragraph {
      max-width: 100%;
    }
  }
  .searchCardtoggleInput {
    margin-bottom: 20px;
    * {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 20px;
      font-weight: 700;
    }
    .label {
      width: 300px;
      height: 44px;
      border: none;
      background: ${(props) => props.theme.color.secondary.coolGray1};
      &::after {
        width: 150px;
        background: ${(props) => props.theme.color.primary.tongueTorchRed};
      }
    }

    .activeOption {
      color: ${(props) => props.theme.color.secondary.coolGray10};
    }
  }
  .image-wrapper {
    cursor: pointer;
    position: absolute;
    height: 24px;
    width: 24px;
    right: 28px;
    top: 28px;
  }
  .underlinedHeading {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
  }

  .toggleInput {
    margin-bottom: 25px;
    label {
      width: 285px;
    }
  }

  .searchContainer {
    > div {
      grid-gap: 0;
      .searchInput {
        + label {
          left: 20px;
        }
        &:disabled {
          opacity: 1;
        }
      }
      .input-wrapper {
        width: ${(props) => (props.isDelivery ? "294px" : "258px")};
      }
      .clearIcon {
        width: 18px;
        position: absolute;
        right: 16px;
        bottom: 12px;
        cursor: pointer;
      }
    }

    .storeAddressContainer {
      opacity: 0;
      /* Width is input field width + 2px */
      width: 292px;
      left: 1px;
      top: ${(props) => (props.isAuth ? "80px" : "60px")};
      position: absolute;
      background-color: ${(props) => props.theme.color.core.white};
      z-index: 9;
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.25);

      > li {
        border-top: none;
        cursor: pointer;
        color: ${(props) => props.theme.color.primary.foundersBlue};
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding: 0 12px;
        > div {
          > div {
            font-family: ${(props) => props.theme.font.barlow};
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            width: 100%;
          }
        }
        .pinIcon {
          background-image: url(${IC_LOCATION_PIN_RED.src});
          width: 26px;
          height: 25px;
          background-size: 17px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          margin-top: 0;
          margin-right: 8px;
        }
      }
    }

    .searchGpsIcon {
      flex-shrink: 0;
    }

    &:focus-within {
      .storeAddressContainer {
        opacity: 1;
        padding-bottom: 30px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .notLoggedIn {
    padding-top: 0;
  }

  .noFavoriteStoresText {
    padding-bottom: 0;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    position: absolute;
    border-radius: 0;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 9999;

    .locationErrorModal {
      .heading {
        font-size: 24px;
        margin-top: 24px;
      }
      .paragraph {
        width: 85%;
      }
    }
    .textFieldWrapper {
      width: 100%;
    }
    .mobileCloseButton {
      display: grid;
      align-self: flex-end;
    }
    .searchContainer {
      > div {
        .clearIcon {
          width: 14px;
          right: 10px;
          bottom: 6px;
        }
        .searchInput {
          font-size: 16px;
          padding: 23px 45px 6px 40px;
          + label {
            left: 6px;
          }
        }
        .input-wrapper {
          width: 100%;
        }
      }

      .storeAddressContainer {
        /* input width + 2px */
        width: 294px;
        top: ${(props) => (props.isAuth ? "65px" : "44px")};
        border-radius: 0;
        left: 0;
      }
    }

    .underlinedHeading {
      margin-top: 64px;
      margin-bottom: 20px;
    }

    .searchCardtoggleInput {
      margin: 40px 0 20px;
      .label {
        width: 262px;
        height: 36px;
        border: none;

        span {
          font-size: 15px;
        }
        &::after {
          width: 134px;
        }
      }
    }
  }
`;

export const SearchCardSpaceStyled = styled.div`
  height: 100px;
`;
