import styled from "styled-components";
import { responsive } from "theme/responsive";

export const StoreRequestConfirmationStyled = styled.div`
  .cardFooter {
    padding: 32px 0 50px;

    ${responsive.max.md(`
        padding: 30px 0;
    `)}
  }
  .customOverlay {
    z-index: 2000;
  }
  .customModal {
    ${responsive.max.md(`
      width: calc(100% - 40px);
      top: 32px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      border-radius: 40px;
      height: auto;
    `)}
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .popupHeading {
    margin-top: 60px;
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    text-align: center;
  }

  .popupContent {
    margin-top: 15px;
    max-width: 364px;
    text-align: center;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .popupHeading {
      margin-top: 10px;
    }
    .popupContent {
      font-weight: 500;
      font-size: 14px;
      line-height: 16.8px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 0px 15px;

    > button {
      width: 240px;
      height: 44px;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    min-height: 75px;

    .buttonContainer {
      flex-direction: column;
      gap: 15px 0px;

      > button {
        width: 200px;
        height: 36px;
      }
    }
  }
`;
