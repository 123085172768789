import { apiCall } from "src/apis/api";

export const getGoogleGeocode = (queryLocation: string) => {
  const googleKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string;
  return apiCall({
    header: {},
    requestType: "GET",
    apiEndPoint: `https://maps.googleapis.com/maps/api/geocode/json?address=${queryLocation}&key=${googleKey}`,
  });
};
