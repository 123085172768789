import { UserAction, UserState } from "./types";

const initialState: UserState = {
  type: null,
  tokens: null,
  details: null,
  stores: null,
  addresses: null,
  isAuthenticated: false,
  userLoyaltyAvailability: false,
  changesSaved: false,
  guestUserDetails: null,
  giftCardSelectedType: false,
  trackingPermissionType: null,
  vehicleList: [],
  maxmumVehiclesReachedError: "",
  selectedVehicle: {},
  addVehicleInputErrors: {},
};

export const userReducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case "SET_USER_TYPE":
      return { ...state, type: action.payload || null };

    case "SET_USER_TOKENS":
      return {
        ...state,
        tokens: action.payload || null,
        details: !action.payload ? null : state.details,
        isAuthenticated: Boolean(action.payload),
      };

    case "SET_USER_DETAILS":
      return { ...state, details: action.payload || null, isAuthenticated: Boolean(action.payload) };

    case "SET_USER_STORES":
      return { ...state, stores: action.payload || null };

    case "SET_USER_ADDRESSES":
      return { ...state, addresses: action.payload || null };

    case "SET_USER_LOYALTY_AVAILABILITY":
      return { ...state, userLoyaltyAvailability: action.payload || false };

    case "UPDATE_USER_DETAILS":
      return { ...state, details: action.payload || null, changesSaved: true };

    case "USER_SUBSCRIPTION":
      if (action.payload && state.details) {
        return { ...state, details: { ...state.details, subscription: action.payload } };
      } else {
        return { ...state };
      }

    case "SET_GUEST_USER_DETAILS":
      return { ...state, guestUserDetails: action.payload || null };

    case "SET_TRACKING_PERMISSION_TYPE":
      return { ...state, trackingPermissionType: action.payload || null };

    case "SET_SELECTED_GIFT_CARD_TYPE":
      return { ...state, giftCardSelectedType: action.payload || false };

    case "SET_VEHICLE_LIST":
      return { ...state, vehicleList: action.payload || [] };

    case "SET_ADD_VEHICLE_ITEM_ERROR_MESSAGE":
      return { ...state, addVehicleInputErrors: action.payload || [] };

    case "SET_VEHICLE_LIST_ERROR_MESSAGE":
      return { ...state, maxmumVehiclesReachedError: action.payload || "" };

    case "SET_SELECTED_VEHICLE":
      return { ...state, selectedVehicle: action.payload || {} };

    case "RESET":
      return {
        type: null,
        tokens: null,
        details: null,
        stores: null,
        addresses: null,
        isAuthenticated: false,
        userLoyaltyAvailability: false,
        changesSaved: false,
        guestUserDetails: null,
        trackingPermissionType: null,
        giftCardSelectedType: false,
        vehicleList: [],
        maxmumVehiclesReachedError: "",
        addVehicleInputErrors: null,
        selectedVehicle: {},
      };

    default:
      return state;
  }
};
