import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_LOCATION } from "api/endpoints";
import { FranchiseLocationsCMSData } from "types/cms/franchise-locations";

export const getFranchiseLocationsCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_LOCATION,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseLocationsCMSData>>;
};
