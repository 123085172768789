import { useSelector } from "react-redux";
import CloseButton from "components/global/Buttons/CloseButton/CloseButton";
import Modal from "components/global/Modal/Modal";
import useModal from "hooks/useModal";
import { RootState } from "src/types/types";
import { GetStartedComfirmationStyled } from "./getStartedComfirmationStyled";

const GetStartedConfirmationModal = () => {
  const { modal, setModal } = useModal();

  const { getStartedMessage } = useSelector((state: RootState) => ({
    getStartedMessage: state?.franchise?.getStartedMessage,
  }));

  const onCloseModal = () => {
    setModal("");
  };
  return (
    <GetStartedComfirmationStyled>
      <Modal isOpen={modal === "FRANCHISE_GET_STARTED_CONFIRMATION"} className="notAvailableForDelivery getStartedStyled">
        <CloseButton variant="first" onClick={onCloseModal} className="modalCloseButon modalNavigatiomButton" />
        <div className="notAvailableForDeliveryContainer">
          <div className="heading-container">
            <span className="sub-heading">{getStartedMessage?.message}</span>
            <span className="describtion">{getStartedMessage?.description}</span>
          </div>
        </div>
      </Modal>
    </GetStartedComfirmationStyled>
  );
};
export default GetStartedConfirmationModal;
