import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { ORDER_DETAILS } from "api/endpoints";
import { RootState } from "src/types/types";
import { reorderItems } from "utils/helper";
import { apiCall } from "../apis/api";

export default function useReorder() {
  const router = useRouter();
  const { basket, selectedStoreId, tokens, userAddress, corporateStoreId, recentOrdersList } = useSelector((state: RootState) => ({
    basket: state.basket?.basket,
    selectedStoreId: state.store?.selectedStoreId,
    tokens: state.user?.tokens,
    userAddress: state.address?.userAddress,
    corporateStoreId: state.store?.corporateStoreId,
    recentOrdersList: state?.placeOrder?.recentOrdersList,
  }));

  const reorder = async (orderId: string, orderType: string, overrideStoreId?: number, shouldRedirect?: boolean) => {
    let refreshedRecent;
    if (overrideStoreId) {
      await apiCall({
        requestType: "POST",
        apiEndPoint: `${ORDER_DETAILS}recents?basedOnStore=true`,
        apiPostData: {
          accessToken: tokens?.accessToken,
          storeId: overrideStoreId,
        },
      }).then((response) => {
        refreshedRecent = response.response;
      });
    }

    const recentOrderRequest = (refreshedRecent ?? recentOrdersList).find(({ orderId: oId }) => orderId === oId);

    if (!recentOrderRequest) {
      throw Error("Something went wrong with reorder request");
    }

    try {
      reorderItems(
        basket,
        false,
        tokens?.accessToken,
        orderId,
        router,
        overrideStoreId ?? selectedStoreId,
        corporateStoreId,
        recentOrderRequest,
        orderType,
        tokens?.idToken,
        orderType === "delivery" ? userAddress : undefined
      );

      if (shouldRedirect) {
        router.push("/cart");
      }
    } catch (_e) {
      if (shouldRedirect) {
        router.push("/");
      }
    }
  };

  return reorder;
}
