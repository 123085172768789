import {
  SET_CREDIT_CARD_LIST,
  SET_FREEDOMPAY_IS_LOADING,
  SET_IFRAME_URL,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_OPTIONS,
  SET_PAYMENT_TOKEN,
  SET_SAVE_NEW_CARD_IN_ACCOUNT,
  SET_SELECTED_CARD,
  RESET_PAYMENT,
} from "./const";
import { FreedomPayAction, FreedomPayState } from "./types";

const initialState: FreedomPayState = {
  error: "",
  isLoading: false,
  iFrameURL: "",
  creditCardList: [],
  paymentOptions: [],
  selectedCard: {},
  paymentToken: {},
  saveNewCardInAccount: false,
  paymentMethods: [],
};

const freedomPayReducer = (state = initialState, action: FreedomPayAction): FreedomPayState => {
  switch (action.type) {
    case SET_IFRAME_URL:
      return { ...state, iFrameURL: action.payload || "" };

    case SET_CREDIT_CARD_LIST:
      return { ...state, creditCardList: action.payload || [] };

    case SET_FREEDOMPAY_IS_LOADING:
      return { ...state, isLoading: action.payload || false };

    case SET_PAYMENT_OPTIONS:
      return { ...state, paymentOptions: action.payload || [] };

    case SET_SELECTED_CARD:
      return { ...state, selectedCard: action.payload || {} };

    case SET_PAYMENT_TOKEN:
      return { ...state, paymentToken: action.payload || {} };

    case SET_SAVE_NEW_CARD_IN_ACCOUNT:
      return { ...state, saveNewCardInAccount: action.payload || false };

    case SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload || [] };

    case RESET_PAYMENT:
      return {
        ...state,
        error: "",
        isLoading: false,
        iFrameURL: "",
        creditCardList: [],
        paymentOptions: [],
        selectedCard: {},
        paymentToken: {},
        saveNewCardInAccount: false,
        paymentMethods: [],
      };

    default:
      return state;
  }
};

export default freedomPayReducer;
