import { LOWERCASE_REGEX, NUMBERS_REGEX, SPECIAL_CHARECTER_REGEX, UPPERCASE_REGEX } from "constants/regexPatterns";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { PasswordCase, StrengthIndicator } from "./PasswordStrengthIndicatorStyled";

const PasswordStrengthIndicator = (props: { password: string; isPasswordFieldoutOfFocus: boolean }) => {
  const { password, isPasswordFieldoutOfFocus } = props;

  const hasUppercase = UPPERCASE_REGEX.test(password);
  const hasLowercase = LOWERCASE_REGEX.test(password);
  const hasSpecialChar = SPECIAL_CHARECTER_REGEX.test(password);
  const hasNumber = NUMBERS_REGEX.test(password);
  const hasLengthOfEight = password.length >= 8;

  const validations = [hasUppercase, hasLowercase, hasNumber, hasSpecialChar, hasLengthOfEight];
  const PasswordCases = ["ABC", "abc", "123", "!@$", "8+ characters"];

  const cases = PasswordCases.map((value, index) => {
    return (
      <PasswordCase validation={validations[index]} key={index} isPasswordFieldoutOfFocus={isPasswordFieldoutOfFocus}>
        {value}
      </PasswordCase>
    );
  });

  return (
    <StrengthIndicator className="strengthIndicator">
      <div className="strengthWrapper">
        <Paragraph variant="secondaryParagraph4" className="required">
          Required:{" "}
        </Paragraph>
        {cases}
      </div>
    </StrengthIndicator>
  );
};

export default PasswordStrengthIndicator;
