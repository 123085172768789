export const NAME_INVALID = "Field cannot contain numeric or special characters";
export const PHONE_INVALID = "PLEASE ENTER A VALID PHONE NUMBER";
export const EMAIL_INVALID = "PLEASE ENTER A VALID EMAIL ADDRESS";
export const PASSWORD_INVALID = "Password does not meet requirements";
export const REQUIRED_FIELD = "Required Field";
export const REFERRAL_CODE_INVALID = "Field should be alphanumeric and cannot contain spaces or special characters";
export const PASSWORD_MUST_REQUIRED = "Password must required";
export const CONFIRM_PASSWORD_NOTSAME = "Passwords do not match";
export const INVALID_COLOR = "PLEASE ENTER COLOR";
export const INVALID_MAKE = "PLEASE ENTER MAKE";
export const INVALID_MODEL = "PLEASE ENTER MODEL";
