import {
  SET_CREDIT_CARD_LIST,
  SET_FREEDOMPAY_IS_LOADING,
  SET_IFRAME_URL,
  SET_PAYMENT_OPTIONS,
  SET_PAYMENT_TOKEN,
  SET_SAVE_NEW_CARD_IN_ACCOUNT,
  SET_SELECTED_CARD,
  SET_PAYMENT_METHODS,
  RESET_PAYMENT,
} from "./const";
import {
  SetCreditCardListAction,
  SetCreditCardListPayload,
  SetFreedomPayLoadingAction,
  SetIFrameAction,
  SetPaymentOptionsAction,
  SetPaymentOptionsPayload,
  SetPaymentTokenAction,
  SetPaymentTokenPayload,
  SetSaveNewCardInAccountAction,
  SetSavePaymentMethods,
  SetSelectedCardAction,
  SetSelectedCardPayload,
} from "./types";

export const setIFrameURL = (IFrame: string): SetIFrameAction => ({
  type: SET_IFRAME_URL,
  payload: IFrame,
});

export const setCreditCardList = (arrCreditCards: SetCreditCardListPayload): SetCreditCardListAction => ({
  type: SET_CREDIT_CARD_LIST,
  payload: arrCreditCards,
});

export const setFreedomPayLoader = (isLoading: boolean): SetFreedomPayLoadingAction => ({
  type: SET_FREEDOMPAY_IS_LOADING,
  payload: isLoading,
});

export const setPaymentOptions = (options: SetPaymentOptionsPayload): SetPaymentOptionsAction => ({
  type: SET_PAYMENT_OPTIONS,
  payload: options,
});

export const setSelectedCard = (options: SetSelectedCardPayload): SetSelectedCardAction => ({
  type: SET_SELECTED_CARD,
  payload: options,
});

export const setPaymentToken = (options: SetPaymentTokenPayload): SetPaymentTokenAction => ({
  type: SET_PAYMENT_TOKEN,
  payload: options,
});

export const setSaveNewCardInAccount = (options: boolean): SetSaveNewCardInAccountAction => ({
  type: SET_SAVE_NEW_CARD_IN_ACCOUNT,
  payload: options,
});

export const setPaymentMethods = (options: Array<Object>): SetSavePaymentMethods => ({
  type: SET_PAYMENT_METHODS,
  payload: options,
});

export const resetPayment = () => ({
  type: RESET_PAYMENT,
});
