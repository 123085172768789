import { MeResponse } from "src/types/types";
import { ME_CONST, USER_NEW_REFRESH_TOKEN, USER_PHONE_UPDATE_DATA } from "./MeConst";

const initialState = {
  currentUser: null,
  userData: [],
  userNewRefreshToken: {},
};

export default function MeReducer(state = initialState, action: { type: string; payload: MeResponse }) {
  switch (action.type) {
    case ME_CONST:
      return {
        ...state,
        currentUser: action.payload,
      };
    case USER_PHONE_UPDATE_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case USER_NEW_REFRESH_TOKEN:
      return {
        ...state,
        userNewRefreshToken: action.payload,
      };
    default:
      return state;
  }
}
