import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { GenericObject } from "../types/types";

type RequestFunction = typeof axios.get;

interface Parameters {
  endpoint: string;
  headers?: GenericObject;
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  params?: GenericObject;
  body?: GenericObject;
  isAuth?: boolean;
}

export const isMobile = () => {
  if (typeof document === "undefined" && typeof navigator !== "undefined" && navigator.product === "ReactNative") {
    return true;
  }
  return false;
};

// removes params that have undefined / null values
const cleanParams = (params: GenericObject) => {
  const cleanedParams = {} as any;

  for (const key in params) {
    if (params[key] !== null && params[key] !== undefined) {
      cleanedParams[key] = params[key];
    }
  }

  return cleanedParams;
};

const handleRequest = <Response>(
  requestFunction: RequestFunction,
  endpoint: string,
  headers: GenericObject,
  params: GenericObject,
  ...rest: any
) =>
  new Promise<Response>((resolve, reject) => {
    const cleanedParams = cleanParams(params);

    requestFunction<Response>(endpoint, ...rest, {
      timeout: endpoint.includes("submit") ? 60000 : 10000, // For place order timeout is of 60s otherwise timeout of 10s
      params: cleanedParams,
      headers,
    })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err?.response?.data?.message);
      });
  });

// add headerType parameter and conditionally add appropriate header when making request
// access tokens like idToken, accessToken from redux

// axios middleware - search for inbuilt retry mechanisms and other features

export const request = async <Response>({ endpoint, method, isAuth = false, headers = {}, params = {}, body = {} }: Parameters) => {
  // selects appropriate axios function based on API method
  const requestFunction = {
    GET: axios.get,
    POST: axios.post,
    PATCH: axios.patch,
    PUT: axios.put,
    DELETE: axios.delete,
  }[method];
  if (method === "GET" || method === "DELETE") {
    return handleRequest<Response>(requestFunction, endpoint, headers, params);
  } else {
    return handleRequest<Response>(requestFunction, endpoint, headers, params, body);
  }
};

const getTokenForAPILayer = async () => {
  const tokens = (await getTokensForAPICall()) as Record<string, unknown>;
  const requiredToken = tokens?.token as string;
  return requiredToken;
};

export const authHeader = (_idToken?: string) => {
  return { Authorization: `Bearer ${getTokenForAPILayer()}` };
};

// export const getMobileUserAgent = async () => {
//   const { getReducerFromKey } = require("../api/mobileUtils");
//   const device = await getReducerFromKey("device");
//   return device.device.userAgent;
// };

const getWebAuthToken = () => {
  return new Promise((resolve) => {
    try {
      const stringTokens = `${secureLocalStorage.getItem("tokens")}` || sessionStorage.getItem("tokens");
      const tokens = stringTokens && JSON.parse(stringTokens);
      resolve({ token: tokens?.idToken, refreshToken: tokens?.refreshToken });
    } catch (error) {
      console.error("Invalid token ", error);
    }
  });
};

export const getTokensForAPICall = async () => {
  let tokens = null;
  tokens = await getWebAuthToken();

  return tokens;
};
