import styled from "styled-components";

export const AuthCardStyled = styled.div`
  width: 632px;
  min-height: 360px;
  background: ${({ theme }) => theme.color?.primary.coleslawWhite};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 72px 0px 0px 0px;
  overflow: hidden;
  .authCardHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .titleWrapper {
    text-align: center;
    letter-spacing: 0px;
    padding: 0px 80px;
  }
  .descriptionWrapper {
    padding: 0px 40px;
    text-align: center;
    margin-top: 30px;
    text-transform: lowercase;
    color: ${({ theme }) => theme.color?.core.black};
    font-family: ${({ theme }) => theme.font?.barlow};
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .okButton {
    width: 162px;
  }
  ${(props) => props.theme.responsive?.max.lg} {
    width: 302px;
    min-height: 356px;
    background: linear-gradient(to bottom, ${({ theme }) => theme.color?.primary.coleslawWhite} 95%, rgba(255, 255, 255, 0) 80%);
    .titleWrapper {
      padding: 0px 20px;
    }
    .descriptionWrapper {
      padding-bottom: 24px;
    }
    .okButton {
      width: 148px;
    }
  }
`;

export const AuthCardFooter = styled.div`
  height: 136px;
  background: ${({ theme }) => theme.color?.primary.foundersBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  width: 140%;
  margin-left: -20%;
  border-radius: 100% 100% 0 0;
  ${(props) => props.theme.responsive?.max.lg} {
    flex-direction: column-reverse;
    height: 156px;
    button {
      width: 200px;
    }
  }
`;
