import { currentLocation } from "./types";
import {
  IS_SHOW_TOGGLE_MODAL,
  SET_TOGGLE_MENUS,
  SET_IS_FORM_VALID,
  SET_IS_APPLEPAY_PAYMENT_SUPPORTED,
  SAVE_SELECTED_SPOTLIGHT_ITEM_INDEX,
  SET_ADDED_NOTIFICATION_CLOSE,
  SET_ADDED_NOTIFICATION_OPEN,
  SET_CLOSE_RIBBON_BANNER,
  SET_IS_SHOW_RIBBON_BANNER,
  SET_USER_CURRENT_LOCATION,
  SET_IS_LOADING,
} from "./GlobalConst";

export const setShowToggleModal = (payload: boolean) => ({
  type: IS_SHOW_TOGGLE_MODAL,
  payload,
});

export const setShowToggleMenus = (payload: boolean) => ({
  type: SET_TOGGLE_MENUS,
  payload,
});

export const setIsFormValid = (payload: boolean) => ({
  type: SET_IS_FORM_VALID,
  payload,
});

export const setIsApplePayPaymentSupported = (payload: boolean) => ({
  type: SET_IS_APPLEPAY_PAYMENT_SUPPORTED,
  payload,
});

export const saveSelectedSpotlightItemIndex = (payload: number) => {
  return { type: SAVE_SELECTED_SPOTLIGHT_ITEM_INDEX, payload: payload };
};

export const setAddedNotificationOpen = (itemName: any, duration: any) => {
  return { type: SET_ADDED_NOTIFICATION_OPEN, payload: { itemName, duration } };
};

export const setAddedNotificationClose = () => {
  return { type: SET_ADDED_NOTIFICATION_CLOSE };
};

export const setCloseRibbonBanner = (payload: boolean) => {
  return { type: SET_CLOSE_RIBBON_BANNER, payload };
};

export const setShowRibbonBanner = (payload: boolean) => {
  return { type: SET_IS_SHOW_RIBBON_BANNER, payload };
};

export const setUserCurrentLocation = (payload: currentLocation) => {
  return { type: SET_USER_CURRENT_LOCATION, payload };
};

export const setIsLoading = (payload: boolean) => {
  return { type: SET_IS_LOADING, payload };
};
