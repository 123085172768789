import {
  IS_APPLE_PAY_SUPPORTED,
  PAYMENT_WITH_APPLE_PAY_SELECTED,
  PAYMENT_WITH_APPLE_PAY_SELECTED_CAN_MAKE_PAYMENTS,
  SET_APPLE_IFRAME_URL,
} from "./const";
import { ApplePayAction, ApplePayState } from "./types";

const initialState: ApplePayState = {
  error: "",
  isLoading: false,
  appleIframeURl: "",
  applePayIsSelectedForPayment: false,
  isApplePaySupported: false,
  applePayCanMakePayments: false,
};

export const applePayReducer = (state = initialState, action: ApplePayAction): ApplePayState => {
  switch (action.type) {
    case SET_APPLE_IFRAME_URL:
      return { ...state, appleIframeURl: action.payload || "" };

    case PAYMENT_WITH_APPLE_PAY_SELECTED:
      return { ...state, applePayIsSelectedForPayment: action.payload || false };

    case IS_APPLE_PAY_SUPPORTED:
      return { ...state, isApplePaySupported: action.payload || false };

    case PAYMENT_WITH_APPLE_PAY_SELECTED_CAN_MAKE_PAYMENTS:
      return { ...state, applePayCanMakePayments: action.payload || false };

    default:
      return state;
  }
};
