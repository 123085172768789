import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_MENUS } from "api/endpoints";
import { MenuCMSData } from "types/cms/menus";

export const getMenuCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_MENUS,
  }) as Promise<CommonLayerResponseDataWithType<MenuCMSData>>;
};
