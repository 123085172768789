import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/types/types";
import useModal from "hooks/useModal";
import Modal from "components/global/Modal/Modal";
import { passwordOptions } from "api/users/password-options";
import { setIsPasswordResetLimitExceeded, setMigratedUserInfo } from "redux/reducer/UserStatus/UserStatusAction";
import { MIGRATED_USER_PASSWORD_RESET_REQUIRED_CODE } from "constants/errorCodeConstants";
import SignInSignUpCard from "components/Auth/SignInSignUpCard/SignInSignUpCard";
import { CodeDeliveryMedium, CodeDeliveryOptions, ForgotPasswordStep } from "./types";
import ForgotPasswordContext from "./ForgotPasswordContext";
import ForgotPasswordPrompt from "./ForgotPasswordPrompt/ForgotPasswordPrompt";
import ForgotPasswordSendCode from "./ForgotPasswordSendCode/ForgotPasswordSendCode";
import ForgotPasswordEnterCode from "./ForgotPasswordEnterCode/ForgotPasswordEnterCode";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm/ForgotPasswordConfirm";
import ForgotPasswordUpdated from "./ForgotPasswordUpdated/ForgotPasswordUpdated";
import { ForgotPasswordWrapperStyled } from "./ForgotPasswordStyled";

function ForgotPassword() {
  const dispatch = useDispatch();
  const { migratedUser, device } = useSelector((state: RootState) => ({
    migratedUser: state.userStatus.migratedUser,
    device: state.currentDevice.device,
  }));

  const [apiStatus, setApiStatus] = useState(true);
  const [code, setCode] = useState("");
  const defaultStep = apiStatus && migratedUser.errorCode !== MIGRATED_USER_PASSWORD_RESET_REQUIRED_CODE ? "PROMPT" : "SEND_CODE";
  const [step, setStep] = useState<ForgotPasswordStep>(defaultStep);
  const [medium, setMedium] = useState<CodeDeliveryMedium>("EMAIL");
  const [options, setOptions] = useState<CodeDeliveryOptions>({
    email: "",
    phone: "",
    status: "",
  });
  const [username, setUsername] = useState("");

  const { modal, setModal } = useModal();

  const handleClose = () => {
    dispatch(setIsPasswordResetLimitExceeded(false));
    dispatch(setMigratedUserInfo({ errorCode: 0, username: "" }));
    setModal("LOGIN");
  };

  const goToPreviousPage = () => (defaultStep === "PROMPT" ? setStep("PROMPT") : handleClose());

  const BODY = {
    PROMPT: <ForgotPasswordPrompt />,
    SEND_CODE: <ForgotPasswordSendCode handleClose={handleClose} goToPreviousPage={goToPreviousPage} />,
    ENTER_CODE: <ForgotPasswordEnterCode />,
    CONFIRM: <ForgotPasswordConfirm />,
    UPDATED: <ForgotPasswordUpdated />,
  }[step];

  useEffect(() => {
    if (migratedUser.errorCode === MIGRATED_USER_PASSWORD_RESET_REQUIRED_CODE) {
      passwordOptions({ username: migratedUser.username }).then((res: any) => {
        setUsername(migratedUser.username);
        setOptions(res.response);
        setApiStatus(false);
      });
    }
  }, [migratedUser.errorCode]);

  const closeSet = () => {
    if (device === "MOBILE") {
      if (step === "SEND_CODE" || step === "CONFIRM" || step === "UPDATED") {
        return false;
      }
      return true;
    } else if (step === "UPDATED") {
      return false;
    }
  };

  return (
    <ForgotPasswordContext.Provider
      value={{
        step,
        code,
        medium,
        options,
        username,
        setStep,
        setCode,
        setMedium,
        setOptions,
        setUsername,
      }}
    >
      <ForgotPasswordWrapperStyled>
        <Modal isOpen={modal === "FORGOT_PASSWORD"} overlayClassName="forgotPasswordOverlay">
          <SignInSignUpCard body={BODY} onClose={handleClose} isCardMobile={true} className="customCard" isCloseRequired={closeSet()} />
        </Modal>
      </ForgotPasswordWrapperStyled>
    </ForgotPasswordContext.Provider>
  );
}

export default ForgotPassword;
