import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import Checkbox from "components/atomic-components/atoms/form-elements/Checkbox/Checkbox";
import Button from "components/atomic-components/atoms/button/Button";
import { getPhoneNumberInParenthesisAndDashformat } from "utils/formatter/getPhoneNumberInDashFormat";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import StackedButtonsContainer from "../StackedButtonsContainer/StackedButtonsContainer";
import BackButton from "../Buttons/BackButton/BackButton";
import Error from "../Error/Error";
import { SendCodeStyled, CheckboxBoldText, CheckboxText } from "./SendCodeStyled";
import { useSendCode } from "./useSendCode";
import { Values } from "./types";

interface Props {
  options: {
    email: string;
    phone: string;
  };
  onBackBtnClick?: () => void;
  onCancel: () => void;
  onSubmit: (selected: Values) => Promise<any>;
  isBackButtonPresent?: boolean;
}
const SendCode = ({ options, onCancel, onSubmit, onBackBtnClick, isBackButtonPresent }: Props) => {
  const { device, migratedUser } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
    migratedUser: state.userStatus.migratedUser,
  }));
  const { values, error, handleChange, handleSubmit, disableSendBtn } = useSendCode(onSubmit);

  const formattedEnterCodeMessage = (phone: string) => {
    return getPhoneNumberInParenthesisAndDashformat(options?.phone);
  };

  return (
    <SendCodeStyled>
      {isBackButtonPresent && (
        <BackButton type="button" variant="twelveth" onClick={onBackBtnClick} className="sendACodeBackButton">
          Back
        </BackButton>
      )}
      <Headings className="heading" fontSize={40} fontWeight={800} mobileFontSize={28} variant={"secondaryHeading2"}>
        SEND A CODE
      </Headings>
      {device === "MOBILE" && <hr className="horizontalDivider" />}
      <Paragraph variant="secondaryParagraph1" mobileFontSize={14} className="text">
        {migratedUser.errorCode === 1106
          ? "To migrate your account, we need to verify it’s you. We’ll text you a code to enter on the next screen"
          : "Choose where we should send your verification code."}
      </Paragraph>
      <CheckboxText>
        <Checkbox name="phone" value={values.phone} onChange={handleChange} className="checkBox textACode" variant="primaryBlueFilled">
          <CheckboxBoldText>TEXT</CheckboxBoldText> a code to: {formattedEnterCodeMessage(options?.phone)}
        </Checkbox>
        <Checkbox name="email" value={values.email} onChange={handleChange} className="checkBox" variant="primaryBlueFilled">
          <CheckboxBoldText>EMAIL</CheckboxBoldText> a code to: {options?.email}
        </Checkbox>
      </CheckboxText>
      {error && <Error className="errorRed">{error}</Error>}
      <StackedButtonsContainer className="stackedButtons forgotButtonsMobileView" direction="col" mobileDirection="col-reverse">
        <Button className="cancelButton" variant="secondaryBlue" onClick={onCancel}>
          cancel
        </Button>
        <Button
          className="sendButton"
          variant="primaryBlue"
          disabled={(!values.email && !values.phone) || disableSendBtn}
          onClick={handleSubmit}
          data-testid="sendCodeButton"
        >
          SEND CODE
        </Button>
      </StackedButtonsContainer>
    </SendCodeStyled>
  );
};

export default SendCode;
