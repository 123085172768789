import styled from "styled-components";

export const PhoneOTPValidationError = styled.div`
  display: flex;
  font-size: 11px;
  font-weight: 700;
  justify-content: center;
  color: ${(props) => props.theme.color.primary.tongueTorchRed};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  align-items: center;
  margin-top: 12px;
  a {
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    font-family: ${(props) => props.theme.font.sourceSansPro};
    font-weight: 700;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    font-size: 10px;
    a {
      font-size: 10px;
    }
  }
`;

export const PhoneNumber = styled.div`
  font-family: ${(props) => props.theme.font.barlow};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const EnterCodeStyled = styled.div<{ isError: boolean; isShowCMSErrorMessage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  margin: 0 auto;

  .heading {
    margin-bottom: ${(props) => (props.isShowCMSErrorMessage ? "0px" : "24px")};
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    text-transform: uppercase;
    max-width: none;
  }
  .errorModal {
    .cmsErrorMessageLink {
      width: min(95%, 290px);
    }
  }
  .horizontalDivider {
    border-bottom: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
    width: 294px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .oldOTP {
    margin: 20px 0 20px;
  }

  .enterCodeText {
    margin: 10px 0 5px;
  }

  .phoneNumber {
    color: ${(props) => props.theme.color.core.black};
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .resendForgotPasswordCode {
    font-family: ${(props) => props.theme.font.barlow};
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    margin: 0 auto;
    padding-bottom: 1px;
    display: block;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .resendProfilePageOTP {
    font-size: 16px;
    text-decoration: underline;
    line-height: normal;
    text-underline-offset: 3px;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .inputs {
    margin-top: 32px;
    gap: 10px;
  }

  .enterYourCodeBackButton {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    position: absolute;
    top: 30px;
    left: 30px;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 20px;
  }
  .enterYourCodeCloseButton {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    position: absolute;
    top: 1px;
    right: 20px;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 20px;
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .callUsText {
    max-width: 294px;
    margin-top: 32px;
    text-align: center;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 16px;
    font-weight: 500;
    a {
      padding: 2px 0px 10px 0px;
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-family: ${(props) => props.theme.font.barlow};
      font-weight: 700;
      text-transform: capitalize;
      text-underline-offset: 2px;
    }
  }
  .phone-verified {
    display: flex;
    font-size: 14px;
    line-height: 16.8px;
    font-family: ${(props) => props.theme.font.barlow};
    margin-bottom: 50px;
    span {
      height: 20px;
      width: 20px;
    }
    p {
      color: ${(props) => props.theme.color.limitedUse.leafyGreen};
    }
  }
  .newOtp {
    width: 85%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    span {
      font-family: ${(props) => props.theme.font.barlow};
    }
  }

  .buttonContainer {
    margin-top: 32px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    button {
      width: 190px !important;
      height: 44px !important;
    }
  }
  .newCodeSent {
    color: ${(props) => props.theme.color.limitedUse.leafyGreen};
    margin-top: 30px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    .resendLink {
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .modal {
      padding-top: 40px;
      overflow-y: hidden;
    }
    .errorModal {
      width: unset;
      height: 100vh;
      border-radius: 0;
      align-items: center;
      gap: 0;
      padding: 104px 24px 32px;
      .cmsErrorMessageLink {
        width: min(95%, 290px);
        margin: 0;
      }
      .hr {
        margin: 40px 0 20px 0;
      }
    }
    .heading {
      font-size: 28px;
      margin-top: ${(props) => (props.isShowCMSErrorMessage ? "0px" : "52px")};
      margin-bottom: 0;
    }
    .enterYourCodeBackButton {
      top: 20px;
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 15px;
    }
    .horizontalDivider {
      margin-top: 40px;
    }
    .callUsText {
      margin-top: 20px;
    }
    .buttonContainer {
      margin-top: 40px;
      button {
        width: 200px !important;
        height: 36px !important;
      }
    }
    .resendProfilePageOTP {
      font-size: 14px;
    }
    .codeInputStyle {
      width: 32px !important;
      height: 44px;
    }
    .phone-verified {
      margin-bottom: 40px;
    }
  }
`;

export const OTPErrorMessage = styled.span`
  color: ${(props) => props.theme.color.primary.tongueTorchRed};
  font-family: ${(props) => props.theme.font.sourceSansPro};
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-top: 10px;
  margin-left: 5px;
  padding-bottom: 1px;
  display: block;
  text-transform: uppercase;
  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    font-size: 10px;
  }
`;
