import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_TESTIMONIAL } from "api/endpoints";
import { FranchiseTestimonialCMSData } from "types/cms/franchise-testimonials";

export const getFranchiseTestimonialCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_TESTIMONIAL,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseTestimonialCMSData>>;
};
