import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_BLOGS, CMS_FRANCHISE_BLOG_SINGLE_ITEM } from "api/endpoints";
import { FranchiseBlogAttributes, FranchiseBlogsCMSData } from "types/cms/franchise-blogs";

export const getFranchiseBlogsCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_BLOGS,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseBlogsCMSData>>;
};

export const getBlogsByUrl = (url: string) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${CMS_FRANCHISE_BLOG_SINGLE_ITEM}/${url}`,
  }) as Promise<CommonLayerResponseDataWithType<{ data: [{ id: number; attributes: FranchiseBlogAttributes }] }>>; // Changed the response type based on the Strapi v4 Response format
};
