import { PropsWithChildren, forwardRef } from "react";
import Image from "next/image";
import { SmartyStreet } from "src/types/types";
import { IC_LOCATION_PIN_WHITE } from "assets/images";
import { AddressSuggestionsStyled } from "./AddressSuggestionsStyled";

interface Props {
  query: string;
  suggestions: SmartyStreet[];
  onSelect?: (street: SmartyStreet) => void;
}

const AddressSuggestions = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({ children, suggestions, query, onSelect }, ref) => {
  const hightlightText = (text: string) => {
    const regex = new RegExp(query, "gi");
    const highlightedText = text.replace(/(<strong class="highlight">|<\/strong>)/gim, "");
    return highlightedText.replace(regex, '<strong class="highlight">$&</strong>');
  };

  return (
    <AddressSuggestionsStyled suggestionsNo={suggestions?.length} ref={ref}>
      {children}
      <div className={suggestions?.length ? "addressContainer" : "d-none"}>
        {suggestions?.length > 0 &&
          suggestions?.map((item: SmartyStreet, index: number) => {
            return (
              <li className="addressItem" onClick={() => onSelect && onSelect(item)} key={index}>
                <Image src={IC_LOCATION_PIN_WHITE} alt="home" width={16} height={23} />
                <div>
                  <span
                    className="addressOneSmarty"
                    dangerouslySetInnerHTML={{
                      __html: `${hightlightText(item.addressLineOne)}`,
                    }}
                  />
                  <br /> {`${item.city}, ${item.state}`}
                </div>
              </li>
            );
          })}
      </div>
    </AddressSuggestionsStyled>
  );
});

AddressSuggestions.displayName = "AddressSuggestion";

export default AddressSuggestions;
