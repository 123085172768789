/* eslint-disable no-case-declarations */
import { GiftCard } from "src/types/types";
import {
  GET_ALL_GIFT_CARDS,
  APPLY_GIFT_CARD_BALANCE,
  SELECTED_GIFT_CARD,
  USE_GIFT_CARD_LATER,
  PREVIOUS_SELECTED_GIFT_CARD,
  DEFAULT_GIFT_CARD_FLAG,
  GIFT_CARD_PAYMENT,
  RESET_GIFT_CARD_PAYMENT,
  SET_IS_GIFT_CARDS_AVAILABLE,
  SET_IS_SHOW_USE_GIFT_CARD,
} from "./GiftCardsConst";

let index: number = 0;
const initialState = {
  giftCards: [],
  applyBalanceAmt: 0,
  selectedCards: [],
  useGiftCardLater: false,
  previousSelectedGiftCard: [],
  defaultGiftCard: false,
  paymentGiftCards: [],
  isGiftCardAvailable: false,
  showUseGiftCard: true,
};

function GiftCardReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case GET_ALL_GIFT_CARDS:
      return {
        ...state,
        giftCards: action.payload,
      };
    case APPLY_GIFT_CARD_BALANCE:
      return {
        ...state,
        applyBalanceAmt: action.payload,
      };
    case SELECTED_GIFT_CARD:
      if (action.payload?.isSinglePayment) {
        return {
          ...state,
          selectedCards: Object.keys(action.payload?.data).length > 0 ? [action.payload?.data].flat() : [],
        };
      } else {
        const cards: any = state.selectedCards || [];
        index = cards?.findIndex((item: GiftCard) =>
          item.isNewCard ? item.cardNumber === action.payload.data.cardNumber : item.accountId === action.payload.data.accountId
        );
        if (index !== -1) {
          cards?.splice(index, 1);
        } else {
          cards?.push(action.payload.data);
        }
        return {
          ...state,
          selectedCards: cards.flat(),
        };
      }

    case PREVIOUS_SELECTED_GIFT_CARD:
      return {
        ...state,
        previousSelectedGiftCard: action.payload,
      };
    case USE_GIFT_CARD_LATER:
      return {
        ...state,
        useGiftCardLater: action.payload,
      };
    case DEFAULT_GIFT_CARD_FLAG:
      return {
        ...state,
        defaultGiftCard: action.payload,
      };
    case GIFT_CARD_PAYMENT:
      return {
        ...state,
        paymentGiftCards: action.payload,
      };
    case RESET_GIFT_CARD_PAYMENT:
      return {
        ...state,
        giftCards: [],
        applyBalanceAmt: 0,
        selectedCards: [],
        useGiftCardLater: false,
        previousSelectedGiftCard: [],
        defaultGiftCard: false,
        paymentGiftCards: [],
      };
    case SET_IS_GIFT_CARDS_AVAILABLE:
      return {
        ...state,
        isGiftCardAvailable: action.payload,
      };
    case SET_IS_SHOW_USE_GIFT_CARD:
      return {
        ...state,
        showUseGiftCard: action.payload,
      };
    default:
      return state;
  }
}

export default GiftCardReducer;
