import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CONFIRM_PASSWORD_VERIFICATION_CODE } from "../endpoints";

interface Request {
  username: string;
  code: string;
  password: string;
}

interface Response {
  message: string;
}

export const confirmPasswordVerificationCode = async (data: Request) => {
  return apiCall({
    requestType: "POST",
    apiEndPoint: CONFIRM_PASSWORD_VERIFICATION_CODE,
    apiPostData: {
      username: data.username,
      code: data.code,
      password: data.password,
    },
  }) as Promise<CommonLayerResponseDataWithType<Response>>;
};
