import secureLocalStorage from "react-secure-storage";
import { USERS } from "../api/endpoints";
import { getTokensForAPICall } from "../api/utils";
import { get, post, del, put } from "./baseAPI";
import { SOMETHING_WENT_WRONG } from "./const";
import { isWebTokenSoonExpire } from "./generateTimeBasedTokenForWeb";
import refreshToken, { refreshTokenExpired } from "./refreshToken";
import { RequestPayload, ApiResponse, ManageResponse, ResponseData } from "./types";

export const isBrowser = () => typeof window !== "undefined";

let isTokenRefreshInProgress: boolean = false;

const wrapWithDefaultErrorMessage = (response: ApiResponse) => {
  return response.data.apiError ? response.data.apiError.message : response.data.message || SOMETHING_WENT_WRONG;
};

/**
 * Function which initiates API call and call child functions as we request type.
 **/
export const apiCall = async ({ requestType, ...props }: RequestPayload) => {
  let response: ResponseData = {};
  if (isBrowser()) {
    // check is token soon expire for web
    const isWebTokenSoonExpired = (await isWebTokenSoonExpire()) as boolean;
    if (isWebTokenSoonExpired) {
      const stringTokens = `${secureLocalStorage.getItem("tokens")}` || sessionStorage.getItem("tokens");
      const tokens = stringTokens && JSON.parse(stringTokens);
      if (props.token) {
        props.token = tokens?.idToken;
      }
      if (requestType === "POST") {
        if (props.apiPostData && props.apiPostData.accessToken && tokens?.accessToken) {
          props.apiPostData.accessToken = tokens.accessToken;
        }
        if (props.apiPostData && props.apiPostData.idToken && tokens?.idToken) {
          props.apiPostData.idToken = tokens.idToken;
        }
        if (props.apiPostData && props.apiPostData.refreshToken && tokens?.idToken) {
          props.apiPostData.refreshToken = tokens.refreshToken;
        }
      }
    }
  }

  switch (requestType) {
    case "GET":
      response = await getApiCall({ requestType, ...props });
      break;
    case "POST":
      response = await postApiCall({ requestType, ...props });
      break;
    case "DELETE":
      response = await deleteApiCall({ requestType, ...props });
      break;
    case "PUT":
      response = await putApiCall({ requestType, ...props });
      break;
    default:
      response = await getApiCall({ requestType, ...props });
  }

  return response;
};

/**
 * Function to manage response of API, which includes mapping response data in specific format, retry mechanism.
 **/
const manageAPIResponse = async ({
  apiEndPoint,
  requestType,
  apiResponse,
  responseMapFunction,
  paramToPassInMapFunction,
  callback,
  ...props
}: ManageResponse) => {
  let { currentRetry, retry } = props;
  if (apiResponse.success) {
    const resData = responseMapFunction
      ? responseMapFunction(apiResponse.response, paramToPassInMapFunction || undefined)
      : apiResponse.response;
    if (callback) callback(resData, apiResponse, paramToPassInMapFunction);
    return { ...apiResponse, response: resData };
  } else if (retry) {
    currentRetry = (currentRetry || 1) + 1;
    if (currentRetry <= retry) {
      props.currentRetry = currentRetry;
      props.retry = retry;
      return apiCall({ apiEndPoint, requestType, ...props });
    } else {
      return apiResponse;
    }
  } else if (apiResponse?.error?.errorCode === 401 && !isTokenRefreshInProgress) {
    isTokenRefreshInProgress = true;
    const tokens = (await getTokensForAPICall()) as Record<string, unknown>;
    // refresh token API Call
    const result = await apiCall({
      apiEndPoint: `${USERS}/regenerate-tokens`,
      requestType: "POST",
      apiPostData: {
        refreshToken: tokens?.refreshToken,
      },
    });
    // if success, request the same API with updated token
    if (result.success && (result?.response?.authenticationResult?.idToken || result?.response?.idToken)) {
      props.currentRetry = 1;
      props.retry = 1;
      refreshToken(result?.response?.authenticationResult);
      if (props.apiPostData && props.apiPostData.accessToken) {
        props.apiPostData.accessToken = result?.response?.authenticationResult?.accessToken || result?.response?.accessToken;
      }
      if (props.apiPostData && props.apiPostData.idToken) {
        props.apiPostData.idToken = result?.response?.authenticationResult.idToken || result?.response?.idToken;
      }
      if (props.apiPostData && props.apiPostData.refreshToken) {
        props.apiPostData.refreshToken = result?.response?.authenticationResult.refreshToken || result?.response?.refreshToken;
      }

      isTokenRefreshInProgress = false;
      return apiCall({
        apiEndPoint,
        requestType,
        ...props,
        token: result?.response?.authenticationResult?.idToken || result?.response?.idToken,
      });
    } else {
      isTokenRefreshInProgress = false;
      refreshTokenExpired();
      return apiResponse;
    }
  } else {
    return apiResponse;
  }
};

/**
 ** Get type of API call will go through this function. This function includes condition to mange succesd and failure.
 **/
export const getApiCall = ({ apiEndPoint, requestType, ...props }: RequestPayload) => {
  return get({ path: apiEndPoint, ...props })
    .then((responseData: ApiResponse) => {
      let apiResponse = {};
      if (responseData.status === 200 || responseData.status === 201) {
        apiResponse = { success: true, response: responseData.data, isLoading: false };
      } else {
        apiResponse = {
          success: false,
          error: {
            errorCode: responseData.status,
            message: wrapWithDefaultErrorMessage(responseData),
            apiError: responseData.data.apiError ? responseData.data.apiError : responseData.data,
            isLoading: false,
          },
        };
      }
      return manageAPIResponse({ apiResponse, apiEndPoint, requestType, ...props });
    })
    .catch((e) => {
      return { success: false, error: { errorCode: e.status, message: e.message, apiError: e } };
    });
};

/**
 * POST type of API call will go through this function. This function includes condition to mange succesd and failure.
 **/
export const postApiCall = async ({ apiEndPoint, apiPostData, requestType, ...props }: RequestPayload) => {
  return post({ path: apiEndPoint, body: apiPostData, ...props })
    .then((responseData: ApiResponse) => {
      let apiResponse = {};
      if (responseData.status === 200 || responseData.status === 201) {
        apiResponse = { success: true, response: responseData.data, isLoading: false };
      } else {
        apiResponse = {
          success: false,
          error: {
            errorCode: responseData.status,
            message: wrapWithDefaultErrorMessage(responseData),
            apiError: responseData.data.apiError ? responseData.data.apiError : responseData.data,
            isLoading: false,
          },
        };
      }
      return manageAPIResponse({ apiResponse, apiEndPoint, requestType, apiPostData, ...props });
    })
    .catch((e) => {
      return { success: false, error: { errorCode: e.status, message: e.message, apiError: e } };
    });
};

/**
 * DELETE type of API call will go through this function. This function includes condition to mange succesd and failure.
 **/
export const deleteApiCall = ({ apiEndPoint, requestType, ...props }: RequestPayload) => {
  return del({ path: apiEndPoint, ...props })
    .then((responseData: ApiResponse) => {
      let apiResponse = {};
      if (responseData.status === 200 || responseData.status === 201) {
        apiResponse = { success: true, response: responseData.data, isLoading: false };
      } else {
        apiResponse = {
          success: false,
          error: {
            errorCode: responseData.status,
            message: wrapWithDefaultErrorMessage(responseData),
            apiError: responseData.data.apiError ? responseData.data.apiError : responseData.data,
            isLoading: false,
          },
        };
      }
      return manageAPIResponse({ apiResponse, apiEndPoint, requestType, ...props });
    })
    .catch((e) => {
      return { success: false, error: { errorCode: e.status, message: e.message, apiError: e } };
    });
};

/**
 * PUT type of API call will go through this function. This function includes condition to mange succesd and failure.
 **/
export const putApiCall = ({ apiEndPoint, apiPostData, requestType, ...props }: RequestPayload) => {
  return put({ path: apiEndPoint, body: apiPostData, ...props })
    .then((responseData: ApiResponse) => {
      let apiResponse = {};
      if (responseData.status === 200 || responseData.status === 201) {
        apiResponse = { success: true, response: responseData.data, isLoading: false };
      } else {
        apiResponse = {
          success: false,
          error: {
            errorCode: responseData.status,
            message: wrapWithDefaultErrorMessage(responseData),
            apiError: responseData.data.apiError ? responseData.data.apiError : responseData.data,
            isLoading: false,
          },
        };
      }
      return manageAPIResponse({ apiResponse, apiEndPoint, requestType, ...props });
    })
    .catch((e) => {
      return { success: false, error: { errorCode: e.status, message: e.message, apiError: e } };
    });
};
