export const getWebPXHeaders = () => {
  try {
    const pxCookieHeaderValue = document.cookie.split(/;\s?/).reduce((a, b) => {
      if (b.startsWith("_px")) {
        a += `${b};`;
      }
      return a;
    }, "");

    return { "x-px-cookies": pxCookieHeaderValue };
  } catch (error) {
    return {};
  }
};
