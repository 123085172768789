import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { computeStraightLineDistanceInMiles } from "utils/compute";
import {
  setDeliveryAvailableStores,
  setFavouriteStore,
  setFromUserSavedCard,
  setSelectedStore,
  setStoreLocation,
  setUserLocation,
  setFavouriteStoreList,
} from "redux/reducer/Store/actions";
import { ANALYTICS_CONST } from "constants/analytics";
import { getStoreForDelivery } from "utils/helper";
import { urlTrim } from "utils/cleaning";
import Analytics from "analytics/Analytics";
import { Address } from "src/types/types";
import { setFavoriteAddress, setUserAddress, setUserEnteredDeliveryAddress } from "redux/reducer/Address/AddressAction";
import { setLocationStep } from "redux/reducer/Location/LocationAction";
import { setDoubleCheckDateTimeButtonClicked } from "redux/reducer/UserStatus/UserStatusAction";
import LocationCardTile from "../LocationCardTile/LocationCardTile";

interface Props {
  address: Address;
  isFavourite: boolean;
  isDeliveryAddress?: boolean;
}

const LocationDetailTile = ({ address, isFavourite, isDeliveryAddress = false }: Props) => {
  const dispatch = useDispatch();
  const { userLocation, addressList, pickupStoreDetails, details, tokens, orderType, favouriteStoreList } = useSelector((state: any) => ({
    userLocation: state?.store?.userLocation,
    addressList: state?.address?.addressList,
    pickupStoreDetails: state?.store?.pickupStoreDetails,
    details: state.user.details,
    tokens: state.user.tokens,
    orderType: state.location.orderType,
    favouriteStoreList: state?.store?.favouriteStoreList,
  }));

  const router = useRouter();

  const [distance, setDistance] = useState<number>(0);

  const idToken = tokens?.idToken || "";
  const externalId = details?.externalId || "";
  const addresId = address?.addressId;
  const userLocationLat = userLocation?.lat?.toString();
  const userLocationLng = userLocation?.lng?.toString();

  const isDelivery = orderType === "DELIVERY";

  const toggleFavourite = () => {
    if (isDelivery) {
      dispatch(
        setFavoriteAddress({
          idToken: idToken,
          externalId: externalId,
          addressId: addresId,
          favorite: !address.favorite,
        })
      );
    } else {
      const favStoreList = favouriteStoreList?.map((favStore: any) => ({
        ...favStore,
        default: Number(favStore?.storeId) === Number(addresId) ? !address.favorite : false,
      }));
      dispatch(
        setFavouriteStore({
          idToken: idToken,
          externalId: externalId,
          storeId: addresId,
          default: !address.favorite,
        })
      );
      dispatch(setFavouriteStoreList(favStoreList));
    }
  };

  useEffect(() => {
    if (addresId) {
      if (address.variant === "pickup" && userLocationLat && userLocationLng) {
        const x = {
          lat: parseFloat(userLocationLat),
          lng: parseFloat(userLocationLng),
        };

        const y = {
          lat: address.latitude,
          lng: address.longitude,
        };

        const miles = computeStraightLineDistanceInMiles(x, y);
        const floor = Math.floor(miles);
        setDistance(floor);
      }
    }
  }, [addresId, distance, userLocationLat, userLocationLng]);

  useEffect(() => {
    if (pickupStoreDetails) {
      dispatch(setLocationStep("ADDRESS_PICKUP"));
    }
  }, [pickupStoreDetails]);

  const handleSelect = (addressDetail: any) => {
    dispatch(setFromUserSavedCard(true));
    dispatch(setDoubleCheckDateTimeButtonClicked(false));
    if (!isDelivery) {
      dispatch(setSelectedStore(0));
      const latLongPayload = {
        lat: parseFloat(`${addressDetail?.latitude}`),
        lng: parseFloat(`${addressDetail?.longitude}`),
      };
      dispatch(setStoreLocation(latLongPayload));
    } else if (isDelivery) {
      const selectedAddress = addressList.find((item: any) => item.id === +addressDetail?.addressId);

      const latLongPayload = {
        lat: parseFloat(selectedAddress?.latitude),
        lng: parseFloat(selectedAddress?.longitude),
      };

      dispatch(setUserEnteredDeliveryAddress(latLongPayload));
      dispatch(setStoreLocation(latLongPayload));

      const storeForDeliveryAPIPayload = {
        addressLineOne: selectedAddress?.addressLineOne,
        addressLineTwo: selectedAddress?.addressLineTwo,
        city: selectedAddress?.city,
        zip: selectedAddress?.zip,
      };
      dispatch(
        setUserAddress({
          ...storeForDeliveryAPIPayload,
          addressType: selectedAddress?.addressType,
          state: selectedAddress?.state,
          latitude: latLongPayload.lat,
          longitude: latLongPayload.lng,
          favorite: selectedAddress?.favorite,
          notes: selectedAddress?.notes,
          company: selectedAddress?.company,
          id: selectedAddress?.id,
        })
      );

      getStoreForDelivery(storeForDeliveryAPIPayload).then((res) => {
        if (res.success) {
          const deliveryStoreDetails = [res.response];
          dispatch(setDeliveryAvailableStores(deliveryStoreDetails));
          dispatch(setLocationStep("ADDRESS_DELIVERY_CARD"));
        } else {
          if (res.error?.apiError?.status === "NOT_FOUND") {
            dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
          }
        }
      });
    }
  };

  const handleStoreDetails = () => {
    // Added store detail call
    Analytics.getInstance().logEvent(ANALYTICS_CONST.STORE.STORE_DETAIL_CALL);
    dispatch(setUserLocation({}));

    if (!isDelivery) {
      router.push(
        {
          pathname: "/locations/[state]/[city]/[address]",
          query: { storeId: address?.addressId },
        },

        `/locations/${urlTrim(address.state)}/${urlTrim(address.city)}/${urlTrim(address.addressLineOne)}`
      );
    }
  };

  return (
    <LocationCardTile
      {...{
        distance,
        isFavourite,
        handleToggleFavourite: toggleFavourite,
        address,
        handleSelect,
        isDeliveryAddress,
        handleStoreDetails,
      }}
      showAddressTime
      showFavouriteIcon
      showSelectButton
    />
  );
};

export default LocationDetailTile;
