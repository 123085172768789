import Image from "next/image";
import { ANALYTICS_CONST } from "constants/analytics";
import Analytics from "analytics/Analytics";
import { IC_APP_STORE, IC_GOOGLE_PLAY } from "assets/images";
import Link from "components/atomic-components/atoms/typography/Links/Links";
import { AppStoreLogosStyled, AppStoreIconContainer } from "./AppStoreLogosStyled";

interface Props {
  appStoreLink: string;
  playStoreLink: string;
  className?: string;
}

const AppStoreLogos = ({ appStoreLink, playStoreLink, className }: Props) => {
  const handleAppleStoreClick = () => {
    // Added apple_app_store event
    Analytics.getInstance().logEvent(ANALYTICS_CONST.DOWNLOAD.APPLE_APP_STORE);
  };
  const handleGoogleStoreClick = () => {
    // Added google_app_store event
    Analytics.getInstance().logEvent(ANALYTICS_CONST.DOWNLOAD.GOOGLE_APP_STORE);
  };

  return (
    <AppStoreLogosStyled className={className}>
      <Link to={appStoreLink} target="_blank" rel="noreferrer">
        <AppStoreIconContainer className="AppStoreIconContainer">
          <Image src={IC_APP_STORE} onClick={handleAppleStoreClick} alt="App store icon" layout="fill" />
        </AppStoreIconContainer>
      </Link>
      <Link to={playStoreLink} target="_blank" rel="noreferrer">
        <AppStoreIconContainer className="AppStoreIconContainer">
          <Image src={IC_GOOGLE_PLAY} onClick={handleGoogleStoreClick} alt="Google play icon" layout="fill" />
        </AppStoreIconContainer>
      </Link>
    </AppStoreLogosStyled>
  );
};

export default AppStoreLogos;
