import { VehicleInformationResponse } from "api/users/types";
import {
  ResetAction,
  SetUserTypePayload,
  SetUserTypeAction,
  SetUserDetailsAction,
  SetUserDetailsPayload,
  SetUserTokensAction,
  SetUserTokensPayload,
  SetUserStoresAction,
  SetUserStoresPayload,
  SetUserAddressesAction,
  SetUserAddressesPayload,
  SetUserLoyaltyAvailability,
  SetUserLoyaltyAvailabilityAction,
  SetUpdateUserDetailsAction,
  SetUpdateUserDetailsPayload,
  SetSubscriptionDetailsAction,
  SetSubscriptionDetailsPayload,
  SetGuestUserDetailsPayload,
  SetGuestUserDetailsAction,
  SetSelectedGiftCardTypeAction,
  SetTrackingPermissionTypeAction,
  SetVehicleListAction,
  SetAddVehicleInputErrorAction,
  SetSelectedVehicleAction,
} from "./types";

export const setUserType = (payload: SetUserTypePayload): SetUserTypeAction => ({
  type: "SET_USER_TYPE",
  payload,
});

export const setUserTokens = (payload: SetUserTokensPayload): SetUserTokensAction => ({
  type: "SET_USER_TOKENS",
  payload,
});

export const setUserDetails = (payload: SetUserDetailsPayload): SetUserDetailsAction => ({
  type: "SET_USER_DETAILS",
  payload,
});

export const setUserStores = (payload: SetUserStoresPayload): SetUserStoresAction => ({
  type: "SET_USER_STORES",
  payload,
});

export const setUserAddresses = (payload: SetUserAddressesPayload): SetUserAddressesAction => ({
  type: "SET_USER_ADDRESSES",
  payload,
});

export const setUserLoyaltyAvailability = (payload: SetUserLoyaltyAvailability): SetUserLoyaltyAvailabilityAction => ({
  type: "SET_USER_LOYALTY_AVAILABILITY",
  payload,
});

export const updateUserDetails = (payload: SetUpdateUserDetailsPayload): SetUpdateUserDetailsAction => ({
  type: "UPDATE_USER_DETAILS",
  payload,
});

export const setUserSubscriptionDetails = (payload: SetSubscriptionDetailsPayload): SetSubscriptionDetailsAction => ({
  type: "USER_SUBSCRIPTION",
  payload,
});

export const setSelectedGiftCardType = (data: boolean): SetSelectedGiftCardTypeAction => ({
  type: "SET_SELECTED_GIFT_CARD_TYPE",
  payload: data,
});
export const reset = (): ResetAction => ({
  type: "RESET",
});

export const setGuestUserDetails = (payload: SetGuestUserDetailsPayload): SetGuestUserDetailsAction => ({
  type: "SET_GUEST_USER_DETAILS",
  payload,
});

export const setTrackingPermission = (payload: string): SetTrackingPermissionTypeAction => ({
  type: "SET_TRACKING_PERMISSION_TYPE",
  payload,
});

export const setVehicleList = (payload: any): SetVehicleListAction => ({
  type: "SET_VEHICLE_LIST",
  payload,
});

export const setVehicleAddError = (payload: any): SetAddVehicleInputErrorAction => ({
  type: "SET_ADD_VEHICLE_ITEM_ERROR_MESSAGE",
  payload,
});

export const setVehicleListErrorMessage = (payload: string | undefined) => ({
  type: "SET_VEHICLE_LIST_ERROR_MESSAGE",
  payload,
});

export const setSelectedVehicle = (payload: VehicleInformationResponse): SetSelectedVehicleAction => ({
  type: "SET_SELECTED_VEHICLE",
  payload,
});
