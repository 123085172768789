import { css } from "styled-components";
import {
  IC_APPLE_PAY,
  IC_GOOGLE_PAY,
  IC_REBRAND_AMEX_CARD,
  IC_REBRAND_MASTER_CARD,
  IC_REBRAND_VISA_CARD,
  IC_REBRAND_DISCOVER_CARD,
} from "assets/images";
import { CreditCardType, GiftCard, PaymentGiftCard } from "src/types/types";
import { ANALYTICS_CONST } from "constants/analytics";
import { applePayBrowserSupport, canMakeApplePayPayments, canMakeGooglePayPayment } from "utils/payment";

export const formatCardNumber = (EgiftNumber: number) => EgiftNumber?.toString().replace(/(.{4})/g, "$1 ");

export const isApplepayButtonShouldShow = canMakeApplePayPayments() || applePayBrowserSupport();
export const isGooglePayButtonShouldShow = canMakeGooglePayPayment() ?? false;

export const findNextActiveIndexOnDelete = (currentIndex: number, arrayLength: number = 0) => {
  if (arrayLength <= 1) {
    return 0;
  }
  if (currentIndex >= arrayLength - 1) {
    return currentIndex - 1;
  }
  return currentIndex;
};

export const sortAndFilterTheGiftCards = (giftCards: Array<GiftCard>) => {
  if (!giftCards || giftCards?.length < 1) return [];

  // Sort Based on the balance in ascending order
  return [...giftCards]
    ?.filter((giftCard: GiftCard) => giftCard.balance > 0)
    ?.sort((giftCard1: GiftCard, giftCard2: GiftCard) => Number(giftCard1.balance) - Number(giftCard2.balance));
};

export const sortTheCreditCards = (creditCards: Array<CreditCardType>) => {
  if (!creditCards) return [];
  if (creditCards?.length <= 1) {
    return creditCards;
  }
  // Select the default credit card
  const defaultCreditCard = creditCards?.filter((card: CreditCardType) => card.default);
  // Sort Based on the Expiry Date in ascending order
  const sortCreditCards = creditCards
    ?.filter((card: CreditCardType) => card.accountId !== defaultCreditCard[0]?.accountId)
    ?.map((cards: CreditCardType) => {
      return {
        ...cards,
        date: new Date(2000 + parseInt(cards.expiryYear.slice(-2)), parseInt(cards.expiryMonth)),
      };
    })
    ?.sort((creditCard1, creditCard2) => {
      if (Number(creditCard1.date) !== Number(creditCard2.date)) {
        return Number(creditCard1.date) - Number(creditCard2.date);
      }

      if (creditCard1.isNewCard && creditCard2.isNewCard) {
        return 0;
      }

      return creditCard1.isNewCard ? 1 : -1;
    });

  return [...defaultCreditCard, ...sortCreditCards];
};

export const getCreditCardIcon = (cardType: string | undefined) => {
  if (cardType) {
    const variant = cardType.toLowerCase();
    const cardIcon = {
      visa: IC_REBRAND_VISA_CARD,
      mastercard: IC_REBRAND_MASTER_CARD,
      amex: IC_REBRAND_AMEX_CARD,
      apple: IC_APPLE_PAY,
      google: IC_GOOGLE_PAY,
      discover: IC_REBRAND_DISCOVER_CARD,
    }[variant];

    return { variant, cardIcon };
  }
  return { variant: "", cardIcon: "" };
};

type DefaultCardToFocusProps = {
  giftCards: Array<GiftCard>;
  creditCards: Array<CreditCardType>;
  showApplePay: boolean;
};

export const getDefaultCardWithIndexToFocus = ({ giftCards, creditCards, showApplePay }: DefaultCardToFocusProps) => {
  const sortedGiftCards = sortAndFilterTheGiftCards(giftCards);
  const defaultGiftCard = sortedGiftCards.length > 0 ? [sortedGiftCards[0]] : [];

  const defaultCreditCard = creditCards?.filter((creditCard: any) => creditCard?.default) ?? [];
  const findIndexOfDefaultCreditCard = creditCards?.findIndex(
    (creditCard: any) => creditCard?.accountId === defaultCreditCard[0]?.accountId
  );

  // If the default credit card is present then index will be giftcards length plus apple pay or google pay card
  const cardsIndexIfCardsPresent = showApplePay || isGooglePayButtonShouldShow ? sortedGiftCards.length + 1 : sortedGiftCards.length;
  if (defaultCreditCard.length > 0) return { card: defaultCreditCard, cardIndex: cardsIndexIfCardsPresent + findIndexOfDefaultCreditCard };

  // If the credit card is not present then first gift card in the sorted array will be focus so the index will be always 0
  if (defaultGiftCard.length > 0) return { card: defaultGiftCard, cardIndex: 0 };

  // If the there is no default credit card & no gift cards then the next card will be apple pay or google pay so the index will be 0
  if (showApplePay || isGooglePayButtonShouldShow)
    return { card: { accountId: `${isGooglePayButtonShouldShow ? "google" : "apple"}-pay-1000` }, cardIndex: 0 };

  // If there is no above condition satisfies then return default state
  return { card: { accountId: 0 }, cardIndex: 0 };
};

type activeIndexProps = {
  giftCards: Array<GiftCard>;
  creditCards: Array<CreditCardType>;
  newlyAddedCard: any;
  showApplePay: boolean;
};

export const getActiveIndexBasedonAcountId = ({ giftCards, creditCards, newlyAddedCard, showApplePay }: activeIndexProps) => {
  const sortedGiftCards = sortAndFilterTheGiftCards(giftCards);

  const sortedCreditCards = sortTheCreditCards(creditCards);

  const arrayCards = newlyAddedCard.isGiftCard ? sortedGiftCards : sortedCreditCards;
  const findIndexBasedonAccountId = arrayCards.findIndex((arrayCard) => arrayCard.accountId === newlyAddedCard.accountId);

  const cardsIndexIfCardsPresent = showApplePay || isGooglePayButtonShouldShow ? sortedGiftCards.length + 1 : sortedGiftCards.length;
  return newlyAddedCard.isGiftCard ? findIndexBasedonAccountId : cardsIndexIfCardsPresent + findIndexBasedonAccountId;
};

export const getStringValueFromArrayBasedOnKey = (arrayItems: any, key: any) => {
  return arrayItems.reduce((acc: any, arrayItem: any) => {
    acc += arrayItem[key] ?? new Date().getTime();
    return acc;
  }, "");
};

export const totalSelectedGiftCardsAmount = (selectedCards: any) => {
  return selectedCards.reduce((acc: any, selectedCard: any) => {
    acc += selectedCard.amount;
    return acc;
  }, 0);
};

type MultipleCardsPopupTypes = {
  isSingleCardPayment: boolean;
  totalBasketAmount: number | string;
  paymentGiftCards: Array<PaymentGiftCard>;
  isCreditCardSelected: boolean;
  isPaymentWithApplePaySelected: boolean;
  isPaymentWithGooglePaySelected: boolean;
  isGiftCardClicked: boolean;
};

export const canShowMultipleCardsPopup = ({
  isSingleCardPayment,
  totalBasketAmount,
  paymentGiftCards,
  isCreditCardSelected,
  isPaymentWithApplePaySelected,
  isPaymentWithGooglePaySelected,
  isGiftCardClicked,
}: MultipleCardsPopupTypes) => {
  if (isSingleCardPayment) return false;
  const hasCardsSelected = isCreditCardSelected || isPaymentWithApplePaySelected || isPaymentWithGooglePaySelected;
  if (hasCardsSelected && isGiftCardClicked) return true;
  if (totalSelectedGiftCardsAmount(paymentGiftCards) >= totalBasketAmount) return true;
  return false;
};

type singleCardPayment = {
  giftCards: Array<GiftCard>;
  totalOrderAmount: number;
  hasCreditCards: boolean;
};

export const checkIsSingleCardPayment = ({ giftCards, totalOrderAmount, hasCreditCards }: singleCardPayment) => {
  if (giftCards?.length <= 0) {
    return true;
  } else if (giftCards?.length > 0) {
    // If there are no giftCards then there will be credit cards or Apple Pay or Google Pay so returning true as always
    return giftCards.every((giftCard) => Number(giftCard.balance) >= totalOrderAmount);
  } else if (isApplepayButtonShouldShow || isGooglePayButtonShouldShow || hasCreditCards) {
    return true;
  } else {
    return false;
  }
};

export const removeCardText = (text: string, word: string) => {
  const wordLower = text?.toLowerCase();
  const indexOfCard = wordLower?.lastIndexOf(word);
  if (indexOfCard !== -1 && indexOfCard === wordLower?.length - word?.length) {
    return text.slice(0, indexOfCard).trim();
  } else {
    return text;
  }
};

export const isVehicleObjectEmpty = (obj: any) => {
  return !obj || Object.keys(obj).length === 0;
};

export const applyMediaQuery = (minWidth: any, minWidthValue: any) => css`
  @media only screen and (min-width: ${minWidth}px) {
    min-width: ${minWidthValue}px;
    background-size: ${minWidthValue}px 184px;
  }
`;

const extractErrorCode = (message: string) => {
  const startIndex = message?.indexOf("code '") + 6;
  const endIndex = message?.indexOf("'", startIndex);

  if (startIndex !== -1 && endIndex !== -1) {
    return message?.substring(startIndex, endIndex);
  } else {
    return "Failure code not found";
  }
};

const PAYMENT_TYPE = {
  GOOGLE_PAY: "googlepay",
  APPLE_PAY: "applepay",
};

export const getPaymentFailureInfo = (debugMessage: string, paymentType: string) => {
  if (debugMessage?.includes("gift")) {
    return {
      analyticConstant: ANALYTICS_CONST.ORDER.GIFT_CARD_FAILURE,
      errorCode: extractErrorCode(debugMessage),
      errorMessage: debugMessage,
    };
  } else if (paymentType.includes(PAYMENT_TYPE.GOOGLE_PAY)) {
    return {
      analyticConstant: ANALYTICS_CONST.ORDER.GOOGLE_PAY_FAILURE,
      errorCode: extractErrorCode(debugMessage),
      errorMessage: debugMessage,
    };
  } else if (paymentType.includes(PAYMENT_TYPE.APPLE_PAY)) {
    return {
      analyticConstant: ANALYTICS_CONST.ORDER.APPLE_PAY_FAILURE,
      errorCode: extractErrorCode(debugMessage),
      errorMessage: debugMessage,
    };
  } else if (
    paymentType.toLowerCase().includes("visa") ||
    paymentType.toLowerCase().includes("mastercard") ||
    paymentType.toLowerCase().includes("amex") ||
    paymentType.toLowerCase().includes("discover")
  ) {
    return {
      analyticConstant: ANALYTICS_CONST.ORDER.CREDIT_CARD_FAILURE,
      errorCode: extractErrorCode(debugMessage),
      errorMessage: debugMessage,
      paymentType: paymentType,
    };
  } else {
    return {
      analyticConstant: ANALYTICS_CONST.ORDER.UNCATEGORIZED_ORDER_FAILURE,
      errorCode: extractErrorCode(debugMessage),
      errorMessage: debugMessage,
    };
  }
};

export const compareTwoObjectsAndGetResult = (oldObject: any, newObject: any) => {
  const oldObjectKeys = oldObject ? Object.keys(oldObject) : [];
  const newObjectKeys = newObject ? Object.keys(newObject) : [];
  const combinedObjectKeys = [...oldObjectKeys, ...newObjectKeys];
  const valueKeysChanged: any = {};

  if (!oldObject || !newObject) {
    return valueKeysChanged;
  }

  combinedObjectKeys.forEach((key: any) => {
    if (!oldObject[key] && !newObject[key]) {
      return valueKeysChanged;
    }
    if (oldObject[key] !== newObject[key]) {
      valueKeysChanged[key] = newObject[key];
    }
  });

  return valueKeysChanged;
};
