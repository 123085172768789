import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBasket } from "hooks/basket/useBasket";
import {
  setCorporateStoreId,
  setDeliveryAvailableStores,
  setPickupOrderType,
  setIsStoreAvailable,
  setSelectedStore,
  setSelectedStoreName,
  setStoreLocation,
} from "redux/reducer/Store/actions";
import { saveAddress, updateAddress } from "api/users/save-address";
import { Values } from "containers/Order/modals/AddressModal/types";
import useModal from "hooks/useModal";
import {
  getAddressList,
  setIsAddAdrressOpen,
  setIsEditAdrress,
  setUserAddress,
  setUserAddressAfterUpdate,
  setUserAddressConfirmation,
  setUserAddressFromModal,
  setUserEnteredDeliveryAddress,
} from "redux/reducer/Address/AddressAction";
import { setLocationOrderModal, setLocationStep } from "redux/reducer/Location/LocationAction";
import { resetStoreMenu, setSelectedTabName } from "redux/reducer/Menu/MenuAction";
import { setPresentUserOrderType } from "redux/reducer/UserStatus/UserStatusAction";
import { SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG } from "redux/reducer/UserStatus/UserStatusConst";
import { CommonLayerResponseData, RootState, SmartyStreet, SmartySuggestionsResponse, Suggestions } from "src/types/types";
import { getGoogleGeocode } from "utils/getGoogleGeocode";
import { getStoreForDelivery, getStoreListWithZip } from "utils/helper";
import { getSmartyStreet, validateSmartyStreet } from "utils/smartyStreet/getSmartyStreet";
import { getLocalStorageData, getSessionStorageData, setLocalStorageData, setSessionStorageData } from "utils/storage";
import { ADD_NEW_DELIVERY_ADDRESS } from "constants/constant";

export const useAddressForm = (defaultValues: Values, from: string | undefined, title: string, modalName: string | undefined) => {
  let defaultSuggestionsValue = {
    addressLineOne: defaultValues.addressLineOne,
    description: "",
    addressLineTwo: defaultValues.addressLineTwo,
    area: "",
    city: defaultValues.city,
    state: defaultValues.state,
    country: "",
    zip: defaultValues.zip,
    latitude: defaultValues.latitude,
    longitude: defaultValues.longitude,
    isBusiness: defaultValues.isBusiness,
  };

  if (title === ADD_NEW_DELIVERY_ADDRESS) {
    defaultSuggestionsValue = {
      addressLineOne: "",
      description: "",
      addressLineTwo: "",
      area: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      latitude: 0,
      longitude: 0,
      isBusiness: false,
    };
  }

  const router = useRouter();
  const dispatch = useDispatch();
  const { setModal } = useModal();
  const { transferBasketToAnotherStore } = useBasket();
  const [smartySuggestion, setSmartySuggestion] = useState<SmartyStreet[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<SmartyStreet>(defaultSuggestionsValue);
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [transferBasketActionCalled, setTransferBasketActionCalled] = useState(false);
  const [editFormValue, setEditFormValue] = useState<string | boolean>("");
  const [values, setValues] = useState<Values>({
    addressType: "",
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    state: "",
    zip: "",
    favorite: false,
    latitude: 0,
    longitude: 0,
    isBusiness: false,
    notes: "",
    id: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const [first, setfirst] = useState("");
  useEffect(() => {
    setfirst(defaultValues.addressLineOne);
  }, []);

  const [errors, setErrors] = useState({
    addressLineOne: "",
    city: "",
    state: "",
    zip: "",
  });

  const [genericError, setGenericError] = useState("");
  const { addressList, isAuthenticated, details, tokens, fromCheckout, basket, itemsNotTransferred, userAddress, selectedTabName } =
    useSelector((state: RootState) => ({
      addressList: state.address?.addressList,
      details: state.user.details,
      tokens: state.user.tokens,
      isAuthenticated: state.user.isAuthenticated,
      fromCheckout: state.userStatus?.fromCheckout,
      basket: state.basket?.basket,
      itemsNotTransferred: state.basket?.itemsNotTransferred,
      userAddress: state.address?.userAddress,
      selectedTabName: state.menu?.selectedTabName,
    }));

  useEffect(() => {
    let timeoutId: any;
    if (
      editFormValue === values.addressLineOne &&
      values.addressLineOne.length >= 3 &&
      values.addressLineOne !== selectedSuggestion.addressLineOne
    ) {
      timeoutId = setTimeout(() => {
        getSmartyStreet(values.addressLineOne).then((res: SmartySuggestionsResponse) => {
          if (res.success) {
            if (res.response.suggestions && res.response.suggestions.length > 0) {
              const smartyResults = res.response.suggestions.map((object: Suggestions) => {
                return {
                  address: object.street_line,
                  addressLineOne: object.street_line,
                  description: object.street_line,
                  addressLineTwo: object.secondary,
                  area: "",
                  city: object.city,
                  state: object.state,
                  country: "",
                  zip: object.zipcode,
                  latitude: 0,
                  longitude: 0,
                  structured_formatting: {
                    main_text: object.street_line,
                    secondary_text: object.street_line,
                  },
                };
              });
              setSmartySuggestion(smartyResults);
            }
          }
        });
      }, 500);
    } else if (values.addressLineOne?.length === 0) {
      values.addressLineOne = "";
      setSmartySuggestion([]);
      setSelectedSuggestion(defaultSuggestionsValue);
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [values.addressLineOne]);

  useEffect(() => {
    if (title !== ADD_NEW_DELIVERY_ADDRESS && Object.keys(defaultValues).length !== 0) {
      setValues(defaultValues);
    }
  }, [defaultValues, title]);

  useEffect(() => {
    if (selectedSuggestion) {
      setValues((prev) => ({
        ...prev,
        ...selectedSuggestion,
      }));
      const errorResetMap = Object.fromEntries(Object.entries(selectedSuggestion).map(([key]) => [key, ""]));
      setErrors((prev) => ({
        ...prev,
        ...errorResetMap,
      }));
    }
  }, [selectedSuggestion]);

  useEffect(() => {
    if (fromCheckout && transferBasketActionCalled) {
      if (basket && basket.products.length > 0 && itemsNotTransferred.length === 0) {
        router.push("/checkout");
      } else {
        if (basket && basket.products.length > 0 && itemsNotTransferred.length !== 0) {
          router.push("/cart");
        }
        if (itemsNotTransferred.length > basket.products.length) {
          router.push(
            {
              pathname: "/menu",
              query: { storeId: selectedStoreId },
            },
            "/menu"
          );
        }
      }
    }
  }, [itemsNotTransferred, fromCheckout]);

  const handleCleanCustomName = (value: string) => {
    return value
      .replace(/^[^A-Za-z0-9]/, "")
      .replace(/[^A-Za-z0-9\s]*$/, "")
      .slice(0, 24);
  };

  const onOptionClick = (option: SmartyStreet) => {
    setSmartySuggestion([]);
    setSelectedSuggestion(option);
  };

  const handleCleanAddressType = (value: string) => {
    return value;
  };

  const handleCleanCityName = (value: string) => {
    return value.replace(/[^A-Za-z ]+/g, "").slice(0, 24);
  };

  const handleCleanStateName = (value: string) => {
    return value.replace(/[^A-Za-z]+/g, "").slice(0, 2);
  };

  const handleCleanZip = (value: string) => {
    return value.replace(/\D/g, "").slice(0, 5);
  };

  const handleBlur = (name: string, value: string) => {
    setErrors((prev) => ({ ...prev, [name]: value ? "" : "Required Field" }));
  };

  const handleChange = (name: string, value: string | boolean) => {
    setEditFormValue(value);
    if (name === "addressLineOne" && typeof value === "string") {
      defaultValues.addressLineOne = value;
    }
    setValues((prev) => ({ ...prev, [name]: value }));
    setIsLoading(false);
  };

  const myRef = useRef<HTMLInputElement>(null);

  const executeScroll = () => {
    myRef?.current?.scrollIntoView();
  };

  const handleCloseModalAction = () => {
    dispatch(
      getAddressList({
        idToken: tokens.idToken,
        externalId: details.externalId,
      })
    );
    dispatch(setIsAddAdrressOpen(false));
    dispatch(setIsEditAdrress(false));
    dispatch(
      setUserAddress({
        addressLineOne: "",
        addressLineTwo: "",
        city: "",
        state: "",
        zip: "",
        latitude: 0,
        longitude: 0,
        addressType: "",
        isBusiness: false,
      })
    );
  };

  // REVISIT : Need to revisit any type and make it proper

  const saveAddressFromAccount = (actionName: string) => {
    setIsLoading(true);

    const addressFound = addressList.find(
      (item: any) =>
        item.addressLineOne === values.addressLineOne &&
        item.addressLineTwo === values.addressLineTwo &&
        item.zip === values.zip &&
        item.addressType === values.addressType
    );
    if (actionName === "Update") {
      if (!addressFound) {
        const payload = {
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          zip: values.zip,
        };
        getStoreForDelivery(payload).then((res) => {
          if (res.error?.apiError?.status === "NOT_FOUND") {
            dispatch(setUserAddress(values));
            dispatch(setLocationOrderModal(""));
            dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
            router.push(
              {
                pathname: "/locations",
                query: { stepFromAccount: "DELIVERY_OUTSIDE_RADIUS" },
              },
              "/locations"
            );
          } else {
            updateAddress({
              ...values,
              externalId: details?.externalId ?? "",
              idToken: tokens?.idToken ?? "",
            })
              .then((res: any) => {
                if (res?.success) {
                  handleCloseModalAction();
                }
              })
              .catch((err) => {
                setGenericError(err.response.data.message);
              })
              .finally(() => setIsLoading(false));
          }
        });
      }
    } else {
      if (!addressFound) {
        const payload = {
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          zip: values.zip,
        };

        getStoreForDelivery(payload).then((res) => {
          if (res.error?.apiError?.status === "NOT_FOUND") {
            dispatch(setUserAddress(values));
            dispatch(setLocationOrderModal(""));
            dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
            router.push(
              {
                pathname: "/locations",
                query: { stepFromAccount: "DELIVERY_OUTSIDE_RADIUS" },
              },
              "/locations"
            );
          } else {
            dispatch(setUserAddressAfterUpdate());
            saveAddress({
              ...values,
              externalId: details?.externalId ?? "",
              idToken: tokens?.idToken ?? "",
            })
              .then((res: CommonLayerResponseData) => {
                if (res.success) {
                  handleCloseModalAction();
                } else {
                  setGenericError(res.error.message);
                }
              })
              .finally(() => setIsLoading(false));
          }
        });
      }
    }
  };

  const saveAddressDetails = (checkSave = "") => {
    let latitude = 0;
    let longitude = 0;

    setIsLoading(true);

    validateSmartyStreet(values.addressLineOne, values.city, values.state, values.zip)
      .then((res) => {
        if (res.response && res.response.length > 0) {
          const validatedValues = res.response[0];
          values.addressLineOne = validatedValues.delivery_line_1 || values.addressLineOne;
          values.addressLineTwo = validatedValues.delivery_line_2 || values.addressLineTwo;
          values.zip = validatedValues.components.zipcode;
          values.city = validatedValues.components.city_name;
          values.state = validatedValues.components.state_abbreviation;
        }
      })
      .then(() => {
        getGoogleGeocode(
          values.addressLineOne + (values.addressLineTwo ? values.addressLineTwo : "") + values.city + values.state + values.zip
        )
          .then((res) => {
            latitude = res.response?.results[0]?.geometry.location.lat;
            longitude = res.response?.results[0]?.geometry.location.lng;
          })
          .then(() => {
            dispatch(
              setUserEnteredDeliveryAddress({
                lat: latitude,
                lng: longitude,
              })
            );
            dispatch(
              setStoreLocation({
                lat: latitude,
                lng: longitude,
              })
            );
            const payload = {
              addressLineOne: values.addressLineOne,
              addressLineTwo: values.addressLineTwo,
              city: values.city,
              zip: values.zip,
            };
            getStoreForDelivery(payload).then((res) => {
              if (res.error?.apiError?.status === "NOT_FOUND") {
                dispatch(setUserAddress(values));
                dispatch(setLocationOrderModal(""));
                dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
              } else {
                saveAddress({
                  ...values,
                  externalId: details?.externalId ?? "",
                  idToken: tokens?.idToken ?? "",
                })
                  .then((addressResponse: CommonLayerResponseData) => {
                    if (addressResponse.success) {
                      const response = addressResponse.response;
                      values.addressLineOne = response.addressLineOne;
                      values.addressLineTwo = response.addressLineTwo;
                      values.city = response.city;
                      values.state = response.state;
                      values.zip = response.zip;
                    }
                    (modalName === "NEW_ADDRESS" || modalName === "CONFIRM_ADDRESS") &&
                      dispatch(
                        getAddressList({
                          idToken: tokens?.idToken,
                          externalId: details?.externalId,
                        })
                      );
                    if (checkSave === "fromSave") {
                      getStoreListWithZip(values.zip);
                    }
                    dispatch(resetStoreMenu());
                    // landing to menu tab as selected on menu page landing
                    dispatch(setSelectedTabName(selectedTabName || "Menu"));
                    dispatch(setLocationOrderModal(""));
                    dispatch(setUserAddressFromModal(values));
                    dispatch(setPresentUserOrderType("DELIVERY"));
                  })
                  .catch((err) => {
                    setGenericError(err.response.data.message);
                  })
                  .finally(() => setIsLoading(false));
              }
            });
          });
      });
  };

  const getStoreListDetails = () => {
    let latitude = 0;
    let longitude = 0;
    getGoogleGeocode(values.addressLineOne + (values.addressLineTwo ? values.addressLineTwo : "") + values.city + values.state + values.zip)
      .then((res) => {
        if (res.response?.results[0]) {
          latitude = res.response.results[0]?.geometry?.location?.lat;
          longitude = res.response.results[0]?.geometry?.location?.lng;
        }
      })
      .then(() => {
        dispatch(
          setUserEnteredDeliveryAddress({
            lat: latitude,
            lng: longitude,
          })
        );
        dispatch(
          setStoreLocation({
            lat: latitude,
            lng: longitude,
          })
        );
        const payload = {
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          zip: values.zip,
        };
        getStoreForDelivery(payload).then((res) => {
          if (res.error?.apiError?.status === "NOT_FOUND") {
            dispatch(setUserAddressFromModal(values));
            dispatch(setUserAddressConfirmation(false));
            dispatch(setLocationOrderModal("NOT_NEAR_ADDRESS"));
          } else {
            dispatch(setCorporateStoreId(res.response.corporateStoreId));
            dispatch(setIsStoreAvailable(true));
            setModal("");
            dispatch(setLocationOrderModal(""));
            dispatch(
              setSelectedStoreName({
                deliveryType: "DELIVERY",
                storeAddress: values.addressLineOne,
              })
            );
            dispatch(setUserAddressConfirmation(true));
            const deliveryStoreDetails = [res.response];
            const deliverySelectedStoreId = res.response.storeId;
            dispatch(setDeliveryAvailableStores(deliveryStoreDetails));
            setSelectedStoreId(deliverySelectedStoreId);
            dispatch(setSelectedStore(deliverySelectedStoreId));
            if (fromCheckout) {
              transferBasketToAnotherStore({
                storeId: deliverySelectedStoreId,
                basketId: basket?.basketId,
                handoffMode: "delivery",
                address: userAddress,
              }).then((transferBasketResponse: any) => {
                if (transferBasketResponse.success === false) {
                  dispatch({
                    type: "SET_ERROR_MESSAGE_TO_MODAL",
                    params: transferBasketResponse.error,
                  });
                  dispatch({
                    type: "SET_ERROR_MESSAGE_TO_MODAL_FLAG",
                    params: true,
                  });
                }
              });
              setTransferBasketActionCalled(true);
            } else {
              router.push(
                {
                  pathname: "/menu",
                  query: { storeId: deliverySelectedStoreId },
                },
                "/menu"
              );
            }
            dispatch(setPickupOrderType(null));
            dispatch(setPresentUserOrderType("DELIVERY"));
            dispatch(setUserAddressFromModal(values));

            const orderData = {
              presentUserOrderType: "DELIVERY",
              pickupOrderType: null,
              selectedStoreId: deliverySelectedStoreId,
              selectedStoreDetail: {
                deliveryType: "DELIVERY",
                storeAddress: res.response.address,
              },
              userAddress: userAddress,
            };
            if (getLocalStorageData("tokens")) {
              setLocalStorageData("store", JSON.stringify(orderData));
            } else if (getSessionStorageData("tokens")) {
              setSessionStorageData("store", JSON.stringify(orderData));
            }
          }
        });
      });
  };

  const handleDeliverHere = (fromConfirm: boolean) => {
    if (fromConfirm) {
      if (isAuthenticated) {
        validateSmartyStreet(values.addressLineOne, values.city, values.state, values.zip)
          .then((res) => {
            if (res.response && res.response.length > 0) {
              const validatedValues = res.response[0];
              values.addressLineOne = validatedValues.delivery_line_1;
              values.addressLineTwo = validatedValues.delivery_line_2;
              values.zip = validatedValues.components.zipcode;
              values.city = validatedValues.components.city_name;
              values.state = validatedValues.components.state_abbreviation;
            }
          })
          .then(() => {
            if ((title === "CONFIRM THIS DELIVERY" || title === "CONFIRM \n DELIVERY") && values?.id) {
              const payload = {
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                city: values.city,
                zip: values.zip,
              };
              getStoreForDelivery(payload).then((res) => {
                if (res.error?.apiError?.status === "NOT_FOUND") {
                  dispatch(setUserAddress(values));
                  dispatch(setLocationOrderModal(""));
                  dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
                } else {
                  updateAddress({
                    ...values,
                    externalId: details?.externalId ?? "",
                    idToken: tokens?.idToken ?? "",
                  }).then((res: any) => {
                    if (res.success) {
                      getStoreListDetails();
                    } else {
                      setGenericError(res.error.message);
                    }
                  });
                }
              });
            } else {
              const payload = {
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                city: values.city,
                zip: values.zip,
              };
              getStoreForDelivery(payload).then((res) => {
                if (res.error?.apiError?.status === "NOT_FOUND") {
                  dispatch(setUserAddress(values));
                  dispatch(setLocationOrderModal(""));
                  dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
                } else {
                  saveAddress({
                    ...values,
                    externalId: details?.externalId || "",
                    idToken: tokens?.idToken || "",
                  }).then((res: CommonLayerResponseData) => {
                    if (res.success) {
                      getStoreListDetails();
                    } else {
                      setGenericError(res.error.message);
                    }
                  });
                }
              });
            }
          });
      } else {
        validateSmartyStreet(values.addressLineOne, values.city, values.state, values.zip).then((res) => {
          if (res.response && res.response.length > 0) {
            const validatedValues = res.response[0];
            values.addressLineOne = validatedValues.delivery_line_1;
            values.addressLineTwo = validatedValues.delivery_line_2;
            values.zip = validatedValues.components.zipcode;
            values.city = validatedValues.components.city_name;
            values.state = validatedValues.components.state_abbreviation;
            getStoreListDetails();
          } else {
            setGenericError("Invalid Address");
          }
        });
      }
    } else {
      dispatch(setUserAddress(values));
    }
  };

  const handleSave = (fromSave?: string) => {
    if (!isAuthenticated) {
      dispatch({ type: SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG, payload: true });
      setModal("LOGIN");
    } else {
      const errs = {
        addressLineOne: values.addressLineOne ? "" : "Required Field",
        city: values.city ? "" : "Required Field",
        state: values.state ? "" : "Required Field",
        zip: values.zip ? "" : "Required Field",
      };
      const errorExists = Object.values(errs).some((x) => x);
      setErrors(errs);
      if (errorExists) {
        executeScroll();
        return;
      }
      const actionName = title !== ADD_NEW_DELIVERY_ADDRESS ? "Update" : "";
      if (from !== "account") {
        saveAddressDetails(fromSave);
      } else {
        saveAddressFromAccount(actionName);
      }
    }
  };

  const closeModal = () => {
    defaultValues.addressLineOne = first;
    dispatch(setLocationOrderModal(""));
  };

  return {
    isLoading,
    values,
    myRef,
    errors,
    genericError,
    handleBlur,
    handleCleanCustomName,
    handleCleanAddressType,
    handleCleanCityName,
    handleCleanStateName,
    handleCleanZip,
    handleChange,
    handleSave,
    smartySuggestion,
    selectedSuggestion,
    setSelectedSuggestion,
    onOptionClick,
    handleDeliverHere,
    closeModal,
  };
};
