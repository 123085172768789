import { useDispatch } from "react-redux";
import { BASKET, USERS } from "src/api/endpoints";
import { apiCall } from "src/apis/api";
import {
  SingleProductRequest,
  BasketAddressRequest,
  BasketHandOffRequest,
  UpsellItemsRequest,
  PlaceOrderRequest,
  CreateBasketRequest,
  TransferBasket,
  TransferBasketResponse,
  CreateUserFavBasket,
  DeleteUserFavoriteBasket,
  UpdateFaveBasketRequest,
  UpdateBasketProduct,
  BasketTipAmountRequest,
  VehicleInfoRequest,
} from "src/api/baskets/types";
import {
  basketBeginLoading,
  basketCloseLoading,
  createBasket,
  createFavBasket,
  createFavBasketError,
  deleteUserFavBasket,
  itemsNotAvailableForHandoffMode,
  itemsNotTransferred,
  removeUpSellItem,
  saveFavBasket,
  setBasketDetails,
  setFavBasketList,
  setProductTime,
  setTime,
  setUpSellItem,
  submitOrderStatus,
  validateBasket,
  basketError,
} from "redux/reducer/basket/actions";
import { UpdateQuantityRequest, ResponseData } from "src/apis/types";
import { SOMETHING_WENT_WRONG } from "constants/constant";
import { useIdToken } from "../selectors/useIdToken";

export const useBasket = () => {
  const dispatch = useDispatch();
  const token = useIdToken();

  const errorMessage = (data: ResponseData) => {
    dispatch({ type: "SET_ERROR_MESSAGE_TO_MODAL", payload: data.error?.message });
    dispatch({ type: "SET_ERROR_MESSAGE_TO_MODAL_FLAG", payload: true });
    dispatch(basketError(data.error?.message ? data.error.message : SOMETHING_WENT_WRONG));
  };

  const setBasketResponse = (data: ResponseData) => {
    dispatch(basketCloseLoading());
    if (data.success) {
      dispatch(setBasketDetails(data.response));
    } else {
      errorMessage(data);
    }
    return data;
  };

  const getBasketDetails = async (basketId: string | number) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "GET",
      apiEndPoint: `${BASKET}/${basketId}`,
    };

    return apiCall(apiDetails).then((data) => setBasketResponse(data));
  };

  const addSingleBasketItem = async (basketId: string, body: SingleProductRequest) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "POST",
      apiEndPoint: `${BASKET}/${basketId}/products/batch`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(setTime(3));
        dispatch(setProductTime(3));
        dispatch(setBasketDetails(data.response));
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const updateSingleBasketItemQuantity = async (basketId: number | string, basketProductId: number, body: UpdateQuantityRequest) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "PUT",
      apiEndPoint: `${BASKET}/${basketId}/products/${basketProductId}/quantity`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(setBasketDetails(data.response));
        dispatch(setProductTime(3));
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const updateVehicleInfoInBasket = async (basketId: string, body: VehicleInfoRequest) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "PUT",
      apiEndPoint: `${BASKET}/${basketId}/attributes`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(setBasketDetails(data.response));
      }
      return data;
    });
  };

  const updateSingleBasketItem = async (basketId: string, basketProductId: string, body: UpdateQuantityRequest) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "PUT",
      apiEndPoint: `${BASKET}/${basketId}/products/${basketProductId}`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => setBasketResponse(data));
  };

  const deleteSingleItemFromBasket = async (basketId: string, basketProductId: number, productId: number) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "DELETE",
      apiEndPoint: `${BASKET}/${basketId}/products/${basketProductId}`,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(removeUpSellItem(productId));
        getBasketDetails(basketId);
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const addUpsellItems = async (basketId: string, body: UpsellItemsRequest) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "POST",
      apiEndPoint: `${BASKET}/${basketId}/upsell`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(setTime(3));
        getBasketDetails(basketId);
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const getUpsellItems = async (basketId: string, customizableProductsAllowed: boolean = false) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "GET",
      apiEndPoint: `${BASKET}/${basketId}/upsell${customizableProductsAllowed ? "?customizableProducts=true" : ""}`,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(setUpSellItem(data.response));
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const getUserFavoriteBaskets = async () => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "GET",
      apiEndPoint: `${USERS}/baskets`,
      isAuth: true,
      token,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(setFavBasketList(data.response));
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const setBasketAddress = async (basketId: string, body: BasketAddressRequest) => {
    const apiDetails = {
      requestType: "PUT",
      apiEndPoint: `${BASKET}/${basketId}/address`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => {
      if (data.success) {
        dispatch(setBasketDetails(data.response));
        setBasketHandOffMode("delivery", basketId);
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const setBasketHandOffMode = async (handOffMode: string, basketId: string) => {
    const body: BasketHandOffRequest = {
      handoffMode: handOffMode,
    };

    const apiDetails = {
      requestType: "PUT",
      apiEndPoint: `${BASKET}/${basketId}/handoff-mode?isValidateBasketForHandoff=true`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => setBasketResponse(data));
  };

  const placeOrder = async (basketId: string, body: PlaceOrderRequest) => {
    const apiDetails = {
      requestType: "POST",
      apiEndPoint: `${BASKET}/${basketId}/place-order`,
      apiPostData: body,
    };
    return apiCall(apiDetails).then((data) => {
      if (data.success) {
        dispatch(submitOrderStatus(data.response?.status));
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const placeOrderStatus = async (basketId: string) => {
    const apiDetails = {
      requestType: "GET",
      apiEndPoint: `${BASKET}/${basketId}/place-order-status`,
    };
    return apiCall(apiDetails).then((data) => {
      if (data.success) {
        dispatch(submitOrderStatus(data.response?.status));
        if (data.response?.status === "order-placed" && data.response?.orderId) {
          dispatch(setBasketDetails(undefined));
          dispatch(setUpSellItem([]));
        }
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const createBasketId = (params: CreateBasketRequest) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      requestType: "POST",
      apiEndPoint: `${BASKET}/create`,
      apiPostData: {
        storeId: params.storeId,
        accessToken: params?.accessToken,
        handoffMode: params?.handoffMode,
        address: params?.address,
      },
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(createBasket(data.response));
      } else {
        dispatch(createBasket(undefined));
        errorMessage(data);
      }
      return data;
    });
  };

  const transferBasketToAnotherStore = (params: TransferBasket) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      apiEndPoint: `${BASKET}/${params.basketId}/transfer?isValidateBasketForHandoff=true`,
      requestType: "POST",
      apiPostData: {
        storeId: params.storeId,
        handoffMode: params?.handoffMode,
        address: params?.address,
      },
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      const responseData: TransferBasketResponse = data.response;
      if (data.success) {
        dispatch(setBasketDetails(responseData.basket));
        dispatch(itemsNotTransferred(responseData.itemsNotTransferred));
        dispatch(itemsNotAvailableForHandoffMode(responseData.itemsNotAvailableForHandoffMode));
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const createUserFavBasket = (params: CreateUserFavBasket) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      apiEndPoint: `${USERS}/baskets`,
      requestType: "POST",
      apiPostData: {
        basketId: params.basketId,
        name: params.name,
        isDefault: params.isDefault,
      },
      isAuth: true,
      token: params?.idToken,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(createFavBasket(data.response?.message ?? ""));
      } else {
        dispatch(saveFavBasket(false));
        dispatch(createFavBasketError(true));
      }
      return data;
    });
  };

  const deleteUserFavoriteBasket = (params: DeleteUserFavoriteBasket) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      apiEndPoint: `${USERS}/baskets/${params.favoriteId}`,
      requestType: "DELETE",
      isAuth: true,
      token: params.idToken,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(deleteUserFavBasket(data.response?.message ?? ""));
      } else {
        errorMessage(data);
      }
      return data;
    });
  };

  const updateFaveBasket = (params: UpdateFaveBasketRequest) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      apiEndPoint: `${USERS}/baskets/${params.favoriteId}`,
      requestType: "PUT",
      apiPostData: {
        name: params.name,
        default: params.default,
      },
      isAuth: true,
      token: params.idToken,
    };
    return apiCall(apiDetails).then((data) => {
      dispatch(basketCloseLoading());
      if (data.success) {
        dispatch(saveFavBasket(true));
        getUserFavoriteBaskets();
      } else {
        dispatch(saveFavBasket(false));
        errorMessage(data);
      }
      return data;
    });
  };

  const updateBasketProduct = (params: UpdateBasketProduct) => {
    dispatch(basketBeginLoading());
    const apiDetails = {
      apiEndPoint: `${BASKET}/${params.basketId}/products/batch/${params.basketProductId}`,
      requestType: "PUT",
      apiPostData: params,
    };
    return apiCall(apiDetails).then((data) => setBasketResponse(data));
  };

  const setTipForDriver = (params: BasketTipAmountRequest) => {
    // Note: use only for web
    dispatch(basketBeginLoading());
    const apiDetails = {
      apiEndPoint: `${BASKET}/${params.basketId}/tip`,
      requestType: "POST",
      apiPostData: {
        tipAmount: params.tipAmount,
      },
    };
    return apiCall(apiDetails).then((data) => setBasketResponse(data));
  };

  const getValidateBasket = (basketId: string) => {
    const apiDetails = {
      apiEndPoint: `${BASKET}/${basketId}/validate`,
      requestType: "GET",
    };
    return apiCall(apiDetails).then((data) => {
      if (data.success) {
        dispatch(validateBasket(data.response));
      } else {
        errorMessage(data);
      }
      getBasketDetails(basketId);
      return data;
    });
  };

  return {
    getBasketDetails,
    addSingleBasketItem,
    updateSingleBasketItemQuantity,
    updateSingleBasketItem,
    deleteSingleItemFromBasket,
    addUpsellItems,
    getUpsellItems,
    getUserFavoriteBaskets,
    setBasketAddress,
    setBasketHandOffMode,
    placeOrder,
    placeOrderStatus,
    createBasketId,
    transferBasketToAnotherStore,
    createUserFavBasket,
    deleteUserFavoriteBasket,
    updateFaveBasket,
    updateBasketProduct,
    setTipForDriver,
    getValidateBasket,
    updateVehicleInfoInBasket,
  };
};
