import styled from "styled-components";

export const ConfirmPasswordStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .changePasswordTitle {
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    font-weight: 800;
    font-size: 40px;
    margin-top: 26px;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      font-size: 28px;
    }
  }
  .inputLabel {
    top: 20px;
    font-weight: 800;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      top: 12px;
      font-weight: 700;
    }
  }

  .buttonSize {
    width: 190px;
    height: 44px;
  }
  .cardBody {
    padding: 50px 10px 20px;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    .input-wrapper {
      width: 294px;
    }
    .buttonSize {
      width: 200px;
      height: 36px;
    }
  }
  justify-content: center;

  & > *:nth-last-child(2) {
    margin-bottom: 10px;
  }
  .textGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .textfieldWrapper {
      .show-icon {
        top: 12px;
        @media only screen and (max-width: ${(props) => props.theme.size.md}) {
          top: 4px;
        }
      }
    }
  }

  .stackedForgotButtons {
    align-items: center;
    width: 390px;
    margin-left: 0;
    margin-top: 22px !important;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      max-width: 200px;
      margin-left: 0;
    }
    .cancelForgotButton {
      height: 44px;
    }
    button {
      width: 100%;
      margin-bottom: 0;
    }
    & > *:first-child {
      margin-right: 0;
    }
  }

  .error {
    text-align: center;
    font-weight: 700;
    font-family: ${(props) => props.theme.font.sourceSansPro};
    text-transform: uppercase;
    max-width: 300px;
  }

  .horizontalDivider {
    border-bottom: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
    width: 294px;
    margin-top: 25px;
    margin-bottom: 20px;
    align-items: center;
  }

  .requiredText {
    width: 392px;
    .strengthIndicator {
      width: 392px;
      .strengthWrapper {
        width: 392px;
      }
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    .textfield {
      width: 294px;
      height: 44px;
      outline: none;
    }
    .textGroup {
      margin-bottom: 20px;
    }
    .error {
      font-size: 10px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    max-width: 300px;
    .textfield {
      width: 294px;
      height: 44px;
    }
    .inputLabel {
      font-size: 16px;
      font-weight: 700;
    }
    .requiredText {
      width: 294px;
      margin-top: 0 !important;
      .strengthIndicator {
        width: 294px;
        .strengthWrapper {
          width: 294px;
        }
      }
    }
    .changePasswordTitle {
      width: 100%;
    }
    .inputWrapper {
      width: 100%;
    }
    .btnWrapper {
      button {
        &:first-child {
          margin-bottom: 0;
          background: transparent;
        }
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  .button-wrapper {
    width: 190px !important;
    height: 44px !important;
    margin-left: 12px !important;
  }
  display: flex;
  flex-direction: row;
`;
