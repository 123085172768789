import { Dispatch } from "redux";
import { FavouriteStoreRequest, SubmitStoreRequest } from "src/api/store/types";
import { Store } from "src/types/types";
import { FILE_DOWNLOADS, STORES, STORE_DATA, USERS, NOTIFICATIONS, CAREERS_STORE_LIST } from "api/endpoints";
import { apiCall } from "../../../apis/api";
import {
  deleteFavouriteStorePayload,
  getCityBasedStoreListPayload,
  getFavouriteStoreAction,
  getFavouriteStorePayload,
  getFilteredStoreListPayload,
  getRecentStoreListPayload,
  getStoreDetailsPayload,
  getStoreListPayload,
  getStoreTimingsPayload,
  resetStoreDetailsAction,
  resetStoreListAction,
  setCorporateStoreIdAction,
  setCorporateStoreIdPayload,
  setDeliveryAvailableStoresAction,
  setDeliveryAvailableStoresPayload,
  setDeliveryLocationCoordinatesAction,
  setDeliveryLocationCoordinatesPayload,
  setDeliveryLocationNameAction,
  setDeliveryLocationNamePayload,
  setDirectionsPayload,
  setFamousMealFlagAction,
  setFamousMealFlagPayload,
  setFavouriteStorePayload,
  setFilteredListAction,
  setFilteredListPayload,
  setFilteredRadiusAction,
  setFilteredRadiusPayload,
  setFilteredStoreListAction,
  setFromStorePageAction,
  setFromStorePagePayload,
  setFromUserSavedCardAction,
  setFromUserSavedCardPayload,
  setIsStoreAvailableAction,
  setIsStoreAvailablePayload,
  SetIsZipSearchAction,
  SetIsZipSearchPayload,
  setNewStoreListAction,
  setPickupLocationNameAction,
  setPickupLocationNamePayload,
  setPickupOrderTypeAction,
  setPickupOrderTypePayload,
  setPickupStoreDetailsAction,
  setPickupStoreDetailsPayload,
  setSelectedStoreAction,
  setSelectedStoreLocationAction,
  setSelectedStoreLocationPayload,
  setSelectedStoreNameAction,
  setSelectedStoreNamePayload,
  setSelectedStorePayload,
  SetStoreCalendarAction,
  SetStoreCalendarPayload,
  setStoreDetailsPinAction,
  setStoreDetailsPinPayload,
  SetStoreIsLoadingAction,
  SetStoreIsLoadingPayload,
  setStoreLocationAction,
  setStoreLocationNameAction,
  setStoreLocationNamePayload,
  setStoreLocationPayload,
  SetUserLocationAction,
  SetUserLocationPayload,
  StoreAction,
} from "./types";

export const setStoreLoader = (isLoading: SetStoreIsLoadingPayload): SetStoreIsLoadingAction => {
  return { type: "SET_STORE_IS_LOADING", payload: isLoading };
};

export const setIsZipSearch = (isZipSearch: SetIsZipSearchPayload): SetIsZipSearchAction => {
  return { type: "SET_IS_ZIP_SEARCH", payload: isZipSearch };
};

export const setUserLocation = (payload: SetUserLocationPayload): SetUserLocationAction => {
  return { type: "SET_USER_LOCATION", payload: payload || false };
};

export const setStoreLocation = (payload: setStoreLocationPayload): setStoreLocationAction => {
  return { type: "SET_STORE_LOCATION", payload: payload || false };
};

export const getStoreList = (payload: getStoreListPayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${STORES}/near?latitude=${payload.lat}&longitude=${payload.lng}`,
      requestType: "GET",
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "SET_STORE_LIST", payload: response.response });
      } else {
        dispatch({ type: "SET_STORE_LIST", payload: [] });
      }
    });
  };
};

export const getCareersStoreList = (payload: getStoreListPayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${CAREERS_STORE_LIST}/near?postal%20code=${payload.zip}&latitude=${payload.lat}&longitude=${payload.lng}`,
      requestType: "GET",
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "SET_STORE_LIST", payload: response.response });
      } else {
        dispatch({ type: "SET_STORE_LIST", payload: [] });
      }
    });
  };
};

export const setNewStoreList = (payload: Store[]): setNewStoreListAction => {
  return { type: "SET_STORE_LIST", payload: payload };
};

export const resetStoreList = (): resetStoreListAction => {
  return { type: "SET_STORE_LIST", payload: [] };
};

export const resetStoreDetail = (): resetStoreDetailsAction => {
  return { type: "SET_STORE_DETAIL", payload: {} };
};

export const getFilteredStoreList = (payload: getFilteredStoreListPayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${STORES}/near?latitude=${payload.lat}&longitude=${payload.lng}&radius=${payload.radius}&delivery=${payload.delivery}&curbside=${payload.curbside}&drivethru=${payload.drivethru}&openlate=${payload.openlate}&freewifi=${payload.freewifi}&cateringmanageronsite=${payload.cateringmanageronsite}&cateringdelivery=${payload.cateringdelivery}`,
      requestType: "GET",
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "SET_STORE_LIST", payload: response.response });
      } else {
        dispatch({ type: "SET_STORE_LIST", payload: [] });
      }
    });
  };
};

export const submitStoreRequest = async (payload: SubmitStoreRequest) => {
  return apiCall({
    apiEndPoint: `${NOTIFICATIONS}/stores/${payload.id}/submit-request`,
    requestType: "POST",
    apiPostData: {
      topic: payload.topic,
      company: payload.company,
      firstName: payload.firstName,
      lastName: payload.lastName,
      addressLineOne: payload.addressLineOne,
      addressLineTwo: payload.addressLineTwo,
      city: payload.city,
      state: payload.state,
      zip: payload.zip,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      bestTimeToContact: payload.bestTimeToContact,
      c3Status: payload.c3Status,
      sponsorshipStartDate: payload.sponsorshipStartDate,
      preferredFundraiserDate: payload.preferredFundraiserDate,
      donationType: payload.donationType,
      donationAmount: payload.donationAmount,
      comments: payload.comments,
    },
    token: payload.idToken,
  });
};

export const setFilteredStoreList = (payload: Store[]): setFilteredStoreListAction => {
  return { type: "SET_FILTERED_STORE_LIST", payload: payload };
};

export const setStoreLocationName = (payload: setStoreLocationNamePayload): setStoreLocationNameAction => {
  return { type: "SET_STORE_LOCATION_NAME", payload: payload };
};

export const setPickupLocationName = (payload: setPickupLocationNamePayload): setPickupLocationNameAction => {
  return { type: "SET_PICKUP_LOCATION_NAME", payload: payload };
};

export const setDeliveryLocationName = (payload: setDeliveryLocationNamePayload): setDeliveryLocationNameAction => {
  return { type: "SET_DELIVERY_LOCATION_NAME", payload: payload };
};

export const setFilteredList = (payload: setFilteredListPayload): setFilteredListAction => {
  return { type: "SET_FILTERED_LIST", payload: payload };
};

export const setFilteredRadius = (payload: setFilteredRadiusPayload): setFilteredRadiusAction => {
  return { type: "SET_FILTERED_RADIUS", payload: payload };
};

export const setFavouriteStoreList = (payload: getFavouriteStorePayload): getFavouriteStoreAction => {
  return { type: "SET_FAVOURITE_STORE_LIST", payload: payload };
};

export const getStoreDetails = (payload: getStoreDetailsPayload, isAddressPickup?: boolean) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({ apiEndPoint: `${STORE_DATA}/${payload}`, requestType: "GET" }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        if (isAddressPickup) {
          dispatch({ type: "SET_PICKUP_STORE_DETAILS", payload: true });
        }
        dispatch({ type: "SET_STORE_DETAIL", payload: response.response });
      } else {
        dispatch({ type: "SET_STORE_DETAIL", payload: {} });
      }
    });
  };
};

export const getStoreTimings = (payload: getStoreTimingsPayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${STORE_DATA}/${payload.id}/calendars?from=${payload.from}&to=${payload.to}`,
      requestType: "GET",
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "SET_STORE_TIMING", payload: response.response });
      } else {
        dispatch({ type: "SET_STORE_TIMING", payload: [] });
      }
    });
  };
};

export const getFavouriteStore = (payload: FavouriteStoreRequest) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${USERS}/stores`,
      requestType: "GET",
      isAuth: true,
      token: payload?.idToken,
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        if (response.response.length > 0) {
          const corporateStoreDetails = response.response.find((x: any) => x.default);
          if (corporateStoreDetails?.corporateStoreId) {
            dispatch(setCorporateStoreId(corporateStoreDetails.corporateStoreId));
          }
        }
        dispatch({ type: "SET_FAVOURITE_STORE_LIST", payload: response.response });
      } else {
        dispatch({ type: "SET_FAVOURITE_STORE_LIST", payload: [] });
      }
    });
  };
};

export const setSelectedStore = (payload: setSelectedStorePayload): setSelectedStoreAction => {
  return { type: "SET_SELECTED_STORE", payload: payload }; // || false getting error
};

export const deleteFavouriteStore = (payload: deleteFavouriteStorePayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${USERS}/stores/${payload.storeId}?user_id=${payload.userId}&store_id=${payload.storeId}`,
      requestType: "DELETE",
      isAuth: true,
      token: payload?.idToken,
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "DELETE_FAVOURITE_STORE", payload: response.response });
      } else {
        dispatch({ type: "DELETE_FAVOURITE_STORE", payload: {} });
      }
    });
  };
};

export const setFavouriteStore = (payload: setFavouriteStorePayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${USERS}/stores`,
      apiPostData: {
        storeId: payload.storeId,
        default: payload.default,
      },
      requestType: "POST",
      isAuth: true,
      token: payload?.idToken,
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "SET_FAVOURITE_STORE", payload: response.response });
      }
    });
  };
};

export const getCityBasedStoreList = (payload: getCityBasedStoreListPayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${STORES}/search?address=${payload.cityName}&startdate=${payload.startdate}`,
      requestType: "GET",
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "SET_STORE_LIST", payload: response.response });
      } else {
        dispatch({ type: "SET_STORE_LIST", payload: [] });
      }
    });
  };
};

export const setSelectedStoreName = (payload: setSelectedStoreNamePayload): setSelectedStoreNameAction => {
  return { type: "SET_SELECTED_STORE_NAME", payload: payload };
};

export const setSelectedStoreLocation = (payload: setSelectedStoreLocationPayload): setSelectedStoreLocationAction => {
  return { type: "SET_SELECTED_STORE_LOCATION", payload: payload || false };
};
export const setDeliveryLocationCoordinates = (payload: setDeliveryLocationCoordinatesPayload): setDeliveryLocationCoordinatesAction => {
  return { type: "SET_DELIVERY_LOCATION_COORDINATES", payload: payload };
};

export const setDirections = (payload: setDirectionsPayload): setDirectionsPayload => {
  return { type: "SET_DIRECTIONS", payload: payload };
};

export const getFilesToDownload = () => {
  return (dispatch: Dispatch<StoreAction>) => {
    apiCall({ apiEndPoint: FILE_DOWNLOADS, requestType: "GET" }).then((response) => {
      if (response.success) {
        dispatch({
          type: "SET_MENU_FILES_PATH",
          payload: response.response.FullMenu.url,
        });
        dispatch({
          type: "SET_CATERINGMENU_FILES_PATH",
          payload: response.response.CateringMenu.url,
        });
      } else {
        dispatch({ type: "SET_MENU_FILES_PATH", payload: "" });
        dispatch({ type: "SET_CATERINGMENU_FILES_PATH", payload: "" });
      }
    });
  };
};

export const setFromStorePage = (payload: setFromStorePagePayload): setFromStorePageAction => {
  return { type: "SET_FROM_STORE_PAGE", payload: payload || false };
};

export const setFromUserSavedCard = (payload: setFromUserSavedCardPayload): setFromUserSavedCardAction => {
  return { type: "SET_FROM_USER_SAVED_CARD", payload: payload || false };
};

export const getRecentStoreList = (payload: getRecentStoreListPayload) => {
  return (dispatch: Dispatch<StoreAction>) => {
    dispatch(setStoreLoader(true));
    apiCall({
      apiEndPoint: `${STORES}/recents`,
      requestType: "POST",
      apiPostData: {
        accessToken: payload,
      },
    }).then((response) => {
      dispatch(setStoreLoader(false));
      if (response.success) {
        dispatch({ type: "RECENT_STORE_LIST", payload: response.response });
      } else {
        dispatch({ type: "RECENT_STORE_LIST", payload: "" });
      }
    });
  };
};

export const setPickupOrderType = (payload: setPickupOrderTypePayload): setPickupOrderTypeAction => {
  return { type: "SET_PICKUP_ORDER_TYPE", payload: payload ?? null };
};

export const setStoreDetailsPin = (payload: setStoreDetailsPinPayload): setStoreDetailsPinAction => {
  return { type: "SHOW_STORE_PIN", payload: payload || null };
};

export const setDeliveryAvailableStores = (payload: setDeliveryAvailableStoresPayload): setDeliveryAvailableStoresAction => {
  return { type: "SET_DELIVERY_AVAILABLE_STORES", payload: payload || [] };
};

export const setPickupStoreDetails = (payload: setPickupStoreDetailsPayload): setPickupStoreDetailsAction => {
  return { type: "SET_PICKUP_STORE_DETAILS", payload: payload };
};

export const setFamousMealFlag = (payload: setFamousMealFlagPayload): setFamousMealFlagAction => {
  return { type: "SET_FAMOUS_MEAL_FLAG", payload: payload };
};

export const setStoresCalendar = (storesCalendarDetails: SetStoreCalendarPayload): SetStoreCalendarAction => ({
  type: "SET_STORE_CALENDAR",
  payload: storesCalendarDetails,
});

export const setIsStoreAvailable = (payload: setIsStoreAvailablePayload): setIsStoreAvailableAction => {
  return { type: "SET_IS_STORE_AVAILABLE", payload: payload };
};

export const setCorporateStoreId = (payload: setCorporateStoreIdPayload): setCorporateStoreIdAction => {
  return { type: "SET_CORPORATE_STORE_ID", payload: payload };
};
