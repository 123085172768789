import {
  SET_MULTIPLE_CARDS_ERROR_POPUP,
  SET_NEWLY_ADDED_CARD,
  SET_IS_SINGLE_CARD_PAYMENT,
  SET_DELETE_CARD_DATA,
  INITIAL_DELETE_CARDS_STATE,
  SET_TRIGGER_INIT_API_CALL,
  SET_MULTIPLE_CARDS_ERROR_POPUP_CREDIT_CARD,
  SET_INIT_API_CALL_PAYLOAD_DATA,
} from "./CheckoutConst";

const initialState = {
  newlyAddedCard: {},
  multipleCardsErrorPopup: false,
  multipleCardsErrorPopupCreditCard: false,
  isSingleCardPayment: false,
  deleteCardsData: INITIAL_DELETE_CARDS_STATE,
  isTriggerInitAPI: false,
  initApiCallPayloadData: {
    initApiCallStartTime: null,
    payload: {
      storeId: null,
      paymentType: "",
      amount: null,
      croCheckout: false,
      styles: "",
      sessionId: null,
    },
  },
};

export const CheckoutReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_NEWLY_ADDED_CARD:
      return { ...state, newlyAddedCard: action.payload };
    case SET_MULTIPLE_CARDS_ERROR_POPUP:
      return { ...state, multipleCardsErrorPopup: action.payload };
    case SET_MULTIPLE_CARDS_ERROR_POPUP_CREDIT_CARD:
      return { ...state, multipleCardsErrorPopupCreditCard: action.payload };
    case SET_IS_SINGLE_CARD_PAYMENT:
      return { ...state, isSingleCardPayment: action.payload };
    case SET_DELETE_CARD_DATA:
      return { ...state, deleteCardsData: action.payload };
    case SET_TRIGGER_INIT_API_CALL:
      return { ...state, isTriggerInitAPI: action.payload };
    case SET_INIT_API_CALL_PAYLOAD_DATA:
      return { ...state, initApiCallPayloadData: action.payload };
    default:
      return state;
  }
};
