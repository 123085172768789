import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_LOCATION_VERIFICATION_CHECKOUT } from "api/endpoints";
import { LocationVerificationCheckoutPopupData } from "types/cms/checkout";

export const getLocationVerificationCheckoutCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_LOCATION_VERIFICATION_CHECKOUT,
  }) as Promise<CommonLayerResponseDataWithType<LocationVerificationCheckoutPopupData>>;
};
