type Signature = {
  Key: string;
  defaultValue?: string;
  lookupField?: string;
  regex?: string;
  targetedDevice?: string;
  applytourl?: string;
};

export const getWebFingerprintValue = (lookupField?: string, defaultValue?: string, email?: string) => {
  const userDetails = localStorage.getItem("userDetails");
  const user = userDetails ? JSON.parse(userDetails) : {};
  const defaultAssignment = defaultValue ?? null;

  switch (lookupField) {
    case "deviceID":
      return sessionStorage.getItem("deviceId");
    case "deviceType":
      return "WEB";
    case "emailAddress": {
      const emailAddress = email || defaultAssignment;
      return user?.email ? user?.email : emailAddress;
    }
    case "firstName":
      return user?.firstName ? user?.firstName : defaultAssignment;
    case "lastName":
      return user?.lastName ? user?.lastName : defaultAssignment;
    case "phone":
      return user?.phone ? user?.phone : defaultAssignment;
    default:
      return defaultAssignment;
  }
};

export const getWebFingerprintingHeaders = async (path: string, email: string) => {
  const signaturesJson = sessionStorage.getItem("signaturesFormat");

  const signaturesFormat: Signature[] = signaturesJson ? JSON.parse(signaturesJson) : [];

  const signatureFilteredHeaders = signaturesFormat.filter((signature) => {
    if (signature?.applytourl && !path.includes(signature?.applytourl)) {
      return false;
    }

    if (signature?.targetedDevice && signature?.targetedDevice?.length > 0) {
      const targetedDevice = Array.isArray(signature.targetedDevice)
        ? signature.targetedDevice.map((value) => value.toUpperCase())
        : signature.targetedDevice.toUpperCase();

      if (!targetedDevice.includes("WEB")) {
        return false;
      }
    }

    if (email) {
      return signature.lookupField === "emailAddress";
    }

    return false;
  });

  const signatureHeaders =
    signatureFilteredHeaders.length > 0
      ? signatureFilteredHeaders.map((signature) => {
          const key = signature.Key;
          const value = getWebFingerprintValue(signature.lookupField, signature.defaultValue, email);

          return {
            [key]: value,
          };
        })
      : [];

  return signatureHeaders.reduce((accumulator, current) => {
    return {
      ...accumulator,
      ...current,
    };
  }, {});
};
