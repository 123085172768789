import styled from "styled-components";

const ForgotPasswordStyled = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;

  .heading {
    position: relative;
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    text-align: center;
    text-transform: uppercase;
    ${(props) => props.theme.responsive?.min.xs} {
      margin: 10px auto;
    }

    ${(props) => props.theme.responsive?.max.md} {
      width: auto;
      font-size: 28px;
      margin: 0 auto 20px;
    }
  }
  .forgotPasswordTextInput {
    padding: 18px 16px 9px 16px !important;
  }
  .border {
    border-bottom: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
    width: 294px;
    margin-top: 10px;
  }

  .information {
    margin-bottom: 32px;
    font-size: 14px;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-weight: 400;
    text-align: center;
    width: 364px;
    height: 44px;
  }

  .input-wrapper {
    width: 392px;
  }

  ${(props) => props.theme.responsive.max.lg} {
    .heading {
      margin: 0 auto 20px;
      width: 294px;
    }
    .information {
      font-size: 16px;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      width: 292px;
      color: #0c2340;
    }
    .input-wrapper {
      width: 294px;
      margin-top: 30px;
    }
    .forgotPasswordTextInput {
      padding: 18px 16px 9px 12px !important;
      font-size: 16px;
      line-height: 19.2px;
      font-family: ${(props) => props.theme.font.barlow};
      font-weight: 500;
      color: ${(props) => props.theme.color.primary.foundersBlue};
    }
    .inputLabel {
      font-size: 16px;
      line-height: 19.2px;
      font-family: ${(props) => props.theme.font.barlow};
      font-weight: 700;
      color: ${(props) => props.theme.color.primary.foundersBlue};
    }
  }

  .enterEmailText {
    margin-bottom: 30px;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    ${(props) => props.theme.responsive.max.md} {
      font-weight: 500;
      margin-top: 10px;
    }
  }

  .requiredText {
    font-size: 11px;
    line-height: 13px;
    font-family: ${(props) => props.theme.font.serifMedium};
    color: ${(props) => props.theme.color.core.blue};
    margin-bottom: 30px;
  }

  .stackedForgotButtons {
    margin: 37px auto 0;
    align-items: center;
    width: 184px;
    ${(props) => props.theme.responsive.max.md} {
      width: 200px;
      height: 36px;
    }
    .cancelForgotButton {
      height: 44px;
    }
    button {
      width: 100%;
      margin-bottom: 0;
    }
    & > *:first-child {
      margin-right: 0;
    }
  }
  .textInputGroupClass {
    width: 392px;
    ${(props) => props.theme.responsive.max.sm} {
      width: 294px;
    }
    .errorRed {
      font-family: ${(props) => props.theme.font.sourceSansPro};
      color: ${(props) => props.theme.color.primary.tongueTorchRed};
      font-weight: 700;
    }
  }
`;

export default ForgotPasswordStyled;
