import { PHONE_FORMAT } from "../../constants/regexPatterns";

export const phoneNumberFormatting = (phoneNumber: string) => {
  let cleaned = "";
  if (phoneNumber.length > 10) {
    cleaned = ("" + phoneNumber).replace(/\D/g, "").slice(-10);
  } else {
    cleaned = ("" + phoneNumber).replace(/\D/g, "");
  }
  const match = cleaned.match(PHONE_FORMAT);
  if (match) {
    cleaned = "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return cleaned;
};
