import { useDispatch } from "react-redux";
import * as yup from "yup";
import { resendConfirmationCode } from "api/users/auth/resendConfirmationCode";
import { setUserDetails, setUserLoyaltyAvailability, setUserTokens, setUserType } from "redux/reducer/user/actions";
import { setSignupDetails } from "redux/reducer/auth/signup/actions";
import { apiCall } from "src/apis/api";
import { USERS } from "api/endpoints";
import { useAccount } from "hooks/account/useAccount";
import { useForm } from "../../useForm";

export const useLogin = () => {
  const dispatch = useDispatch();
  const { getUserDetails } = useAccount();

  const loginSchema = {
    username: yup.object().shape({
      username: yup.string().email("Invalid Email Address").required("Required Field"),
    }),
    password: yup.object().shape({
      password: yup.string().required("Required Field"),
    }),
  };

  const loginForm = useForm({
    initial: {
      username: "",
      password: "",
      remember: true,
    },

    validationSchema: loginSchema,

    validate: (name, value) => {
      if (["username", "password"].includes(name) && !value) {
        return "Required Field";
      }

      return "";
    },

    onSubmit: async (values, callback) => {
      const apiDetails = {
        requestType: "POST",
        apiEndPoint: `${USERS}/signin`,
        apiPostData: values,
      };
      // if (isMobile()) {
      //   setInitialValue();
      // }
      const signinResponse = await apiCall(apiDetails);
      if (signinResponse.success) {
        const { authenticationResult } = signinResponse.response;
        const { idToken, accessToken } = authenticationResult;

        dispatch(setUserType("FIRST_TIME"));
        dispatch(setUserTokens(authenticationResult));

        if (callback) {
          await callback();
        }
        const detailsResponse = getUserDetails(idToken, accessToken);
        const profileResponse = (await detailsResponse).response;
        dispatch(setUserDetails(profileResponse));
        dispatch(setUserLoyaltyAvailability(signinResponse.response.loyaltyAvailable));
        // if (isMobile() === false && values.remember) {
        //   setLocalStorageData("userLoyaltyAvailability", signinResponse.response.loyaltyAvailable);
        // }
        return { values, authenticationResult, profileResponse };
      } else {
        return { values, error: signinResponse.error };
      }
    },
  });

  const handleVerify = async () => {
    const username = loginForm.values.username;
    const password = loginForm.values.password;

    return resendConfirmationCode({ username }).then((codeDeliveryDetails: any) =>
      dispatch(setSignupDetails({ username, password, codeDeliveryDetails }))
    );
  };

  return {
    handleVerify,
    ...loginForm,
  };
};
