import { SetSignupDetailsAction, SetSignupDetailsPayload, ResetAction } from "./types";

export const setSignupDetails = (payload: SetSignupDetailsPayload): SetSignupDetailsAction => ({
  type: "SET_SIGNUP_DETAILS",
  payload,
});

export const reset = (): ResetAction => ({
  type: "RESET",
});
