import { ForgotPasswordAction, ForgotPasswordState } from "./types";

const initialState: ForgotPasswordState = {
  username: null,
  codeDeliveryOptions: null,
  codeDeliveryAttribute: null,
  code: null,
};

export const forgotPasswordReducer = (state = initialState, action: ForgotPasswordAction): ForgotPasswordState => {
  switch (action.type) {
    case "SET_USERNAME":
      return { ...state, username: action.payload || null };

    case "SET_CODE_DELIVERY_OPTIONS":
      return { ...state, codeDeliveryOptions: action.payload || null };

    case "SET_CODE_DELIVERY_ATTRIBUTE":
      return { ...state, codeDeliveryAttribute: action.payload || null };

    case "SET_CODE":
      return { ...state, code: action.payload || null };

    case "RESET":
      return initialState;

    default:
      return state;
  }
};
