import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_FAQ } from "api/endpoints";
import { FranchiseFAQCMSData } from "types/cms/franchise-faq";

export const getFranchiseFAQCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_FAQ,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseFAQCMSData>>;
};
