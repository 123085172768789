import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "redux/reducer/user/actions";
import { sendUserVerificationCode } from "api/users/send-user-attribute-verfication-code";
import { verifyUserAttribute } from "api/users/verify-user-attribute";
import Modal from "components/global/Modal/Modal";
import EnterCode from "components/global/EnterCode/EnterCode";
import useModal from "hooks/useModal";
import { RootState } from "src/types/types";
import { ProfileVerifyEnterCodeStyled } from "./ProfileVerifyEnterCodeStyled";

const ProfileVerifyEnterCode = () => {
  const { modal, setModal } = useModal();
  const { details, tokens } = useSelector((state: RootState) => ({
    details: state.user.details,
    tokens: state.user.tokens,
  }));
  const dispatch = useDispatch();
  const target = `***-***-${details?.phone.slice(-4)}`;

  const handleResend = async () => {
    return sendUserVerificationCode({
      idToken: tokens.idToken || "",
      accessToken: tokens.accessToken || "",
      attribute: "PHONE",
    });
  };

  const handleSubmit = async (code: string) => {
    return verifyUserAttribute({
      code,
      attribute: "PHONE",
      accessToken: tokens.accessToken || "",
      idToken: tokens.idToken || "",
    }).then((response) => {
      if (response.success) {
        setModal("UPDATED");
        dispatch(setUserDetails({ ...details, phoneVerified: true }));
      }
      return response;
    });
  };

  const handleClose = () => {
    setModal("");
  };

  return (
    <ProfileVerifyEnterCodeStyled>
      <Modal isOpen={modal === "ENTER_CODE"} className="customModal">
        <EnterCode
          codeLength={6}
          target={target}
          onResend={handleResend}
          onSubmit={handleSubmit}
          onBackBtnClick={handleClose}
          isBackButtonPresent
        />
      </Modal>
    </ProfileVerifyEnterCodeStyled>
  );
};

export default ProfileVerifyEnterCode;
