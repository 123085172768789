import { apiCall } from "src/apis/api";
import { CommonLayerResponseDataWithType } from "src/types/types";
import { CMS_FRANCHISE_GET_STARTED } from "api/endpoints";
import { FranchiseGetStartedCMSData } from "types/cms/franchise-getstarted";

export const getFranchiseGetStartedCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_GET_STARTED,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseGetStartedCMSData>>;
};
