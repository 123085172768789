const refreshToken = async (authenticationResult: any) => {
  if (typeof window !== "undefined") {
    window.dispatchEvent(new CustomEvent("setTokenInRedux", { detail: authenticationResult }));
  }
};

export const refreshTokenExpired = async () => {
  if (typeof window !== "undefined") {
    window.dispatchEvent(new CustomEvent("refreshTokenExpired", { detail: "" }));
  }
};

export default refreshToken;
