import { ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/types/types";
import { setUserAddressFromModal } from "redux/reducer/Address/AddressAction";
import { saveAddress } from "api/users/save-address";
import AddressSelectedCardBody from "../AddressSelectedCardBody/AddressSelectedCardBody";
import AddressSelectedCardContainer from "../AddressSelectedCardContainer/AddressSelectedCardContainer";
import { AddressDeliveryCardStyled, BackButtonStyled } from "./AddressDeliveryCardStyled";

interface Props {
  onBack?: () => void;
  Footer: ReactNode;
  className?: string;
  isNearByCard?: boolean;
  isPinnedLocation?: boolean;
  storeData?: any;
  isUserAddress?: boolean;
  onUpdateFavoriteStore?: (favorite: boolean, storeId?: number) => Promise<void>;
  onUpdateFavouriteAddress?: (favorite: boolean, addressId?: number) => Promise<void>;
}

const AddressDeliveryCard = ({
  onBack,
  Footer,
  className,
  isNearByCard,
  isPinnedLocation,
  storeData,
  isUserAddress,
  onUpdateFavoriteStore,
  onUpdateFavouriteAddress,
}: Props) => {
  const { favouriteStoreList, addressList, userAddress, details, tokens, orderType, step } = useSelector((state: RootState) => ({
    userAddress: state.address?.userAddress,
    favouriteStoreList: state.store?.favouriteStoreList,
    addressList: state.address?.addressList,
    details: state.user.details,
    tokens: state.user.tokens,
    orderType: state.location.orderType,
    step: state.location.step,
  }));

  const backButton = (
    <BackButtonStyled variant="ghostBlue" onClick={onBack} data-testid="backButton">
      BACK
    </BackButtonStyled>
  );

  const [isFavourite, setIsFavourite] = useState(false);
  const [isFavAddress, setIsFavAddress] = useState(false);
  const dispatch = useDispatch();

  // Favorite Store
  useEffect(() => {
    const storeList = favouriteStoreList?.find((sId: any) => sId.storeId === storeData?.storeId && !!sId.default);

    storeList ? setIsFavourite(true) : setIsFavourite(false);
  }, [favouriteStoreList, storeData]);

  // Favorite Address
  useEffect(() => {
    const favAddress = addressList?.find(
      (item: any) =>
        item.latitude?.toFixed(2) === storeData?.latitude?.toFixed(2) &&
        item.longitude?.toFixed(2) === storeData?.longitude?.toFixed(2) &&
        item.zip === storeData?.zip &&
        item.favorite
    );
    favAddress ? setIsFavAddress(true) : setIsFavAddress(false);
  }, [addressList, storeData]);

  const handleUpdateFavoriteStore = () => {
    if (onUpdateFavoriteStore) {
      onUpdateFavoriteStore(!isFavourite, storeData?.storeId).then(() => setIsFavourite(!isFavourite));
    }
  };

  const saveAddressAndMarkFavourite = () => {
    const saveAddressData = {
      ...storeData,
      favorite: !isFavAddress,
    };
    saveAddress({
      ...saveAddressData,
      externalId: details?.externalId || "",
      idToken: tokens?.idToken || "",
    })
      .then((res: any) => {
        if (res?.success) {
          if (onUpdateFavouriteAddress) {
            onUpdateFavouriteAddress(!isFavAddress, res?.response?.id);
          }
          dispatch(setUserAddressFromModal(saveAddressData));
        }
      })
      .catch(() => {
        alert("Invalid address, Please click on confirm address");
      });
  };

  const handleUpdateFavouriteAddress = () => {
    const favAddress = addressList.find(
      (item: any) =>
        item.latitude?.toFixed(2) === storeData?.latitude?.toFixed(2) &&
        item.longitude?.toFixed(2) === storeData?.longitude?.toFixed(2) &&
        item.zip === storeData?.zip
    );
    const addressId = favAddress?.id;
    if (!isFavAddress) {
      if (addressId) {
        if (onUpdateFavouriteAddress) {
          onUpdateFavouriteAddress(!isFavAddress, addressId);
        }
      } else {
        saveAddressAndMarkFavourite();
      }
    } else {
      if (onUpdateFavouriteAddress) {
        onUpdateFavouriteAddress(!isFavAddress, addressId);
      }
    }
  };

  // If the Card is opening from the account Your Address Page make it default false
  const isPickup = step === "DELIVERY_OUTSIDE_RADIUS" ? false : orderType === "PICKUP";

  const Body = (
    <AddressSelectedCardBody
      storeData={storeData}
      backButton={!isNearByCard && backButton}
      isNearByCard={isNearByCard}
      isFavourite={isPickup ? isFavourite : isFavAddress}
      isUserAddress={isUserAddress}
      onUpdateFavoriteStore={isPickup ? handleUpdateFavoriteStore : handleUpdateFavouriteAddress}
      className={isNearByCard ? "nearByCardWrapper" : "outOfRangeCardWrapper"}
      disableFavourite={!isPickup && userAddress?.zip?.length < 5}
      isDeliveryAddress={!isPickup}
    />
  );

  const deliveryCardClassName = className ? `addressDeliveryCard ${className}` : "addressDeliveryCard";

  return (
    <AddressDeliveryCardStyled className={deliveryCardClassName}>
      <AddressSelectedCardContainer body={Body} footer={Footer} isNearByCard={isNearByCard} isPinnedLocation={isPinnedLocation} />
    </AddressDeliveryCardStyled>
  );
};

export default AddressDeliveryCard;
