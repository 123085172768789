import styled from "styled-components";
import { responsive } from "theme/responsive";

export const UpdatePasswordStyled = styled.div`
  .modal-style {
    width: 632px;
    background-color: ${(props) => props.theme.color.primary.coleslawWhite};
    padding: 72px 0 40px 0;
    border-radius: 20px;
  }

  .heading {
    font-family: ${(props) => props.theme.font.barlowSemiCondensed} !important;
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 26px;
    text-transform: uppercase;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      font-size: 28px;
    }
  }

  .stackedForgotButtons {
    align-items: center;
    width: 390px;
    margin-left: 0;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      max-width: 200px;
    }
    .cancelForgotButton {
      height: 44px;
    }
    button {
      width: 190px;
      margin-bottom: 0;
    }
    & > *:first-child {
      margin-right: 0;
    }
  }

  .send-code {
    .modal-style {
      height: 386px;
    }
    .heading {
      margin: 0;
      line-height: 42px;
      margin-bottom: 16px;
    }
    .text {
      margin-bottom: 45px;
      line-height: 17px;
    }
    .stackedButtons {
      margin-top: 23px;
      * {
        width: 180px;
      }
    }
    .checkBox {
      margin-bottom: 24px;
      .checkbox-label {
        width: 376px;
        line-height: 19px;
        &::before {
          margin-right: 10px;
        }
        span {
          font-size: 16px;
          margin: 0 0 0 10px;
        }
      }
    }
  }

  .enter-code {
    .modal-style {
      height: 399px;
      .heading {
        margin: 0;
      }
      .enterCodeText {
        margin: 10px 0 0;
      }
      .phoneNumber {
        font-size: 20px;
        margin-bottom: 18px;
      }
      .inputs {
        margin-top: 17px;
      }
      .resendForgotPasswordCode {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .confirm {
    .modal-style {
      min-height: 409px;
    }
    .changePasswordTitle {
      margin-top: 0;
      margin-bottom: 27px;
    }
    .inputWrapper {
      .textFieldWrapper {
        label {
          top: 20px;
        }
      }
      .requiredText {
        margin-top: -4px;
      }
    }
    .stackedForgotButtons {
      margin-top: 15px;
      margin-left: 0;
      gap: 12px;
      * {
        width: 190px;
        height: 44px;
      }
    }
  }

  .updated {
    .modal-style {
      height: 292px;
      margin: auto;
      .passwordUpdated {
        display: flex;
        flex-direction: column;
        padding: 0 96px;
        align-items: center;
        text-align: center;
        .heading {
          margin-top: 0;
          margin-bottom: 12px;
          text-transform: uppercase;
        }
        .btnWrapper {
          height: auto;
          margin-top: 25px;
        }
      }
    }
  }

  .passwordUpdatedDescription {
    p {
      font-family: ${(props) => props.theme.font.barlow};
      color: ${(props) => props.theme.color.secondary.coolGray10};
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
    }
  }

  .horizontalDivider {
    border-top: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
  }
  .buttonSize {
    width: 190px;
    height: 44px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .requiredText {
      margin-top: 0px !important;
      .strengthIndicator {
        .strengthWrapper {
          margin-left: -6px;
        }
      }
    }
    .send-code,
    .enter-code,
    .confirm,
    .updated {
      .modal-style {
        height: 100% !important;
        border-radius: 0;
        padding-top: 104px;
        .heading {
          line-height: 28px !important;
          font-size: 28px;
          font-weight: 800;
          margin-bottom: 10px;
        }
        .text {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding: 0 50px;
          margin-bottom: 20px;
        }
        .checkBox {
          margin-bottom: 20px;
          .checkbox-label {
            width: auto;
            line-height: 19px;
            padding-left: 22px;
            &::before {
              margin-right: 0;
            }
            span {
              font-size: 14px;
              margin: 0;
            }
          }
        }
        .enterCodeText {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
        }
        .phoneNumber {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .codeInputStyle {
          height: 44px;
          width: 32px;
          font-weight: 500;
        }
        .inputs {
          margin-top: 29px;
        }
        .resendForgotPasswordCode {
          margin-top: 5px;
        }
        .stackedButtons {
          margin-top: 20px;
          gap: 20px;
          * {
            width: 200px;
            height: 36px;
          }
        }
        .stackedForgotButtons {
          margin: 0;
          gap: 20px;
          button {
            height: 36px;
          }
        }
        .inputWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;
          .textFieldWrapper {
            margin-bottom: 20px;
            label {
              top: 12px;
              font-size: 16px;
              line-height: 19px;
            }
            .input-wrapper input:valid ~ label {
              top: 4px;
              font-size: 10px;
              font-weight: 700;
            }
            .textfield {
              font-size: 16px;
              padding-bottom: 5px;
            }
          }
        }
        .changePasswordTitle {
          line-height: 28px;
          font-size: 28px;
          font-weight: 800;
          margin-bottom: 10px;
        }
        .requiredText {
          font-size: 10px;
          font-weight: 700;
          width: 282px;
          margin-top: -8px;
          margin-bottom: 30px;
        }
        .passwordUpdated {
          display: flex;
          flex-direction: column;
          padding: 0 48px;
          align-items: center;
          text-align: center;
        }
        .horizontalDivider {
          width: 294px;
          margin-top: 30px;
          margin-bottom: 20px;
        }
        .buttonSize {
          width: 200px;
          height: 36px;
        }
      }
    }
    .send-code {
      .modal-style {
        .text {
          padding: 0;
        }
      }
    }
    .confirm {
      .modal-style {
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
    }
    .updated {
      .modal-style {
        padding-top: 56px;
        .passwordUpdated {
          .heading {
            padding: 0 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .passwordUpdatedDescription {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 15px;
    }
    & + footer {
      display: none;
    }
  }
  ${responsive.max.lg(`
.buttonsMobileView {
  gap: 10px;
}
`)}
`;

export const ButtonWrapper = styled.div`
  .button-wrapper {
    width: 190px;
    height: 44px;
  }
  display: flex;
  .disabled {
    opacity: 0.3;
    color: ${(props) => props.theme.color.core.white + "b2"};
  }
  flex-direction: column;
  margin-top: 15px;

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    margin-top: 40px;
    .button-wrapper {
      width: 200px;
      height: 36px;
    }
  }
`;
