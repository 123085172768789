import { getTokensForAPICall } from "../api/utils";
import { getWebFingerprintingHeaders } from "../utils";
import { getWebPXHeaders } from "../utils/perimeterX/getWebPXHeaders";
import { Request } from "./types";

export const isBrowser = () => typeof window !== "undefined";

// There is lots of Data in some API so we need to add more timeout here
const TIMEOUT = 60000;

/**
 * GET a path relative to API root url.
 * @param {String}  path Relative path to the configured API endpoint
 * @returns {Promise} of response body
 */
export const get = async ({ path, ...props }: Request) => {
  return bodyOf(request({ method: "get", path, body: null, ...props }));
};

/**
 * POST JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @returns {Promise}  of response body
 */
export const post = async ({ path, body, ...props }: Request) => {
  return bodyOf(request({ method: "post", path, body, ...props }));
};

/**
 * PUT JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @returns {Promise}  of response body
 */
export const put = async ({ path, body, ...props }: Request) => {
  return bodyOf(request({ method: "put", path, body, ...props }));
};

/**
 * DELETE a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @returns {Promise}  of response body
 */
export const del = async ({ path, ...props }: Request) => {
  return bodyOf(request({ method: "delete", path, body: null, ...props }));
};

/**
 * Make arbitrary fetch request to a path relative to API root url
 * @param {String} method One of: get|post|put|delete
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 */
export const request = async ({ method, path, body, ...props }: Request) => {
  try {
    const response = await sendRequest({ method, path, body, ...props });
    return handleResponse(path, response);
  } catch (error) {
    throw error;
  }
};

/**
 * Takes a relative path and makes it a full URL to API server
 */
export const url = (path: string) => {
  return path;
};

/**
 * Constructs and fires a HTTP request
 */
const sendRequest = async ({ method, path, body, isAuth, ...props }: Request) => {
  try {
    const endpoint = url(path);
    let headers = props.header ? props.header : await getRequestHeaders(isAuth, props?.token);
    if (isBrowser()) {
      const email = path.includes("/signin") ? body.username : "";

      const signatureHeaders = await getWebFingerprintingHeaders(path, email);
      const pxHeaders = getWebPXHeaders();

      const isZaxbysAPI = endpoint.includes("zaxbys.com");

      headers = isZaxbysAPI
        ? {
            ...headers,
            ...signatureHeaders,
            ...pxHeaders,
          }
        : {
            ...headers,
          };
    }

    const options = body ? { method, headers, body: JSON.stringify(body) } : { method, headers };

    return timeout(fetch(endpoint, options), TIMEOUT);
  } catch (e) {
    const error: any = e;
    throw new Error(error);
  }
};

/**
 * Receives and reads a HTTP response
 */
const handleResponse = async (_path: string, response: any) => {
  try {
    const responseBody = await response.text();
    const responseBodyJson: any = {};
    responseBodyJson.status = response.status;
    try {
      responseBodyJson.data = responseBody ? JSON.parse(responseBody) : null;
    } catch (e: any) {
      throw {
        ...e,
        status: response.status,
        message: "Something Went Wrong",
      };
    }
    return {
      status: response.status,
      headers: response.headers,
      body: responseBodyJson || null,
    };
  } catch (e) {
    const error = e;
    throw error;
  }
};

const getRequestHeaders = async (isAuth?: boolean, token?: string) => {
  let headers = { Accept: "application/json", "Content-Type": "application/json" };
  if (isAuth) {
    try {
      if (token) {
        headers = { ...headers, ...{ Authorization: `Bearer ${token}` } };
        return headers;
      } else {
        const tokens = (await getTokensForAPICall()) as Record<string, unknown>;
        const requiredToken = tokens?.token as string;
        headers = { ...headers, ...{ Authorization: `Bearer ${requiredToken}` } };
        return headers;
      }
    } catch (e) {
      throw new Error("Invalid token");
    }
  } else {
    return headers;
  }
};

/**
 * Rejects a promise after `ms` number of milliseconds, it is still pending
 */
const timeout = (promise: Promise<any>, ms: number) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => reject(new Error("timeout")), ms);
    promise
      .then((response) => {
        clearTimeout(timer);
        resolve(response);
      })
      .catch(reject);
  });
};

const bodyOf = async (requestPromise: Promise<any>) => {
  try {
    const response = await requestPromise;
    return response.body;
  } catch (e) {
    throw e;
  }
};
